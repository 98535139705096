/* base color */
@black: #000;

@white: #fff;
@cherry: #d15656;
@orange: #ff8c00;

@title-1:#334860;
@navi:#95aabf;
@darkTitle:#212226;
@badgeBg:#334860;

@darkBlueBg: #5696d1;
@activeIcon: #5696d1;
@activeLink: #5696d1;
@btnBg: #2c81d1;
@bodyBg: #f5f6fa;
@inputText: #7f7f7f;
@grey: #838385;
@lightGrey: #b3b3b3;
@redText: #d15656;
@blueBg: #8eb9e0;
@lightBlue:#d9e8f6;
@lightBorder:#e5e9f2;
@orangeBg:#ff8c00;

@blueBorder:#2d82d1;
@bgChatPage:#dbe6ff;
@linkHover: #d15656;
@linkLine: rgba(44,129,209, .2);
@linkActiveLine: rgba(209,86,86, .2);
@greenBg:#41ae1b;
@yellowBg: #fff8eb;
@yellowBRD: #f2ebdf;
@yellowMuted: #b3aea4;
@dangerBg:#ffd9d9;
@dangerBrd:#ffbfbf;
//-кастомизация сетки бутстрап

@media (min-width: 1350px) {
  .container {
    max-width: 1320px
  }
}
@media (min-width: 1350px) {
  .col-hl {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-hl-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-hl-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-hl-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-hl-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-hl-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-hl-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-hl-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-hl-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-hl-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-hl-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-hl-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-hl-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-hl-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-hl-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }
  .order-hl-last {
    -webkit-box-ordinal-group: 14;
    -ms-flex-order: 13;
    order: 13;
  }
  .order-hl-0 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }
  .order-hl-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
  .order-hl-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }
  .order-hl-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
  }
  .order-hl-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
  }
  .order-hl-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5;
  }
  .order-hl-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6;
  }
  .order-hl-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7;
  }
  .order-hl-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8;
  }
  .order-hl-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9;
  }
  .order-hl-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10;
  }
  .order-hl-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11;
  }
  .order-hl-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-hl-0 {
    margin-left: 0;
  }
  .offset-hl-1 {
    margin-left: 8.333333%;
  }
  .offset-hl-2 {
    margin-left: 16.666667%;
  }
  .offset-hl-3 {
    margin-left: 25%;
  }
  .offset-hl-4 {
    margin-left: 33.333333%;
  }
  .offset-hl-5 {
    margin-left: 41.666667%;
  }
  .offset-hl-6 {
    margin-left: 50%;
  }
  .offset-hl-7 {
    margin-left: 58.333333%;
  }
  .offset-hl-8 {
    margin-left: 66.666667%;
  }
  .offset-hl-9 {
    margin-left: 75%;
  }
  .offset-hl-10 {
    margin-left: 83.333333%;
  }
  .offset-hl-11 {
    margin-left: 91.666667%;
  }
}
.col-hl-1, .col-hl-2, .col-hl-3, .col-hl-4, .col-hl-5, .col-hl-6, .col-hl-7, .col-hl-8, .col-hl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-hl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 12px;
  padding-left: 12px;
}
