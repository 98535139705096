/* Floats */

.float-right {
	float: right;
}

.float-left {
	float: left;
}

/* Text align */

.text-center {
	text-align: center;
}

.text-left {
	text-align: left;
}

.text-right {
	text-align: right;
}

.text-justify {
	text-align: justify;
}

/* Font weight */

.light {
	font-weight: 300;
}

.normal {
	font-weight: 400;
}

.strong {
	font-weight: 700;
}
/* Font properties */
.uppercase {
	text-transform: uppercase;
}
/* Flex */

.flex-grow {
	-webkit-flex-grow: 1;
	        flex-grow: 1;
}
