.filter-toggle{
	align-items: center;
	display: flex;
}
.filter-toggle__text{
	font-size: 16px;
	color:#000;
	padding-right: 10px;
	@media(min-width: 768px){
		font-size: 14px;
	}
}
.filter-toggle-item{
	cursor: pointer;
	border-bottom: 1px dashed rgba(44, 129, 209, 0.2);
	color:rgb(44, 129, 209);
	transition: .5s;
	margin-right: 10px;
	font-size: 16px;
}
.filter-active{
	height: 24px;
	padding-left: 10px;
	padding-right: 10px;
	border-radius: 3px;
	border-bottom: transparent;
	background-color:#334860;
	color:#fff;
	@media(min-width: 768px){padding-top: 1px;}

}
.filter-active a{
	color:#fff;
}
.filter-toggle--border{
	border-radius: 3px;
	width: 100%;
	height: 24px;
	text-align: center;
	border: 1px solid #334860;
	@media(min-width: 768px){width: auto;}
	.filter-toggle__text{
		display: none;
		@media(min-width: 768px){display: block;}
	}
	.filter-toggle-item,
	.filter-active{margin-right: 0;}

}
.filter-toggle-item--left{
	border-radius: 3px 0px 0px 3px;
	width: 50%;
}
.filter-toggle-item--right{
	border-radius: 0px 3px 3px 0px;
	width: 50%;
}
@media(min-width: 768px){
	.filter-toggle--border{
		border:0px;
		border-radius: 0px;
	}
	.filter-toggle-item--left{
		border-radius:3px;
		width: auto;
	}
	.filter-toggle-item--right{
		border-radius: 3px;
		width: auto;
		margin-left: 10px;
	}
	.filter-toggle-item{font-size: 14px;}

}
.visible-desktop{
	display: none;
	@media(min-width: 768px){display: flex;}
}
.visible-mobile{

	@media(min-width: 768px){display: none;}
}