.badge{
	cursor: pointer;
	font-size: 16px;
	font-weight: 500;
	min-height: 30px;
	align-items: center;
	display: inline-flex;
	padding-left:8px;
	padding-right:8px;
	color: @darkBlueBg;
	border: 1px solid @darkBlueBg;
	border-radius: 3px;
	text-align: center;
	letter-spacing: .5px;
	margin-bottom: 3px;

	@media(min-width: 768px){
		.badge-group{flex-shrink: 0}
		min-height: 24px;
		font-size: 14px;
	}
}

.badge--dark{
	background-color: @badgeBg;
	border: 1px solid @badgeBg;
	color: @white;
}
.big-badge{
	//background-color:@darkBlueBg;
	background-color:@title-1;
	color:@white;
	min-height: 82px;
	width: 200px;
	line-height: 1.3;
	padding-top: 14px;
	padding-left: 24px;
	border-top-right-radius: 12px;
	border-bottom-right-radius: 12px;
	@media(min-width: 768px){
		padding-top: 3px;
		padding-left: 10px;
		padding-bottom: 3px;
		min-height: 55px;
		border-radius: 4px;
	}
	@media(min-width: 1200px){
		width: 160px;

	}
	@media(min-width: 1350px){
		width:200px;
		padding-left: 15px;
	}

}
.big-badge__top{

	font-size: 24px;
	font-weight: 700;
	@media(min-width: 768px){font-size: 20px;}
}
.big-badge__bottom{
	font-size: 16px;
	@media(min-width: 768px){font-size: 14px;}
}
.badge--active{
	background-color:@btnBg; ;
	color:@white;
}
.badge--red{
	background-color: @redText;
	border: 1px solid @redText;
	color: @white;
}
.badge--green{
	background-color: @greenBg;
	border: 1px solid @greenBg;
	color: @white;
}
.blue {background-color: @btnBg !important;}
.red {background-color: @redText!important;}
.orange{background-color: @orangeBg!important;}
.text-orange{color: @orangeBg!important;}
.dark{background-color: @title-1!important;}
.text-navi{color: @navi!important;}
.black{background-color: @black!important;}
.cherry{background-color: @cherry!important;}
.green{background-color: @greenBg!important;}

.rounded{
	border-radius: 10px;
}
