.rcat-landing{
	position: relative;
	background-color:#1b2e46;
	font-size: 16px;
	color: #fff;
	overflow: hidden;
	padding-top: 50px;
}

.search-param{
	padding-bottom: 60px;
	padding-top: 160px;
	 @media(min-width: 1200px){padding-bottom: 0px;}
}
.search-param-descrption{
	padding-top: 55px;
	// @media(min-width: 768px){}
	@media(min-width: 992px){padding-top: 0px;}
	@media(min-width: 1200px){display: block;}
}

.parallax-wrapper2,
.parallax-wrapper{
	// border:2px solid red;
	position: relative;
	-webkit-border-radius: 5px;
	        border-radius: 5px;
	left:-25px;
	height: 555px;
	position: relative;
	overflow: hidden;
	width: 121%;
	// @media(min-width: 480px){height: 680px;	}
	@media(min-width: 650px){
		left:0px;
		max-width: 100%;
		margin-left: auto;
		margin-right: auto;
	}

	@media(min-width: 768px){
		// width: 100%;
		height: 780px;}
	@media(min-width: 992px){
		width: 100%;
		height: 700px;}
	@media(min-width: 1200px){height: 1050px;}

	.parallax-screen{
		position: absolute;
		width: 578px;
		z-index: 10;
		@media(min-width: 480px){width: 650px;}
		@media(min-width: 650px){width: 100%;}
		@media(min-width:992px){width: 110%;}
		@media(min-width:1200px){width: 100%;}

	}
	img{-webkit-border-radius: 5px;
	        border-radius: 5px;}
}
.parallax-wrapper2{
	@media(min-width:1200px){height: 1414px;}
}
.parallax-screen img{
	height: 690px;
	@media(min-width: 576px){height: 785px;}
	@media(min-width: 768px){height: auto;}
}
#screen2 img{
	height: 1800px;
	@media(min-width: 576px){height: 1900px;}
	@media(min-width: 768px){height: auto;}
}
.text-box{
	font-size: 16px;
	line-height: 1.5;
	color: #fff;
	font-weight: normal !important;
}
.bgblue {background-color: #2b82d1;}
.bggreen {background-color: #41ae1b;}
.text-box--bold{font-weight: 700;}
.text-box--number{
	line-height: 0.9;
	font-size: 80px;
	@media(min-width: 992px){line-height: 1;}
}
.emoji-item{margin-bottom: 16px;}
.descrption_item-top,
.descrption_item--middle,
.descrption_item--bottom{max-width: 250px;}
.descrption_item-top{
	padding-bottom: 55px;
	// padding-top: 55px;
	@media(min-width: 550px){padding-top: 50px;}
	@media(min-width: 768px){padding-top: 0px;}
	@media(min-width: 992px){
		padding-top: 30px;
		padding-bottom:100px;}
	@media(min-width: 1200px){padding-bottom:150px;}
	@media(min-width: 1280px){padding-bottom:200px;}
}
.descrption_item--middle{
	padding-bottom: 55px;
	@media(min-width: 992px){padding-bottom:100px;}
	@media(min-width: 1200px){padding-bottom:200px;}
	@media(min-width: 1280px){padding-bottom:300px;}
}



/*----преимущества----*/

.benefits{
	padding-top: 90px;
	padding-bottom: 25px;
	@media(min-width: 1200px){padding-bottom: 75px;}
	@media(min-width: 1200px){padding-top: 150px;}
}
.block-title{
	font-size: 26px;
	font-weight: 700;
	@media(min-width: 768px){font-size: 36px;}
}
.block-subtitle{
	padding-top: 16px;
	padding-bottom: 40px;
	font-size: 16px;
	line-height: 1.5;
	@media(min-width: 768px){font-size: 20px;}
}
.block-subtitle--benefits{max-width: 490px;}
.text-box__title{
	font-size: 16px;
	font-weight: 700;
	padding-bottom: 10px;
	@media(min-width: 768px){font-size: 20px;}
}
.agent-card-img{
	margin-bottom: 38px;
	height: 275px;
	width: 120%;
	border-radius: 10px;
	background: url('../../img/landing/agent-card-img.png');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: left;
	@media(min-width: 480px){height: 330px;}
	@media(min-width: 768px){
		margin-bottom: 50px;
		width: 100%;}
	@media(min-width: 850px){height: 360px;}
	@media(min-width: 900px){height: 390px;}

	@media(min-width: 992px){height: 405px;}
}
.benefits__bottom-text{
	padding-top: 30px;
	@media(min-width: 992px){padding-top: 40px;}
	@media(min-width: 1200px){padding-top: 90px;}
}
.text-box--bg{
	display: inline-block;
	padding-top: 3px;
	font-weight: 700;
	height: 30px;
	padding-left: 12px;
	padding-right: 12px;
	background-color: #41ae1b;
	-webkit-border-radius: 3px;
	        border-radius: 3px;
}
.benefits-cards{
	display: none;
	@media(min-width: 768px){display: block;}
}
.benefits-card{
	background-color: #334860;
	border-radius: 10px;
	overflow: hidden;
	padding: 15px 30px 0px 20px;
	width: 100%;
	height: 420px;
	max-width: 630px;
	margin-left: auto;
	margin-right: auto;

}
.benefits-card-left{
	@media(min-width: 768px){margin-bottom: 55px;}
	@media(min-width: 1200px){margin-bottom: 0px;}


}
.benefits-descrip{
	margin-bottom: 50px;
	@media(min-width: 768px){

		display: flex;
		justify-content: space-between;
		align-items: center;

	}
	@media(min-width: 992px){
		margin-bottom: 0;
		display: block;}

	.benefits__top-text{
		max-width: 300px;
	}
}

.landing-button{
  cursor: pointer;
  border-style: solid;
  border-width: 1px;
  border-color: rgb(255, 255, 255);
  border-radius: 3px;
  background-color: transparent;
  min-height: 30px;

  font-family: inherit;
  font-weight: 500;
  padding-left: 12px;
  padding-right: 12px;
  display: inline-flex;
  align-items: center;
  color: #fff;
  margin-top: 17px;
  margin-bottom: 40px;
  transition: .5s;
}
.btn-add:hover,
.landing-button:hover{
	color:#fff;
	box-shadow: 0 0 10px 2px rgba(255,255,255, .4);
	background-color: rgba(70, 136, 241, 0);
}
.card-left-img{
	height: 230px;
	background-color: #fff;
	position: relative;
}
.benefits-cards--mobile{
	display: block;
	background-color: #334860;
	@media(min-width: 768px){display: none;}

	.benefits-card__top{
	position: relative;
	padding-top: 40px;
	height: 650px;
	}
	.card-left-img{
		position: absolute;
		left: -100px;
		right: 25px;
		bottom: 30px;

		height: 280px;
		// box-shadow: 0px 20px 25px -12px #000;
		background-color: rgb(255, 255, 255);
  		box-shadow: 0px 40px 60px 0px rgba(0,0,0,.75);

	}


	.card-left__icons{
		top:-40px;
	}
	.card-left__whatsapp{
		position: relative;
		right: -35px;}
	.card-left__email{
		position: relative;
		right:-15px;
	}

	.benefits-card__bottom{
		padding-top: 40px;
		height: 1020px;
		position: relative;

	}
	.card__bottom-img{
		width: 300px;
		height: 550px;
		position: absolute;
		left: 10px;
		// transform: translateX(-50%);
		bottom: 0;
		background-color: #fff;
		background: url(../img/landing/agent.png);
		background-size: cover;
	}
}
.landing-button--arrow{

	font-weight: 700;
	padding-left: 15px;
}

.card-left__icons{
	position: absolute;
	display: flex;
	align-items: center;
	right: 10px;
	top: -50px;
}

.benefits-card-right{
	width: 100%;
	background-color: #334860;
	border-radius: 10px;
	overflow: hidden;
	position: relative;
	padding-top: 15px;
	padding-left: 20px;
}
.card-right__bottom{padding-top: 40px;}
.text-box--strong{font-weight: 700;}
.card-right-text{
	width: 52%;
	max-width: 320px;
}
.card-right-img{
	width: 230px;
	height: 400px;
	position: absolute;
	right: 30px;
	bottom: 0px;

	overflow: hidden;
}
.screen3{
	position: absolute;
	z-index: 10;
}
.emoji-item--selfie{
	font-size: 100px;
	position: relative;
	left: -40px;
	margin-bottom: 17px;
}
/*--------горячие предложения-------*/
.emoji-item--megaphone{
	position: relative;
	top:-20px;}
.urgent-sale-description{
	padding-top: 90px;
	padding-bottom: 160px;
	@media(min-width: 768px){padding-bottom: 100px;}
	@media(min-width: 1200px){
		padding-bottom: 145px;
		padding-top: 50px;}

}
.urgent-top-text{
	margin-bottom: 40px;

	@media(min-width: 1200px){margin-bottom: 130px;}
}
.urgent-sale-text--flex{
	@media(min-width: 992px){
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		padding-top: 60px;
	}
}
.urgent-sale-benefits{margin-top: 70px;}
.urgent-sale-img{
	background-color: #fff;
	background: url(../../img/landing/hot-card.png);
	background-size: cover;
	height: 370px;
	width: 630px;
	margin-top: 40px;
	border-radius: 10px;
	@media(min-width: 992px){
		width: 960px;
		height: 560px;
	}
}
.benefits-text,
.card__bottom-text,
.urgent-sale-text{
	max-width: 300px;
	@media(min-width: 1300px){max-width: 388px;}
}
.mb-75{
	margin-bottom: 75px;
	@media(min-width: 992px){margin-bottom: 0;}
	}
/*--------шахматки-------*/

.hc-param-wrapper{
	position: relative;
	// height: 765px;
	border-radius: 10px;
	background-color: #000;
	padding-top: 35px;
	padding-bottom: 50px;
}
//	@media(min-width: 1200px)
	.text-number{
		font-size: 80px;
		color: #41ae1b;
		@media(min-width: 768px){font-size: 60px;}
		@media(min-width: 1200px){font-size: 80px;}

	}
	.sub-title-block{
		align-items: flex-end;
		display: flex;}
//}

.hc-param-top{
	list-style-type: 35px;
	padding-left: 35px;
}
.title-3{
	font-size: 26px;
	line-height: 1.3;
	width: 90%;
	font-weight: 700;
	@media(min-width: 768px){font-size: 32px;}
	@media(min-width: 992px){font-size: 36px;}
	@media(min-width: 1200px){font-size: 40px;}
}
.hc-cards-block{
	position: relative;
	overflow: hidden;
	height: 430px;
}
.screen4{
	position: absolute;
	z-index: 10;
	width: 104%;
}
/**
.hc-param--mobile{
	position: relative;
	.title-3{padding-bottom: 20px;}
	.hc-param-wrapper{
		height: 1180px;
		padding-top: 30px;
		padding-bottom: 30px;
		border-radius: 0px;
		@media(min-width: 375px){height: 1100px;}
		@media(min-width: 425px){height: 1050px;}
		@media(min-width: 500px){height: 1000px;}
		@media(min-width: 600px){height: 950px;}
		@media(min-width: 700px){height: 900px;}
	}
	.text-number{
		padding-top: 40px;
		padding-bottom: 18px;
	}
	.param-wrapper-top{
		padding-left: 30px;
		padding-right: 30px;
	}

	.hc-cards-block{bottom: -35px;}
	.screen4{
		width: 1300px;
		// margin-left: -160px;
		margin-left: 90px;
		}
}**/
/*-----философия---*/
.philosophy{
	padding-top: 150px;
	// padding-bottom: 155px;
	// @media(min-width: 1200px){padding-bottom: 120px;}
}
.philosophy-slogan{
	font-weight: 700;
	font-size: 40px;
	line-height: 1.6;
	@media(min-width: 768px){
		line-height: 1.3;
		font-size: 50px;}
	@media(min-width: 992px){font-size: 70px;}
	@media(min-width: 1200px){font-size: 80px;}
}
.pl-20{
	max-width: 300px;
	@media(min-width: 992px){padding-left: 20px;}
}
.pb-16{padding-bottom: 16px;}
.color-block{
	padding: 25px;
	width: 100%;
	position: relative;

	@media(min-width: 435px){border-radius: 5px;}
	@media(min-width: 1300px){
		left: -25px;
	}

}
.philosophy-right{
	padding-top: 70px;
	@media(min-width: 768px){padding-top: 30px;}
	@media(min-width: 1200px){padding-top: 40px;}
}
.philosophy-emoji{margin-bottom: 20px;}
.philosophy-right-text{margin-bottom: 50px;}
.color-block--desk{
//	display: none;
	@media(min-width: 992px){display: block;}

}
.color-block--mobile{
	display: block;
	max-width: 435px;
	margin-left: auto;
	margin-right: auto;
	margin-top: 50px;
	@media(min-width: 992px){display: none;}
}

.philosophy-blockquote{
	padding: 0;
	margin: 0;
	padding-top: 116px;
	margin-left: -50px;
	@media(min-width: 768px){
		padding-top: 130px;
		margin-left: 0px;}
	@media(min-width: 992px){padding-top: 116px;}
}

.philosophy-blockquote__text{
	padding-left: 50px;
	position: relative;
	@media(min-width: 768px){padding-left: 0px;}
	.block-subtitle{
		// font-size: 16px;
		padding-bottom: 25px;
		// @media(min-width: 768px){font-size: 20px;}
	}
	span.text-box__title{
		padding-bottom: 0;
		margin-right: 8px;}
	span{
		display: block;
		@media(min-width: 768px){display: inline-block;}
	}
}
.quote-left{

	display: block;
	position: absolute;
	top: -100px;
	left: -256px;
	@media(min-width: 768px){left: -156px;}
	@media(min-width: 992px){
		display: none;
		top: -90px;
		left: -156px;}

}
.quote-right{

	display: block;
	position: absolute;
	bottom: -42px;
	right: -75px;
	// @media(min-width: 768px){
	// 	bottom: -35px;
	// 	right: -140px;}
	@media(min-width: 992px){
		display: none;

		bottom: -35px;
		right: -140px;}
	@media(min-width: 1200px){
		bottom: -35px;
		right: -140px;}
}
.philosophy-blockquote--mobile{
	display: block;
	@media(min-width: 992px){display: none;}
}
#scene{
	display: none;
	@media(min-width: 992px){display: block;}
}
/*cooperation*/
.cooperation{
	padding-top: 160px;
	position: relative;}
.cooperation-block{
	position: relative;
	padding: 25px 25px 75px 30px;
	background-color: #000;
	margin-left: -30px;
	width: 120%;
	// margin-top: 160px;
	@media(min-width: 768px){
		margin-left: 0px;
		border-radius: 10px;
		width: 100%;
	}
}
.cooperation--parallax{
	padding-top: 160px;
}
.cooperation-title{
	font-size: 26px;
	@media(min-width: 768px){font-size: 36px;}
	@media(min-width: 992px){font-size: 40px;}

}
.cooperation-proportions__col{
	margin-bottom: 45px;
	@media(min-width: 768px){margin-bottom: 0px;}
}
.cooperation-proportions{

	@media(min-width: 768px){
		margin-bottom: 0;
		display: flex;
		justify-content: space-between;
	}
}
.cooperation-right__img{
	width: 140px;

	margin-bottom: 25px;
	@media(min-width: 768px){width: 65%;}
	@media(min-width: 992px){width: 100%;}
}
.proportions-value{
		font-size: 80px;
	@media(min-width: 768px){	font-size: 50px;}
	@media(min-width: 992px){	font-size: 70px;}
	@media(min-width: 1200px){	font-size: 80px;}

}
.cooperation-right{
	@media(min-width: 768px){margin-top: 70px;}
	@media(min-width: 992px){margin-top: 25px;}
	.text-box{
		max-width:380px;
		@media(min-width: 768px){max-width:100%;}
	}
}
.cooperation-left .block-subtitle{
	@media(min-width: 480px){max-width: 92%	;}
	@media(min-width:768px){max-width: 100%	;}
}
.cooperation_item-top{
	@media(min-width: 1200px){
		padding-bottom: 0;
		padding-top: 50px;}
	@media(min-width: 1440px){padding-top: 80px;}
}
.cooperation_item--middle{
	@media(min-width: 1200px){
		padding-bottom: 250px;
		padding-top: 180px;
		}
	@media(min-width: 1440px){
		padding-bottom: 330px;
		padding-top: 250px;
	}
}

/*-----join------*/
.join{padding-top: 155px;}
.title-3--join{
	padding-bottom: 15px;
	border-bottom: 1px solid #569ada;
	width: 100%;
}
.join-row{
	padding-top: 26px;
	align-items: flex-end;
	padding-top: 25px;
}
.join-row--center{
	padding-top: 55px;
	align-items: center;
	padding-bottom: 85px;
}
.text-box--join{
	max-width: 245px;
}
.text-box__title--join + .text-box{
	max-width: 630px;
}
.join-img{
	// background-color: #fff;
	// background-image: url(../../img/landing/codex.png);
	// background-size: cover;
	// background-repeat: no-repeat;
	height: 220px;
	margin-right: -30px;
	position: relative;
	overflow: hidden;

	@media(min-width: 480px){
		margin-left: auto;
		margin-right: auto;
		// margin-right: 0px;

		width: 420px;}
	@media(min-width: 768px){
		margin-left: 0;
		width: 100%;
		height: 312px;
		margin-right: 0;
	}

}
.buttons-reg--join{margin-bottom: 15px;}
.text-box--broker{max-width: 720px;}
#screen5{
	position: absolute;
	z-index: 10;
	width: 100%;
	// height: 411px;
}
#screen5 img{
	max-width: 133%;
	// height: 735px;
}
.line-bottom{
	height: 1px;
	background-color: #334860;
	position: absolute;
	left: -10px;
	right: 0;
	bottom: 0;
	z-index: 20;

}
/*---------ava flip-----------*/
/* ГОРИЗОНТАЛЬНЫЙ FLIP */

.flip_effect_horizontal {
	/* общие стили */
	width:70px; /* ширина блока с flip */
	height:70px; /* высота блока с flip */
	position: relative; /* позиционируем для дочерних блоков */
	border-radius: 5px;
	cursor: pointer;
 	transition:1s; /* скорость поворота */
	transform-style: preserve-3d; /* стиль трансформирования 3-д */

	/* для поддержки webkit */
   	-webkit-transition:1s;
  	-webkit-transform-style: preserve-3d;
  	-webkit-transform: rotateY(0deg);

	/* для поддержки moz */
   	-moz-transition:1s;
  	-moz-transform-style: preserve-3d;
  	-moz-transform: rotateY(0deg);

}

	.flip_effect_horizontal:hover {
		/* общие стили */
		transform: rotateY(180deg); /* поворачиваем на 180 градусов по горизонтали */
		/* для поддержки webkit */
		-webkit-transform: rotateY(180deg);
		/* для поддержки moz */
		-moz-transform: rotateY(180deg);
	}

.flip_effect_horizontal .front {
	/* общие стили */
	position: absolute; /* абсолютное позиционирование */
  	width: 100%; /* на всю ширину родительского блока */
	height: 100%; /* на всю высоту родительского блока */
  	background: #FF6600; /* цвет фона */
	backface-visibility: hidden; /* скрываем контент, который отвернут от пользователя */

	/* для поддержки webkit */
	-webkit-backface-visibility: hidden;

	/* для поддержки moz */
	-moz-backface-visibility: hidden;
}

.flip_effect_horizontal .back {
	/* общие стили */
	position: absolute; /* абсолютное позиционирование */
  	width: 100%; /* на всю ширину родительского блока */
	height: 100%; /* на всю высоту родительского блока */
	background:#569ada;; /* цвет фона */
	backface-visibility: hidden; /* скрываем контент, который отвернут от пользователя */
	transform: rotateY(180deg); /* отворачиваем задний блок */
	border-radius: 5px;
	display: flex;
	justify-content: center;
	align-items: center;
	/* для поддержки webkit */
	-webkit-backface-visibility: hidden;
	-webkit-transform: rotateY(180deg);

	/* для поддержки moz */
  	-moz-backface-visibility: hidden;
	-moz-transform: rotateY(180deg);
}
.front{border-radius: 5px}
.agents{
	display: none;
	flex-wrap: wrap;
	@media(min-width: 768px){
		overflow: hidden;
		height: 70px;
		display: flex;
		margin-left: auto;
		margin-right: auto;
		justify-content: space-between;

	}
	@media(min-width: 992px){
		height: 311px;

	}
}
.agents-ava{
	margin-bottom: 8px;
	margin-right: 8px;
	@media(min-width: 992px){margin-right: auto;}
}

.landing-button--join{
	margin-bottom: 0;
	margin-left: auto;
	margin-right: auto;
	margin-top: 20px;
	margin-bottom: 50px;
	@media(min-width: 992px){margin-bottom: 0;}

}
.agents +.landing-button--join{
	display: none;
	@media(min-width: 768px){
		margin-right: auto;
		margin-left: 0;
		display: flex;}
}

.col-emoji {
	align-self: self-start;
	padding-top: 50px;
	margin-bottom: 20px;
	@media(min-width: 768px){
		padding-top: 25px;
		margin-bottom: 0;}
}
.mb-35{
	margin-bottom: 30px;
	@media(min-width: 992px){margin-bottom: 35px;}
}

/*slider*/
.agents-slider-block{
	display: block;
	position: relative;
	height: 120px;
	@media(min-width: 768px){display: none;	}
	.landing-button--join{
		position: absolute;
		left: 30px;
		bottom: 0;
		margin-bottom: 0;

	}
}
.agents-slider{
	position: absolute;
	left: 30px;
	right: 0;
}
.agents-ava-item{
	margin-right: 10px;
	width: 70px;
}

/* параллакс на кавычках*/
#scene {
	width: 100%;
	height: 800px;
	position: relative; /*important!*/
	overflow: hidden; /*important!*/
	@media(min-width: 768px){height: 600px;}
	@media(min-width: 1200px){height: 425px;}
}
.layer {
	width: 100%;
	height: 100%;
}
.layer img {
	position: absolute;
	display: none;
	@media(min-width: 992px){
		display: block;

	}
}
.layer__item1 img {
	display: none;
	@media(min-width: 992px){
		display: block;

	}
	top: -120px;
	right: -60px;
}
.layer__item2 img {
   top: -86px;
   left: -165px;
}

/* //параллакс на кавычках*/
.col-item{
	position: relative;
}
.bgImg{
	position: absolute;
	top: 10px;
	right: -50px;
	@media(min-width: 768px){
		top: -28px;
		right: -51px;

	}

}
.text-fix-width{
	position: relative;
	padding-top: 80px;
	width: 205px;
	@media(min-width: 480px){width: 300px;}
	@media(min-width: 768px){
		padding-top: 50px;
		width: 100%;}
	@media(min-width: 992px){padding-top: 0px;}
}
//-bonus-section
.bonus{
	padding-top: 100px;
	padding-bottom: 100px;
}
.bonus-banner{
	margin-left: -30px;
	margin-right: -30px;
	background-color: #000;
	padding: 40px;
	height: 410px;
	@media(min-width: 480px){text-align: center;}
	@media(min-width: 768px){
		height: 340px;
		margin-left: 0;
		margin-right: 0;

		border-radius: 10px;}

}
.bonus-banner__emoji{
	display: inline-flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 30px;
	height: 115px;
}
.bonus-banner__slogan{
	//max-width: 770px;
	margin-left: auto;
	margin-right: auto;
	width: 100%;

}
.banner-img{
	margin-right: 20px;
}
.banner-img--desk{
	display: none;
	@media(min-width: 480px){display: block;}
}
.icon-ban--desk{
	display: none;
	@media(min-width: 768px){display: block;}
}

.icon-ban--mob{
	display: block;
	@media(min-width: 768px){display: none;}
}
.landing-button.button--join-desk{
	max-width: 363px;
	display: none;
	@media(min-width: 1280px){display: flex;}
	.landing-button--arrow{margin-left: auto;}
}
.landing-button.button--join-tablet{
	display: none;
	@media(min-width: 768px){
		max-width: 265px;
		display: flex;}
	@media(min-width: 1280px){display: none;}
}

.gk_img { border-radius: 50%;}
.p14 {font-size:14px;}
.house_supershort {min-height: 100px;}

//-modal
.codex-text,
.agents-names{
	font-size: 18px;
	color: #334860;
}
.modal-title{
	font-size: 22px;
	font-weight: 700;
	color: #334860;

}
