.mt-0 {	margin-top: 0 !important; } 
.mt-5 {	margin-top: 5px !important; } 
.mt-10 { margin-top: 10px !important; } 
.mt-15 { margin-top: 15px !important; } 
.mt-20 { margin-top: 20px !important; } 
.mt-25 { margin-top: 25px !important; }
.mt-30 { margin-top: 30px !important; } 
.mt-35 { margin-top: 35px !important; }
.mt-40 { margin-top: 40px !important; } 
.mt-45 { margin-top: 45px !important; }
.mt-50 { margin-top: 50px !important; } 
.mt-55 { margin-top: 55px !important; }
.mt-60 { margin-top: 60px !important; } 
.mt-65 { margin-top: 65px !important; }
.mt-70 { margin-top: 70px !important; } 
.mt-75 { margin-top: 75px !important; }
.mt-80 { margin-top: 80px !important; } 
.mt-85 { margin-top: 85px !important; }
.mt-90 { margin-top: 90px !important; } 
.mt-95 { margin-top: 95px !important; }
.mt-100 { margin-top: 100px !important; } 

.mb-0 {	margin-bottom: 0 !important; } 
.mb-5 {	margin-bottom: 5px !important; } 
.mb-10 { margin-bottom: 10px !important; } 
.mb-15 { margin-bottom: 15px !important; } 
.mb-20 { margin-bottom: 20px !important; } 
.mb-25 { margin-bottom: 25px !important; }
.mb-30 { margin-bottom: 20px !important; } 
.mb-35 { margin-bottom: 35px !important; }
.mb-40 { margin-bottom: 40px !important; } 
.mb-45 { margin-bottom: 45px !important; }
.mb-50 { margin-bottom: 50px !important; } 
.mb-55 { margin-bottom: 55px !important; }
.mb-60 { margin-bottom: 60px !important; } 
.mb-65 { margin-bottom: 65px !important; }
.mb-70 { margin-bottom: 70px !important; } 
.mb-75 { margin-bottom: 75px !important; }
.mb-80 { margin-bottom: 80px !important; } 
.mb-85 { margin-bottom: 85px !important; }
.mb-90 { margin-bottom: 90px !important; } 
.mb-95 { margin-bottom: 95px !important; }
.mb-100 { margin-bottom: 100px !important; } 

.ml-0 {	margin-left: 0 !important; } 
.ml-5 {	margin-left: 5px !important; } 
.ml-10 { margin-left: 10px !important; } 
.ml-15 { margin-left: 15px !important; } 
.ml-20 { margin-left: 20px !important; } 
.ml-25 { margin-left: 25px !important; }
.ml-30 { margin-left: 20px !important; } 
.ml-35 { margin-left: 35px !important; }
.ml-40 { margin-left: 40px !important; } 
.ml-45 { margin-left: 45px !important; }
.ml-50 { margin-left: 50px !important; } 
.ml-55 { margin-left: 55px !important; }
.ml-60 { margin-left: 60px !important; } 
.ml-65 { margin-left: 65px !important; }
.ml-70 { margin-left: 70px !important; } 
.ml-75 { margin-left: 75px !important; }
.ml-80 { margin-left: 80px !important; } 
.ml-85 { margin-left: 85px !important; }
.ml-90 { margin-left: 90px !important; } 
.ml-95 { margin-left: 95px !important; }
.ml-100 { margin-left: 100px !important; } 

.mr-0 {	margin-right: 0 !important; } 
.mr-5 {	margin-right: 5px !important; } 
.mr-10 { margin-right: 10px !important; } 
.mr-15 { margin-right: 15px !important; } 
.mr-20 { margin-right: 20px !important; } 
.mr-25 { margin-right: 25px !important; }
.mr-30 { margin-right: 20px !important; } 
.mr-35 { margin-right: 35px !important; }
.mr-40 { margin-right: 40px !important; } 
.mr-45 { margin-right: 45px !important; }
.mr-50 { margin-right: 50px !important; } 
.mr-55 { margin-right: 55px !important; }
.mr-60 { margin-right: 60px !important; } 
.mr-65 { margin-right: 65px !important; }
.mr-70 { margin-right: 70px !important; } 
.mr-75 { margin-right: 75px !important; }
.mr-80 { margin-right: 80px !important; } 
.mr-85 { margin-right: 85px !important; }
.mr-90 { margin-right: 90px !important; } 
.mr-95 { margin-right: 95px !important; }
.mr-100 { margin-right: 100px !important; }

@media (max-width: 768px) {
  .sm-mt-0 {	margin-top: 0 !important; }
  .sm-mt-5 {	margin-top: 5px !important; }
  .sm-mt-10 { margin-top: 10px !important; }
  .sm-mt-15 { margin-top: 15px !important; }
  .sm-mt-20 { margin-top: 20px !important; }
  .sm-mt-25 { margin-top: 25px !important; }
  .sm-mt-30 { margin-top: 30px !important; }
  .sm-mt-35 { margin-top: 35px !important; }
  .sm-mt-40 { margin-top: 40px !important; }
  .sm-mt-45 { margin-top: 45px !important; }
  .sm-mt-50 { margin-top: 50px !important; }
  .sm-mt-55 { margin-top: 55px !important; }
  .sm-mt-60 { margin-top: 60px !important; }
  .sm-mt-65 { margin-top: 65px !important; }
  .sm-mt-70 { margin-top: 70px !important; }
  .sm-mt-75 { margin-top: 75px !important; }
  .sm-mt-80 { margin-top: 80px !important; }
  .sm-mt-85 { margin-top: 85px !important; }
  .sm-mt-90 { margin-top: 90px !important; }
  .sm-mt-95 { margin-top: 95px !important; }
  .sm-mt-100 { margin-top: 100px !important; }

  .sm-mb-0 {	margin-bottom: 0 !important; }
  .sm-mb-5 {	margin-bottom: 5px !important; }
  .sm-mb-10 { margin-bottom: 10px !important; }
  .sm-mb-15 { margin-bottom: 15px !important; }
  .sm-mb-20 { margin-bottom: 20px !important; }
  .sm-mb-25 { margin-bottom: 25px !important; }
  .sm-mb-30 { margin-bottom: 20px !important; }
  .sm-mb-35 { margin-bottom: 35px !important; }
  .sm-mb-40 { margin-bottom: 40px !important; }
  .sm-mb-45 { margin-bottom: 45px !important; }
  .sm-mb-50 { margin-bottom: 50px !important; }
  .sm-mb-55 { margin-bottom: 55px !important; }
  .sm-mb-60 { margin-bottom: 60px !important; }
  .sm-mb-65 { margin-bottom: 65px !important; }
  .sm-mb-70 { margin-bottom: 70px !important; }
  .sm-mb-75 { margin-bottom: 75px !important; }
  .sm-mb-80 { margin-bottom: 80px !important; }
  .sm-mb-85 { margin-bottom: 85px !important; }
  .sm-mb-90 { margin-bottom: 90px !important; }
  .sm-mb-95 { margin-bottom: 95px !important; }
  .sm-mb-100 { margin-bottom: 100px !important; }

  .sm-ml-0 {	margin-left: 0 !important; }
  .sm-ml-5 {	margin-left: 5px !important; }
  .sm-ml-10 { margin-left: 10px !important; }
  .sm-ml-15 { margin-left: 15px !important; }
  .sm-ml-20 { margin-left: 20px !important; }
  .sm-ml-25 { margin-left: 25px !important; }
  .sm-ml-30 { margin-left: 20px !important; }
  .sm-ml-35 { margin-left: 35px !important; }
  .sm-ml-40 { margin-left: 40px !important; }
  .sm-ml-45 { margin-left: 45px !important; }
  .sm-ml-50 { margin-left: 50px !important; }
  .sm-ml-55 { margin-left: 55px !important; }
  .sm-ml-60 { margin-left: 60px !important; }
  .sm-ml-65 { margin-left: 65px !important; }
  .sm-ml-70 { margin-left: 70px !important; }
  .sm-ml-75 { margin-left: 75px !important; }
  .sm-ml-80 { margin-left: 80px !important; }
  .sm-ml-85 { margin-left: 85px !important; }
  .sm-ml-90 { margin-left: 90px !important; }
  .sm-ml-95 { margin-left: 95px !important; }
  .sm-ml-100 { margin-left: 100px !important; }

  .sm-mr-0 {	margin-right: 0 !important; }
  .sm-mr-5 {	margin-right: 5px !important; }
  .sm-mr-10 { margin-right: 10px !important; }
  .sm-mr-15 { margin-right: 15px !important; }
  .sm-mr-20 { margin-right: 20px !important; }
  .sm-mr-25 { margin-right: 25px !important; }
  .sm-mr-30 { margin-right: 20px !important; }
  .sm-mr-35 { margin-right: 35px !important; }
  .sm-mr-40 { margin-right: 40px !important; }
  .sm-mr-45 { margin-right: 45px !important; }
  .sm-mr-50 { margin-right: 50px !important; }
  .sm-mr-55 { margin-right: 55px !important; }
  .sm-mr-60 { margin-right: 60px !important; }
  .sm-mr-65 { margin-right: 65px !important; }
  .sm-mr-70 { margin-right: 70px !important; }
  .sm-mr-75 { margin-right: 75px !important; }
  .sm-mr-80 { margin-right: 80px !important; }
  .sm-mr-85 { margin-right: 85px !important; }
  .sm-mr-90 { margin-right: 90px !important; }
  .sm-mr-95 { margin-right: 95px !important; }
  .sm-mr-100 { margin-right: 100px !important; }
}