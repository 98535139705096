.button {
	font-size: 16px;
	line-height: 16px;
	border: none;
	border-radius: 3px;
	text-align: center;
	background-color: @btnBg;
	color: @white;
	display: inline-block;
	outline: none;
	transition: .25s;

	&:hover, &:active, &:focus {
		color: @white;
		text-decoration: none;
		opacity: .85;
	}
	@media(min-width: 768px){font-size: 14px;}
}
.button--basic{
	height: 60px;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: 700;
	padding: 0 10px;
	@media(min-width: 768px){
		height: 30px;
		width: auto;
		display: inline-flex;
		/**padding-left: 32px;
		padding-right: 32px;**/
	}
}
.pointer{
	cursor:pointer;
}

.button--white{
		cursor: pointer;
		font-size: 16px;
		font-weight: 400;
		min-height: 30px;
		align-items: center;
		display: inline-flex;
		padding-left:8px;
		padding-right:8px;
		color: @white;
		border: 1px solid @white;
		border-radius: 3px;
		text-align: center;
		letter-spacing: .5px;
		margin-bottom: 3px;

		@media(min-width: 768px){
			.badge-group{flex-shrink: 0}
			min-height: 24px;
			font-size: 14px;
		}
}

.button--white:hover{	color: @white;
	border: 1px solid @white;
	box-shadow: 0 0 10px 1px #ffffff3b;
}

#back-top{
	position: fixed;
	bottom: 40px;
	right: 1%;
}

.btn-add {
	position: relative;
	min-width:270px;
	height:60px;
	padding: 1.6rem 4.8rem ;
	white-space: nowrap;

	font-weight: 500;


	@media (min-width:768px) {

		padding: 7px 10px 8px 28px;
		min-width: 200px;
		height: 30px;
	}
}
.btn-add:before{
	content: "";
	position: absolute;
	left: 0;
	top: 50%;
	transform: translate(170%, -43%);
	width: 20px;
	height: 20px;
	background-image: url(../../img/plus.svg);
	background-repeat: no-repeat;

}
@media(min-width: 768px){
.btn-add:before{
	content: "\f067";
	position: absolute;
	left: 0;
	top: 50%;
	transform: translate(170%, -30%);
	width: 20px;
	height: 20px;
	font-size: 26px;
	font-family: FontAwesome;
	width: 4px;
	height: 11px;
		font-size: 14px;
		transform: translate(245%, -80%);

	}
}

.btn-tel{
	width: 120px;
	height: 30px;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;

	line-height: normal;

	font-weight: 700;

	@media (min-width:768px) {
		padding: 6px 10px;
		width: 108px;
		min-height: 24px;
	}
}

.mobile-button{
	display: flex;
	position: relative;
	color: @white;
	border-radius: 3px;
	width: 40px;
	height: 40px;
	img{margin:auto;}
	transition: .25s;

	&:hover, &:active, &:focus {
		color: @white;
		opacity: .85;
	}
}
.btn-add.section-btn{
	padding-right: 32px;
	text-align: center;

	padding-left: 32px;
}
.btn-add.section-btn:before{display: none;
}
.card a.button{
	color: #fff;
	font-weight: 700;
	padding: 20px 50px;
}
.button-wrapper{
	margin-top: 35px;
	margin-bottom: 20px;

}
.button-wrapper--mobile{
	display: block;
	@media(min-width: 768px){display: none;}
}
.section-btn{
	border-radius: 5px;
}
//-синяя кнопка с иконкой колокольчик
.btn-mode1{
	display: inline-flex;
	align-items: center;
	height: 60px;
	padding-left: 20px;
	padding-right: 20px;
	font-weight: 700;
	width: 100%;
	.icon-notify{
		height: 60px;
		display: flex;
		padding-right: 19px;
		border-right: 1px solid #2874bc;

		svg{
			margin: auto;
			width: 19px;
			height: 22px;
		}
		path {fill: @white;}
		@media(min-width: 768px){
			border-right: 0px;
			padding-right: 9px;
		}
	}
	.btn-text--mobile{
		font-size: 16px;
		height: 60px;
		padding-top: 23px;
		padding-left: 39px;
		border-left: 1px solid #569ada;
		@media(min-width: 768px){display:none;}
	}
	.btn-text{
		display:none;
		@media(min-width: 768px){display: block;}
	}
	@media(min-width: 768px){
		width: auto;
		height: 30px;
	}
}
/*КАСТОМНЫЙ ЧЕКБОКС*/
.checkbox-label{
	display: flex;
	align-items: center;
	cursor: pointer;
    .checkbox-title{
    	margin-right: 10px;
    }
}
.check--hc-type{
	.checkbox-title{
		color:@btnBg;
		border-bottom: 1px dashed @linkLine;}
}
.check--hc-type:hover{
	.checkbox-title{
		color:@redText;
		border-bottom-color:@linkActiveLine;
	}
}

.form-checkbox{
	width: 0.01px;
}
.form-checkbox:focus + .custom-checkbox{
	border: 1px solid @btnBg;
}

.custom-checkbox{
	position: relative;
	display: inline-block;
	min-width: 20px;
	min-height: 20px;
	border-radius: 50%;
	border: 1px solid @btnBg;
	background-color: #fff;
	transition: 0.5s ease-out;
}

.form-checkbox:checked + .custom-checkbox:after{
	content:'';
	position: absolute;
	border: 1px solid @btnBg;
	min-width: 24px;
	min-height: 24px;
	border-radius: 50%;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}
.form-checkbox:checked + .custom-checkbox{
	border: 2px solid #fff;
	background-color: @btnBg;

}

.buttons-reg{
	// padding-bottom: 85px;
	display: block;
	@media(min-width: 768px){display: flex;}
}


.buttons-reg--btn{
	width: 100%;
	max-width: 480px;
	height: 60px;
	border-radius: 4px;
	text-align: center;
	cursor: pointer;
	border: 0px;
	font-size: 20px;
	font-weight: 700;
	padding-top: 20px;
	// padding-right: 20px;
	background-color: #3d5a96;
	box-shadow: 0 10px 20px rgba(61, 90, 150, 0.35);
	transition: .5s;
	@media(min-width: 768px){font-size: 16px;}
	@media(min-width: 992px){font-size: 20px;}
}

.buttons-reg--btn:hover{background-color: #334860;}

.btn-fb{
	margin-bottom: 20px;
	@media(min-width: 768px){
		margin-right: 20px;
		margin-bottom: 0;}
}

.btn-desk{
	display: none;
	@media(min-width: 768px){display: block;}
}

.btn-mob{
	display: block;
	@media(min-width: 768px){display: none;}
}

.form-group label{
	color: #000;
	font-size: 16px;
	font-weight: 700;
	margin-left: 0.1em;
	margin-bottom: 10px;
}
