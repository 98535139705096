.full-description{
	padding-bottom: 24px;
}
.hc-description{
	font-size: 16px;
	padding-left: 25px;
	padding-right: 25px;
	padding-top: 30px;
	@media(min-width: 1350px){
		font-size: 16px;
		padding-left: 25px;
		padding-right: 0;
		padding-top: 10px;
	}
	.big-badge{
		position: relative;
		left: -25px;
		padding-left: 27px;
		@media(min-width: 576px){
			left:auto;
		}
	}
}
.hc-description-column{
	display: flex;
	align-items: center;
	justify-content: space-between;

}
.hc-description-column--area{
	margin-bottom: 25px;
	@media(min-width: 768px){

	}
}
.hc-description-item--align{
	align-items: flex-end;
	height: 100%;
	justify-content: center;
	@media(min-width: 768px){
		align-items: center;
		justify-content: flex-start;
		height: auto;
	}
	@media(min-width: 992px){
		align-items: flex-end;
		text-align: left;
	}

	@media(min-width: 1350px){
		align-items: center;
	}
}
.hc-description-item{
	display: flex;
	flex-direction: column;
}

.hc-description-item--btn{
	@media(min-width: 768px){
		align-items: flex-end;
		justify-content: center;

	}
	.big-badge{top:-12px;}
}
// .text-bl{
// 	height: 200px;
// 	border: 2px solid red;
// 	margin-bottom: 50px;
// }
// .text-mini{
// 	height: 100px;
// 	border-bottom: 2px solid green;
// }
.hc-description__center-item{
	padding-bottom: 28px;
}
.hc-description__column{
	display: flex;
	flex-direction: column;
	align-items: flex-end;
}
.headline-body--pl{
	@media(min-width: 768px){
		padding-left: 10px;
	}
	@media(min-width: 992px){
		padding-left: 0px;
	}

}
.text-body--pr{
		@media(min-width: 768px){
		padding-right: 10px;
	}
	@media(min-width: 992px){
		padding-right: 0px;
	}
	@media(min-width: 1350px){
		padding-right: 30px;
	}
}
.hc-description__top{
	padding-bottom: 20px;
}
.col-pl-0{
	padding-left: 0;
	@media(min-width: 768px){
		padding-left: 12px;
	}
}
.hc-description__footer{
	margin-left: -16px;
	//.text-body{padding-bottom: 16px;}
	.footer-links-item{
		font-size: 14px;
	}
}
.hc-description__footer-links{
	padding-top: 4px;
	display: flex;
	flex-direction: column;
	.simple-link {

		margin-bottom: 12px;

	}
	@media(min-width: 576px){
		align-items: center;
		flex-direction: row;
		justify-content: space-between;
	}
}
/**.hc-description__footer-content{
	display: none;
	@media(min-width: 768px){
		display: block;
	}
}**/

.description__visible-text{
	position: relative;
}

.description__hidden-text{
	display: none;
}