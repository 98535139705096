.header{
	background-color: @white;
	box-shadow: 0px 2px 0px 0px @lightBorder;
}
.logo-icon {
	margin-right: 20px;
	svg{
		height: 40px;
	width: 200px;
	}

}
.header-top{
	position: relative;
	width: 100%;
	display: flex;
	flex-direction: column;

	height: 141px;
	background-color: @white;

	padding-top: 18px;
	padding-bottom: 2rem;
	@media(min-width: 768px){
		padding-top: 2em;
		align-items: center;
		flex-direction: row;
		height: 80px;
	}
	.logoblock{
		margin-bottom: 1.8rem;
		margin-right: 2.5rem;
		@media(min-width: 768px){margin-bottom: 0px;}
	}
	.links-block{
		@media(min-width: 768px){margin-left: auto;padding-left: 1rem;}
	}

	.mobile-button{
		display:flex;
		position: absolute;
		right: 0px;
		top: 18px;
		@media(min-width: 768px){
			top: 0;
			right: 0;
			position: relative;
		}
		@media(min-width: 992px){display: none;}
	}
	.button-block{
	.button.btn-add{
		display: none;
		@media(min-width: 992px){display: block;}
	}
	.logo-text{
		display: none;
		@media(min-width: 1350px){
			display: block;
		}
	}
}

}
.links-block{align-items: center;}

.logoblock{
	align-items: center;
	.logo-icon{

		margin-right: 3rem;
		@media(min-width: 1350px){
			margin-right: 1.1rem;
		}
	}

	.logo-text{
		display: none;
		color:@title-1;
		width: 210px;
		line-height: 1.2;
		@media(min-width: 1200px){display: block;}
	}

	@media(min-width: 768px){justify-content: flex-start;}
	@media(min-width: 992px){width: auto;}
}


.header__icon-group{
	display: flex;
	align-items: center;
	padding-top: 6px;
	margin-right: 2.2rem;

	@media(min-width: 768px){
		margin-bottom: 0;
	}
	.header__icon-group__item--add{
		margin-right: -32px;
		// order:-1;
		@media(min-width: 768px){

			margin-right: 20px;}

	}
}
.header__icon-group__item{padding-bottom: 8px;}
.header__icon-group__item:hover{
	border-bottom: 2px solid @activeIcon;
	padding-bottom: 6px;
	i,
	.icon-heart{
		path{fill:@activeIcon;}

	}
	.icon-notify--path{

		fill:@activeIcon;
	}

}
.header__icon-group__item:not(:last-child){
	margin-right: 32px;
	@media(min-width: 768px){margin-right: 20px;}
}

.header__user-lk{
	display: flex;
	align-items: end;
	justify-content: flex-end;
	@media(min-width: 768px){
		align-items: center;
		justify-content: flex-start;
	}

}
.header__user__avatar{
	font-weight: bold;
	right: 0px;
	border: 1px solid #e5e9f2;
	border-radius: 3px;
	@media(min-width: 768px){
		position: relative;
		right:0;
		bottom: 0;

	}
	@media(min-width: 1200px){
		margin-right: 1.3rem;}
}

.header__user__avatar:hover{
	border: 1px solid #2c81d1;
}

.header__user__lk-dropdown{
	display: none;

	a{
		margin-right: 10px;
		display: inline-block;

	}
	@media(min-width: 1200px){
		//display: block;
		display: inherit;
	}
}

.invisible {
	visibility: hidden;
}

#usermenu {
	position: absolute;
	top: 0;
	margin-top: 141px;
	padding-right: 3rem;
	padding-top: 1rem;
	padding-bottom: 1rem;
	box-shadow: 0px 2px 0px 2px #e5e9f2;
	background: #fff;
	right: 0;
	border-bottom-left-radius: .25rem;
	border-bottom-right-radius: .25rem;
	z-index: 40;

	@media(min-width: 768px){
		margin-top: 80px;
	}
}

#usermenu ul li {
	margin-bottom: 1rem;
	list-style: none;
	display: flex;
}

.header-content{
	display: flex;
	align-items: center;
	.logoblock{margin-right: auto;}

}
.header-info-block{
	display: none;
	margin-right: 45px;
	color: #000;
	@media(min-width: 1200px){display: flex;}
}
.header-info{
	display: flex;
	align-items: center;
}
.header-info__number{
	font-size: 40px;
}
.header-info__text{
	font-size: 14px;
	padding-left: 12px;
	max-width: 170px;
}

.header-btn{
	border:0px;
	border-radius: 5px;
	background-color: #2c81d1;
	color: #fff;
	font-weight: 700;
	border-radius: 5px;
	min-width: 90px;
	height: 30px;
	text-align: center;
	padding-top: 2px;
	transition: .5s;
	cursor: pointer;
}
.header-btn:hover{
	box-shadow: 0 0 12px 4px rgba(44, 129, 209, .45);
}

//-form
#contact-form{width: 100%;}
.form-item{
	position: relative;

}
.input-placeholder{
	// display: none;
	//position: absolute;
	//top: 10px;
	padding: 2px 4px;
	background-color: #FFF;
	//left: 16px;
	font-size: 14px;
	color: #4d4d4d;
	transition:.3s;
}
.input-placeholder.active{
	top: -12px;
	color: #3d5a96;
	font-weight: 700;
}
.form-field{
	width: 100%;
	height: 45px;
	padding-left: 16px;
	-webkit-border-radius: 5px;
	border-radius: 5px;
	border:1px solid #4d4d4d;
	margin-bottom: 20px;
}
.form-field.active{
	border:1px solid #3d5a96;
	-webkit-box-shadow: 0 0 8px rgba(61, 90, 150, 0.35);;
	box-shadow: 0 0 8px rgba(61, 90, 150, 0.35);;
}
/*
.form-footer{
	display: flex;
	-webkit-align-items: center;
	align-items: center;
	-webkit-justify-content: center;
	justify-content: center;
}*/
.form-footer .buttons-reg--btn{
	height: 40px;
	// min-width: 200px;
	margin-left: auto;
	color: #FFF;
	font-size: 16px;
	font-weight: 700;
	padding-top: 5px;
	padding-left: 16px;
	padding-right: 16px;
}
.contact-form__success {

	background-color: #41ae1b;
	position: absolute;
	top: 0%;
	left: 0%;
	right: 0;
	display: block;

	color: white;
	padding: 30px 20px;
	font-weight: 700;
	border-radius: 8px;
	padding: 40px;
	padding-bottom: 60px;
	font-size: 20px;
	bottom: 0;
	text-align: center;
	min-width: 300px;
}
#regButton{width: 220px;

}
#rezetButton{
	width: 130px;
	margin-left: 0;
}


/** СТИЛИ МОДАЛЬНЫХ ОКОН РЕГИСТРАЦИИ **/
.form-input {
	width: 100%;
	border: 1px solid #b3b3b3;
	border-radius: 3px;
	background: #fff !important;
	outline: none;
	background-color: #fff;
	color: #7f7f7f;
	height: 42px;
	padding-left: 9px;
	cursor: text;
	font-size: 14px;
	-webkit-transition: 0.5s;
	-o-transition: 0.5s;
	transition: 0.5s;
}
.text-link {
	font-size: 16px;
	color: #2c81d1;
	border-bottom: 1px dashed rgba(44, 129, 209, 0.2);
}
.text-link--solid {
	border-bottom: 1px solid rgba(44, 129, 209, 0.2);
}

.button--basicbig {
	height: 60px;
	width: 100%;
	//max-width: 240px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	font-weight: 700;
	font-size: 20px;
	cursor: pointer;
	border: 0px;
	margin-right: 28px;
	margin-bottom: 0;
	-webkit-box-shadow: 0px 10px 20px 0px rgba(88, 122, 154, 0.35);
	box-shadow: 0px 10px 20px 0px rgba(88, 122, 154, 0.35);
}
/**
.button {
    font-size: 16px;
    line-height: 16px;
    border-radius: 3px;
    text-align: center;
    background-color: #2c81d1;
    color: #fff;
    display: inline-block;
    outline: none;
    -webkit-transition: .25s;
    -o-transition: .25s;
    transition: .25s;
}**/
.footer-tooltip {
	display: none;
	padding: 10px;
	border-radius: 5px;
	background-color: #000;
	position: absolute;
	top: -20px;
	left: 50%;
	font-size: 14px;
	text-align: center;
	max-width: 250px;
	min-width: 250px;
	-webkit-transform: translate(-50%, -100%);
	-ms-transform: translate(-50%, -100%);
	transform: translate(-50%, -100%);
}
.footer-tooltip:after {
	content: "";
	width: 0px;
	height: 0px;
	position: absolute;
	border: 10px solid #000;
	border-bottom: 0px;
	border-left-color: transparent;
	border-right-color: transparent;
	bottom: -8px;
	left: 50%;
}
.formModales .modal-dialog {
	max-width: 860px;
}
@media (max-width: 480px) {
	.formModales .modal-dialog {
		margin: 0;
	}
}
.formModales .modal-content {
	background-color: #FFF;
}
.formModales .modal-header {
	position: relative;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	padding: 25px 25px 10px;
	font-weight: 700;
	border-bottom: 0px;
}
@media (min-width: 768px) {
	.formModales .modal-header {
		padding: 30px 35px 10px;
	}
}
.formModales .header-border {
	display: none;
	position: absolute;
	left: 35px;
	right: 35px;
	top: 85px;
	height: 1px;
	background-color: #e5e5e5;
}
@media (min-width: 768px) {
	.formModales .header-border {
		display: block;
	}
}
.formModales .modal-title {
	font-size: 28px;
	margin-bottom: 23px;
	line-height: 1;
}
@media (min-width: 768px) {
	.formModales .modal-title {
		margin-bottom: 0;
		font-size: 40px;
	}
}
.formModales .modal-title-mb-0 {
	margin-bottom: 0;
}
.formModales .modal-title--mr {
	margin-right: 1.1em;
}
.formModales .close {
	outline: none;
	position: absolute;
	right: 16px;
	top: 20px;
	color: #CECECE;
	line-height: 0.7;
}
@media (min-width: 768px) {
	.formModales .close {
		right: 40px;
		top: 30px;
	}
}
.formModales .close:hover {
	opacity: 0.7;
}
.formModales .facebook-btn {
	height: 40px;
	font-size: 14px;
	font-weight: 700;
	padding: 10px 31px;
	color: #FFF;
	background-color: #3d5a96;
	border-radius: 4px;
	display: inline-block;
}
@media (min-width: 768px) {
	.formModales .facebook-btn {
		padding: 10px 21px;
	}
}
.formModales .facebook-btn:hover {
	background-color: #334860;
	color: #FFF;
}
.formModales .modal-body {
	padding: 25px 25px 35px;
}
@media (min-width: 768px) {
	.formModales .modal-body {
		padding: 25px 35px 38px;
	}
}
.formModales form {
	width: 100%;
	position: relative;
	z-index: 1000;
}
.formModales .form-item {
	max-width: 500px;
	margin-bottom: 10px;
	position: relative;
}
.formModales .form-item__icon {
	position: absolute;
	right: 13px;
	top: 36px;
	cursor: pointer;
}
.formModales .form-item__icon:hover .footer-tooltip {
	display: block;
}
.formModales .form-item__label {
	color: #000;
	font-size: 16px;
	font-weight: 700;
}
.formModales .form-input {
	outline: none;
	display: block;
	width: 100%;
	color: #838385;
	font-size: 18px;
	height: 50px;
}
@media (min-width: 768px) {
	.formModales .form-input {
		font-size: 20px;
	}
}
.formModales .form-item--email {
	margin-bottom: 20px;
}
.formModales .form-footer {
	padding: 30px 25px 38px;
	background-color: #DBE6FF;
}
@media (min-width: 768px) {
	.formModales .form-footer {
		padding: 30px 35px 50px;
	}
}
.formModales .button--basic {
	font-size: 20px;
	cursor: pointer;
	border: 0px;
	-webkit-box-shadow: 0px 10px 20px 0px rgba(88, 122, 154, 0.35);
	box-shadow: 0px 10px 20px 0px rgba(88, 122, 154, 0.35);
	margin-bottom: 45px;
}
@media (min-width: 480px) {
	.formModales .button--basic {
		max-width: 240px;
	}
}
@media (min-width: 768px) {
	.formModales .button--basic {
		margin-right: 28px;
		margin-bottom: 0;
	}
}
.formModales .add-block {
	font-size: 16px;
	color: #000;
}
.formModales .add-block p {
	line-height: 1.5;
	padding-bottom: 4px;
}
@media (min-width: 768px) {
	.formModales .add-block--restory {
		max-width: 200px;
	}
}
@media (min-width: 768px) {
	.formModales .add-block--fix {
		max-width: 500px;
	}
}
.formModales .form-footer {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: left;
}
.formModales .modal-flex {
	display: block;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
@media (min-width: 768px) {
	.formModales .modal-flex {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
	}
}
.formModales .modal-tooltip {
	min-width: 170px;
	color: #FFF;
	-webkit-transform: translate(-78%, -100%);
	-ms-transform: translate(-78%, -100%);
	transform: translate(-78%, -100%);
}
.formModales .footer-tooltip:after {
	left: 71%;
}
.formModales .mt-45 {
	margin-top: 40px;
}
.text-link--bold {
	font-weight: 700;
}
.modal-body--reg {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}
.modal-col {
	width: 100%;
	max-width: 480px;
}
@media (min-width: 768px) {
	.modal-col:first-child {
		margin-right: 40px;
	}
}
@media (min-width: 1200px) {
	.modal-col:first-child {
		min-width: 480px;
	}
}
.modal-col:last-child {
	display: none;
	padding-top: 26px;
}
@media (min-width: 768px) {
	.modal-col:last-child {
		display: block;
	}
}
.page-modal {
	position: relative;
}
.modal-content-part {
	-webkit-transition: .5s;
	-o-transition: .5s;
	transition: .5s;
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	border-radius: 0px 0px 10px 10px;
	overflow: hidden;
	opacity: 0;
}
@media (min-width: 480px) {
	.modal-content-part {
		border-radius: 10px;
	}
}
@media (min-width: 768px) {
	.modal-content-part {
		top: auto;
	}
}
.modal-content-part.active {
	opacity: 1;
}