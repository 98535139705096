.card-offer{
	display: block;
	border: 1px solid @lightBorder;
	border-radius: 10px 10px 10px 10px;
	margin-top: 20px;
	margin-bottom: 20px;

	@media(min-width:992px){display: flex;}

	.card-slider{
		position: relative;
		margin-left: -23px;
		margin-right: -29px;
		margin-bottom: 20px;
		@media(min-width:1200px){
			margin-right: 0;
			margin-left: 0;
		}
	}

	.card-description{
		width: 100%;
		position: relative;
		background-color: @white;
		border-radius: 10px 10px 10px 10px;
       
        @media(min-width:768px){border-radius: 10px;}
        @media(min-width:992px){
        	border-radius: 10px 0 0 10px;
        	width: 80%;
        	padding: 20px;
        }
    }
   
}

.card-description-top{
	padding: 10px;
	padding-top: 27px;
	padding-bottom: 0;
	border-radius: 10px 10px 10px 10px;

	@media(min-width:768px){padding-top: 16px;}

	@media(min-width:992px){padding-top: 0;}

	.fadeOut:after{
		display: block;
		bottom: 60px;
		background-color: rgba(255,255,255,0);
		background-image: linear-gradient(to bottom, rgba(255,255,255,0) 0, rgb(255,255,255) 100%);
		@media(min-width:768px){
			display: none;
		}
	}
}

.offer-row{
	display: flex;
	align-items: center;
	padding-bottom: 20px;
	padding-left: 20px;

	padding-right: 20px;

	.appartment-card--address{margin-right: 15px;}

	.badge{margin-right: 10px;}

	.metro-block{
		@media(min-width:768px){padding-right: 10px;}
	}

	.appartment-page-link{
 
    	margin-left: auto;
    	.share-link-text{display: none;}
    }

    .appartment-card__toolbar{
    	width: 100%;
    }
}

.offer-row--swipe{
	box-sizing: border-box;
	padding: 16px;
	padding-top: 0;
	padding-bottom: 30px;
	overflow-x: auto;
	overflow-y: hidden;
	white-space: nowrap;


	&::-webkit-scrollbar {
		display: none;
	}

	@media(min-width:768px){
		margin-bottom: 0;
		width: 93%;
		max-width:940px;

	}
}


.offer-row--transform{
	display: block;

	@media(min-width:768px){
		display: flex;
	}
	@media(min-width:1200px){
		padding-top: 0;
	}

	.appartment-card__price--num{
		font-size: 20px;
		padding-right: 0;
		
		@media(min-width:768px){
			padding-bottom: 0;
			
			margin: 0;
		}
	}
}

.appartment-card__price-section{
	display: flex;
	align-items: center;
	margin-bottom: 18px;

	.icon-price-down{
		display: flex;
		margin-left: 10px;
		display: inline-block;
		cursor: pointer;
	
	}

		@media(min-width:768px){
			padding-right: 28px;
		}
	}

.offer-row--info{
	display: block;
	position: relative;

	.text-hidden{
		display: none;

		@media(min-width: 768px){
			display: block;
		}
	}
	
}

.show-hidden-text{
	padding-top: 12px;
	
	@media(min-width: 768px){
		display: none;
		padding-top: 0;
	}
}

.offer-row--title{
	align-items: flex-start;
	flex-direction: column;
	padding-right: 25px;

	@media(min-width:1350px){
		flex-direction: row;
		
	}

.simple-link{
	margin-bottom: 17px;
	font-size: 16px;
	@media(min-width:768px){
		font-size: 20px;
		margin-right: 30px;
	}
}

.appartment-card__price--area{
	margin-right: 30px;
	}
}

.icons-like{
	display: flex;
	
	margin-left: auto;

	.icon-group{
		
		@media(min-width:768px){
			display: flex;
		}
	}

	.group--icon-dislike{margin-left: 22px;
	
	}
}

.row-line{
	padding-bottom: 20px;
	height: 1px;
	width: 100%;
	border-bottom: 1px solid #ced9f2;
	display: none;
	@media(min-width:992px){
		display: block;
	}
}

.card-description-chat{
	padding: 10px;
	padding-bottom: 20px;
	padding-top: 20px;
	background-color: #f5f6fa;
	border-radius: 0 0 10px 10px;

	@media(min-width:768px){background-color: @white;}
	@media(min-width:992px){border-radius: 0;}
}

.offer-row--tools{
	.appartment-card__toolbar{
		flex-wrap: wrap;
		@media(min-width:480px){flex-wrap: nowrap;}
	}
	.appartment-card__toolbar-item--mail{
		padding-right: 0;
		@media(min-width:768px){
			padding-right: 28px;
		}
	}
	.appartment-card__toolbar-item--tel{
		margin-right: auto;
		@media(min-width:1200px){margin-right: 0;}
	}

	.appartment-card__toolbar-item--heart{
		padding-right: 20px;
		@media(min-width:768px){
			padding-right: 28px;
		}
	}

	.appartment-card__toolbar-item--btn{
		width: 100%;
		margin-bottom: 20px;

		@media(min-width:480px){
			padding-right: 28px;
			flex-grow: inherit;
			width: auto;
			margin-bottom: 0;
		}

		a{width: 100%;
			@media(min-width:768px){
				width: auto;
			}
		}
	}
}

.card-offer--hot{
	.appartment-card__price--usd{
		display: block;
		color: @black;
		font-weight: 700;
		 font-size: 16px;
    	padding-right: 16px;
		@media(min-width:768px){
			display: none;
		}
	}
	.repaire-item-box{
		display: none;
		@media(min-width:768px){
			display: flex;
		}
	}
	.appartment-card__price--area{
		margin-right: 0;
		@media(min-width:768px){margin-right: 30px;}
	}
}

.card-offer--new{
	.mobile-price{
		display: none;
		@media(min-width:768px){display: flex;}
	}

	.appartment-card__price--area{
		display: none;
		@media(min-width:768px){display: block;}
	}
	.appartment-card__price--usd{
		display: none;
	}
}

.mobile-price{
	display: flex;
}
.double-repaire-box{
	.repaire-item-box{display: flex;}
	@media(min-width: 768px){display: none;}
}

