.metro-station{
	display: inline-flex;
	flex-direction: column;
	width: fit-content;
	@media(min-width: 768px){
		flex-direction: row;
	align-items: center;
	}
	.icon-metro{
		padding-bottom: 3px;
		margin-right: 7px;
	}

	.first-text{
		display: inline-block;

		position: relative;
	}
	.middle-text{
		padding-right: 5px;
		color: @grey;
	}
	.last-text{

		padding-right: 5px;
		color: @grey;
		@media(min-width: 768px){

		}

	}
}
.last-icon{
	display: block;
	@media(min-width: 768px){display: none;}

	margin-top: 4px;
	@media(min-width: 768px){align-items: flex-end;}
	@media(min-width: 1350px){margin-top: 0px;}

}
.text-info--metro{
	display: flex;
	flex-direction: column;
	position: relative;
	@media(min-width: 768px){flex-direction: row;}

	.icon-metro{
		position: absolute;
		top: -3px;
		right: -35px;
		@media(min-width: 768px){
			right: auto;
			left: -25px;
			top: -2px;
		}
	}
}
.text-info--metro{
	flex-wrap: wrap;
	padding-left: 8px;
	@media(min-width: 768px){padding-left: 25px;}
}
.text-info--metro .metro-station:last-child{
	.last-text--comma{display: none;}
}

.metro-station--flex{
	display: flex;
	flex-direction: row;
	align-items: center;
	padding-left: 40px;
	@media(min-width: 768px){padding-left: 28px;}

	.icon-metro{
		position: absolute;
		left: -35px;
		top:-2px;
		@media(min-width: 768px){left: -25px;}
	}
	.last-text{
		display: none;
		@media(min-width: 768px){display: block;
		}
	}

	.icon-man{
		position: relative;
		top: -5px;
	}
}

.metro-block{
	align-items: center;
	.icon-metro{
		margin-right: 9px;
		padding-bottom: 2px;
	}
	.metro-name:last-child{
		.metro-name--comma{display: none;}

	}
}