.footer{
	padding-top: 66px;
	height: 207px;
	position: relative;
	padding-left: 17px;
	@media(min-width: 768px){
	padding-left: 0px;
	}
	@media(min-width: 1200px){
		height: 270px;
		overflow:hidden;
	}
	@media(min-width: 1350px){
		flex-direction: row;
		align-items: center;
		height: 207px;
	}
	.footer-top{
		background: transparent;
		position: relative;
		z-index: 2;
		padding-bottom: 2.2rem;
		flex-direction: column;
		align-items: flex-start;
		justify-content: space-between;
		@media(min-width: 992px){
			flex-direction: row;
			align-items: center;}
		@media(min-width: 1350px){justify-content: flex-start;}
	}
	.simple-link{
		border-bottom: 1px solid rgba(44,129,209, .2);
		margin-right: 2rem;
		padding-top: 1rem;

		@media(min-width: 992px){padding-top: 0px;
		@media(min-width: 1350px){margin-right: 38px;}
		}

	}
	.links-block{
		width: 300px;
		flex-wrap: wrap;
		margin-bottom: 2.2rem;
		@media(min-width: 480px){width:  auto;}
		@media(min-width: 992px){margin-bottom: 0;}
	}
	.button-block{
		@media(min-width: 992px){}
		@media(min-width: 1350px){margin-left: auto;}
	}
	.logoblock{
		align-items: flex-start;
		flex-direction: column;
		padding-bottom: 20px;

		@media(min-width: 1350px){

			align-items: center;
			flex-direction: row;
			margin-right: 15.2em;
			padding-bottom: 0;
		}
		.logo-text{
			width: 270px;
			font-size: 16px;
			display: block;
			@media(min-width: 768px){font-size: 14px;}
			@media(min-width: 1350px){width: 210px;}
		}
		.logo-icon{
			margin-bottom: 12px;
			@media(min-width: 1350px){
			margin-bottom: 0;
			}
		}
	}

}
.footer-bottom{
	flex-direction: column;
	background: transparent;
	position: relative;
	z-index: 2;
	border-top: 1px solid #ced9f2;
	padding-top: 1rem;
	padding-bottom: 2.2rem;
		@media(min-width: 992px){
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
		}

	.simple-link{
		display: inline-block;

		order:1;
		margin-right: 0;
		margin-bottom: 22px;
		@media(min-width: 992px){
		order:2;
		margin-bottom: 0;
		}
	}

}
.footer-bottom__copyright{
	color: #999;
	order:2;
	font-size: 16px;
	@media(min-width: 992px){
		order:1;
	}
	@media(min-width: 768px){
		font-size: 14px;
	}
}
.footer-bottom__politic{
	order:1;
	@media(min-width: 992px){
		order:2;
	}
}
.realty-cat-img{
	display: none;
	position: absolute;
	bottom: -73px;
	left: 50%;
	transform: translateX(-50%) translateX(30px);
	svg{
		width: 230px;
	}
	@media(min-width: 1200px){
		display: block;
		transform: translate(-32%,-10%);
	}
	@media(min-width: 1350px){
		transform: translateX(-50%) translateX(22px);
		}

}
