.hc-card{
	margin-bottom: 20px;
	padding: 0;
	min-height: 400px;
	//min-width: 280px;
	max-width: 320px;
	padding-left: ;
	box-shadow: 0px 10px 40px 0px rgba(255, 255, 255, 0.2);
	border-radius: 5px;
	background-color: @white;
	margin-left: auto;
	margin-right: auto;

	@media(min-width: 1200px){
		margin-bottom: 32px;
	}
	.headline-body{
		padding-bottom: 1.5px;
	}
	.hc-card-body{padding: 16px;}
	.simple-link--hc-card{
		border-bottom-style: solid;
		border-bottom-color: @lightBlue;
	}
	.hc-card-body__address{
		padding-top: 12px;
		padding-bottom: 6px;
	}
	.hc-card-body__data{color:#7f7f7f;}
	.hc-card-body__price{
		padding-top: 16px;
		font-weight: 700;
	}
	.badgesline{
		position: absolute;
		left: 0.7em;
		top: 0.7em;
	}
	.card-badge{
		position: relative;
		display: table;
		margin-bottom: 4px;
		padding-left: 10px;
		padding-right: 10px;
		height: 24px;
		padding-top: 1px;
		color:@white;
		border-radius: 5px;
		text-align: center;
		background-color:@greenBg;
		opacity: 0.85;
	}


}
