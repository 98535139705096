.swape-block{
	width: 300px;
  box-sizing: border-box;
  border: 1px solid #999;
  padding: 16px;

  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;

  &::-webkit-scrollbar {
    display: none;
  }
}