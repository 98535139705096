.section-top{
	padding-bottom: 24px;
	padding-top: 24px;
	flex-direction: column;
	@media(min-width: 768px){
		flex-direction: row;
		align-items: center;
	}
}
.section-top-title{
	padding-right: 7px;
	padding-left: 9px;
	@media(min-width: 768px){
		padding-left: 0px;
	}
}
.section-top-subtitle{
	padding-bottom: 28px;
	padding-left: 9px;
	@media(min-width: 768px){
		padding-bottom: 0;
		padding-left: 0px;
	}
}

.section-top--apartment{

	.filter-toggle{
		@media(min-width: 768px){
			align-items: center;
			margin-left: auto;
		}
	}

}
	.text-body--title{
		display: none;
		padding-right: 10px;
		@media(min-width: 1200px){display: block;}
	}


.link-filter.simple-link{
		font-size: 16px;

		padding-top: 2px;
		padding-bottom: 4px;
		text-align: center;

		@media(min-width: 768px){
			font-size: 14px;
			padding-left: 0px;
			padding-right: 0px;
			margin-left: 2px;
			margin-right: 8px;
			width: auto;
			border-bottom: 1px dashed #97bfe7;


		}

	}
	.simple-link.link-filter--active{
		padding-left: 10px;
		padding-right: 10px;
		border-bottom: 0px;
	}
	.link-filter--left.link-filter--active{
		border-radius: 3px 0px 0px 3px;
		@media(min-width: 768px){border-radius: 3px;}
	}
	.link-filter--right.link-filter--active{
		border-radius: 0px 3px 3px 0px;
		@media(min-width: 768px){border-radius: 3px;}
	}

.apartment-database__button{
	.button{
		padding-left: 27px;
		padding-right: 27px;
		height: 60px;
		font-weight: 400;
		border-radius: 5px;
		@media(min-width: 768px){
		padding-left: 75px;
		padding-right: 75px;
		height: 30px;
		border-radius: 3px;

		}

	}
}
.section-top--hc-cards{
	.section-top-title{
		padding-bottom: 16px;
		@media(min-width: 768px){
			padding-right: 26px;
			padding-bottom: 0;
		}
	}

}
#map{
	width: 100%;
	height: 420px;
	padding-bottom: 30px;
}
.section-map{

	@media(min-width: 768px){
		padding-bottom: 25px;
		padding-top: 25px;
	}
}
.section-map--title{
	padding-bottom: 20px;
}
.section-bottom{
	flex-direction: column;
	.link-group{
		margin-bottom:14px;
		margin-top: 14px;
		padding-left: 8px;
		@media(min-width: 768px){padding-left: 0;}
		@media(min-width: 1200px){
			width: 40%;
			margin-bottom: 0;
			margin-top: 0;
			margin-right: 30px;
		}
		@media(min-width: 1200px){
			width: auto;

		}
	}

	.link-block{
		padding-right: 0;
		@media(min-width: 768px){padding-right: 24px;}
	}
	@media(min-width: 1200px){
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
	}
	@media(min-width: 1200px){

		justify-content: flex-start;
	}
}

.metro-station-mobile{
	position: relative;
	display: flex;
	flex-direction: column;
	.metro-station-mobile__item{padding-bottom: 15px;}
	@media(min-width: 768px){display: none;}
	.icon-metro{
		width: 27px;
		height: 23px;
		position: absolute;
		right: -37px;
		top: -0px;
	}

}