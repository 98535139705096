/* Левая панель в документации */
.left-panel {
	background-color: #094071;
	width: 300px;
	height: 100%;
	left: 0;
	top: 0;
	bottom: 0;
	color: #fff;
	position: fixed;
	z-index: 1;
	
	.ui-nav--hidden & {
		display: none;
	}
}

#ui-nav-toggle {
	display: block;
	position: absolute;
	top: 0;
	left: 50%;
	transform: translateX(-50%);
	background-color: #094071;
	color: #fff;
	font-size: 11px;
	padding: 2px 8px 2px;
	border-radius: 0px 0px 8px 8px;
	cursor: pointer;
	opacity: 0.2;
	.transition;

	&:hover {
		opacity: 1;
	}
} 

.left-panel__section {
	padding: 15px; // margin-bottom: 20px;
	border-bottom: 1px solid rgba(138, 188, 232, 0.2);

	&:last-child {
		border-bottom: none;
	}
}

.left-panel-nav {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.left-panel-nav__link {
	.transition;
	display: inline-block;
	margin-bottom: 15px;
	color: #ceedff;
	font-size: 90%;
	text-decoration: none;
	border-bottom: 1px solid rgba(138, 188, 232, 0.5);
	padding: 5px 0;

	&:hover {
		color: #fff;
	}
}
