.container-search--block{
	@media(min-width:1350px){
		width: 1340px;
		padding-left: 0;
		padding-right: 0;
	}
}

.search-block{
	display: flex;
	flex-wrap: wrap;
	margin-top: 40px;
	padding-top: 20px;
	padding-bottom: 30px;
	padding-left: 20px;
	padding-right: 20px;
	border: 1px solid #e5e9f2;
 	border-radius: 10px;
}

.search-block-header{

	@media(min-width:768px){
		display: flex;
		flex-wrap: wrap;

	}
}

.search-row-item{

	@media(min-width:768px){
		flex-basis: auto;
		padding-right: 35px;
	}
}

.search-item-title{
	padding-bottom: 12px;

	@media(min-width:768px){
		padding-bottom: 14px;
	}
}

.search-block-header{
	@media(min-width:768px){
		.item-order-2{
			order:2;
		@media(min-width:768px){
			padding-right: 10px;
			width: 220px;
		}

		@media(min-width:992px){
			width: 250px;
			margin-left: auto;
		}
		@media(min-width:1350px){
			padding-right: 28px;
			width: 220px;
		}

	}
		.item-order-3{
			order:3;
		@media(min-width:768px){
			padding-right: 0;
			width: 220px;
		}

		@media(min-width:992px){
			width: 250px;
			margin-left: auto;
		}
		@media(min-width:1350px){
			padding-right: 28px;
			width: 220px;

		}
	}

	.item-order-4{
		order:4;

		@media(min-width:768px){
			flex-grow: 2;
		}

		@media(min-width:992px){
			flex-grow: 1;
			width: 63%;
			margin-right: auto;
			}

		@media(min-width:1350px){
			// width: 337px;
			width: 355px;
			.form-input{width: 100%;}
	}
}
		.item-order-5{
			order:5;

	}

		.item-order-6{
			order:6;
		@media(min-width:768px){
			justify-content: center;
			padding-bottom: 7px;
			padding-right: 26px;
			}
		@media(min-width:992px){
			padding-right: 0;
			// flex-grow: 2;
			}

		@media(min-width:1350px){
			justify-content: flex-start;
			padding-bottom: 0;
			padding-right: 28px;
			padding-top: 4px;

		}

	}

	}
}

.search-row-item--input{

	@media(min-width:1350px){
		flex-grow: 1;
		margin-right: 0;
	}
}

.search-row-item--rooms{
	padding-bottom: 15px;

	@media(min-width:768px){
		padding-bottom: 0;

	}
}

.search-row-item-desk{
	display: none;

	@media(min-width: 768px){
		display: block;
	}

	@media(min-width:1350px){
		width: 220px;
	}
}

.search-row-item--mobile{
	display: flex;

	@media(min-width: 768px){
		display: none;
	}

	.arrow-down{
		margin-left: 18px;
	}
}

.search-row-item--col{
	flex-direction: column;
	align-items: flex-start;

	.button{
		@media(min-width:768px){
			min-width: 155px;
		}
	}

	.link-block{
		display: none;
		padding-right: 0;
		margin-bottom: 10px;
		@media(min-width:1350px){
			display: inline-flex;
		}
	}
}

.search-row-item--pb{
	width: 100%;
	padding-bottom: 20px;

	.input-block{
		padding-bottom: 10px;
	}
}

.search-row-item-badge{
	.badge:first-child{
		margin-right: 5px;
	}

	.badge{
		height: 30px;
	}

	.badge-group{
		display: flex;

	}
}

.search-row-item-link{
	display: flex;
	// padding-top: 20px;
	align-items: center;

	.text-link{
		@media(min-width:768px){
			font-size: 13px;
		}

		@media(min-width:1350px){
			font-size: 14px;
		}
	}
}

.mobile-badge-block--first{
	margin-right: 40px;

	.badge{
		margin-bottom: 5px;
	}
}

.mobile-badge-block--four{
	width: 100%;
	padding-right: 3px;
	flex-wrap: wrap;
	padding-top: 20px;

	.badge{
		margin-bottom: 5px;
	}
}

.search-block--footer{
	padding-top: 30px;
	width: 100%;
	flex-wrap: wrap;

	.badge{
		margin-right: 5px;

	}

	.badge{
		min-width: 30px;
	}

	.badge-group{
		display: flex;
		flex-wrap: wrap;
	}
}

.mobile-badge-block--second{
	margin-top: 20px;
	margin-bottom: 20px;
	margin-right: 40px;
}

 .mobile-badge-block.mobile-badge-block--third{
 	margin-bottom: 40px;

 	.badge{
 		margin-bottom: 5px;
 	}
 }

.search-row-item--flex{
	display: flex;
	padding-top: 20px;
    align-items: center;
}

.search-item-subtitle{
	padding-top: -14px;
	font-size: 13px;
	color: #8a8b8d;
}

.line{
	width: 100%;
	border-bottom: 1px solid #ced9f2;
	padding-bottom: 30px;
}

.item-order-7{
	order: 7;
}

.dropdown-order-1.search-row-item--pb{
	// order: 1;

	@media(min-width:768px){
		padding-right: 25px;
	}

	@media(min-width:1350px){
		width: 600px;
	}

}
.dropdown-order-2{
	@media(min-width:768px){
		display: inline-block;
		order: 2;
	}
}

.dropdown-order-3.box-visible-desk{

	@media(min-width:768px){
		order: 3;
		padding-right: 26px;
		padding-bottom: 6px;
		align-items: flex-end;
		margin-left: auto;

	}
	@media(min-width:1350px){
		align-items: center;
	}
}

.dropdown-order-4{
	@media(min-width:768px){
	order:4;

	}
}

.dropdown-order-5{
	order: 5;
}

.dropdown-order-6{

    @media(min-width:768px){
    	order: 6;
    	padding-bottom: 0;
    }
}
.dropdown-order-7{
    order: 7;
    display: none;

     @media(min-width:768px){
     	display: block;
     	width: 100%;
     }
}

.search-dropdown{
	padding-top: 18px;


	@media(min-width:768px){
		display: flex;
	    flex-wrap: wrap;
		.search-row-item--doubleinput{
			display: none;
		}
	}
}

.search-dropdown-wrapper{
	display: flex;
	flex-wrap: wrap;
}

.mobile-badge-block--second,
.mobile-badge-block--third{
	@media(min-width:768px){
		margin-top: 0;
		margin-bottom: 0;

	}
}

.mobile-badge-block--four{
	@media(min-width:768px){
		margin-bottom: 20px;

	}
}

////////////EDIT///////////
.search-edit-header{
	display: flex;
    flex-wrap: wrap;
	align-items: center;
	padding-top: 28px;
	padding-bottom: 21px;

	@media(min-width:768px){
	    flex-wrap: nowrap;
		align-items: flex-start;
	}

	.search-edit-title{
	    flex-shrink: 0;
	}

	.text-link{
		display: inline-table;
	}
}

.search-edit-items{
	margin-left: 10px;
	margin-right: auto;

	.text-link:not(:last-child){
		margin-right: 30px;

	}
}
////////////EDIT///////////

.search-row-item-link{
	display: flex;
	padding-bottom: 40px;
	// padding-top: 20px;
	align-items: center;

	.text-link{

		color: @grey;
		border-bottom-color: @grey;
		@media(min-width:768px){
			font-size: 13px;
		}

		@media(min-width:1350px){
			font-size: 14px;
		}
	}
}

.search-edit-basket{
	.basket-text{
		padding-right: 10px;
	}
}
.search-edit-body__item{
	background-color: @white;
	border-radius: 5px;
	border: 1px solid @lightBorder;
	padding-top: 9px;
	padding-left: 9px;
	padding-right: 9px;
    justify-content: space-between;
	margin-bottom: 10px;

	.input-block{
		padding-bottom: 0;
	}

	.badge{
		margin-top: 10px;
		margin-bottom: 10px;
	}
}

.search-edit-body__item:last-child{
	margin-bottom: 0;
}

.search-edit-badges{
	width: 85%;
	max-width: 880px;
}

.search-link--desk{
	display: none !important;

	.link-block__icon{
		order:2;
		margin-left: 10px;
	}
	@media(min-width: 768px){
		display: inline-flex !important;
	}
}

.basket-text{
	font-size: 13px;
	display: none;
	color: #C3C3C3;

	@media(min-width: 1350px){
		font-size: 14px;
		}
}

.search-edit-basket:hover .basket-text{display: block;}
.search-edit-basket:hover .icon-basket{use {fill:red;}}
.search-edit-basket{
	transition: 0.5s;
}

.search-link--mob{
	display: inline-flex !important;

	.link-block__icon{
		order:2;
		margin-left: 10px;
		transform: rotate(180deg);
	}

	@media(min-width: 768px){
		display: none !important;
	}
}

//****стили для скрипта****//
.search-dropdown{
	display: none;

}
.show-full-search{
	display: none;
	width: 129px;
	.arrow-down{margin-left: 9px;}
	@media(min-width: 768px){display: flex;}
}
.search-block{
	padding-bottom: 40px;
	@media(min-width: 768px){
		padding-bottom: 0;
	}
}

.show-flex{display: flex;}

.areas-badges{
	.badge-group{display: none;}
	}
.areas-badges{
	.badge-group.visible{display:flex;}
}




.close-full-mobile{
	display: none;
	@media(min-width: 768px){display: none !important;}
}
.close-full-mobile{
	border-top: 1px solid @lightBorder;
	.arrow-down{margin-left: 9px;}
}
.line.dropdown-order-6{
	display: none;
	@media(min-width: 768px){display: block;
	}
}
.search-edit-body{
	display: none;

}
.show-block{
	display: block;

}
.search-edit-body__item,
.search-edit-basket{display: flex;}
.currency-select{
	border-width: 0px;
	background-color: transparent;
	color: rgb(44,129,209);
	border-bottom: 1px dashed rgba(44,129,209, .2);
	outline: none;
	padding-left: 2px;
	padding-right: 2px;
	font-weight: bold;

	-webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.currency-select:active,
 .currency-select:focus{
	border-color:transparent;
	border-bottom: 1px dashed rgba(44,129,209, .2);
  	outline:none
}
