.search-save-mob{
    display: block;
    // background-color: #E5E9F2;
	margin-top: 20px;
	margin-bottom: 20px;
	padding-top: 20px;
	padding-bottom: 26px;
	padding-right: 12px;
	padding-left: 14px;
	border: 1px solid #e5e9f2;
 	border-radius: 10px;
 	
 	.search-save-title{
 		display: flex;
 		justify-content: space-between;
 		font-size: 16px;
 		padding-bottom: 26px;
 	}
 	
 	.arrow-down{
 		margin-left: 70px;		
 	}

 	@media(min-width: 768px){
 		display: none;
 	}
}

.search-edit-items--mobile{
	display: none;
	
	.search-edit-items--links{
		display: block;

	.text-link{
		display: inline-flex;
		line-height: 1.5;
		margin-bottom: 15px;
	}
}

}

.search-edit-items--mobile.visible{
	display: block;
}

.search-edit--mobile{
    flex-wrap: wrap;
	padding-top: 10px;
}

.icon-rotate{
	transform: rotate(180deg);
}

.search-row-item-link.box-visible-mob{
	flex-direction: column;
	align-items: flex-start;
	
	    .icon-heart--small{
	    	margin-bottom: 15px;
	    }
}