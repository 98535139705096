/*Типографика*/

.title-1 {
  font-size: 28px;
  line-height: 34px;
  font-weight: 400;
  color: @black;
  //color: @title-1;

  @media (min-width: 1350px) {
    font-size: 34px;
    line-height: 38px;
  }
}

.title-2 {
  font-size: 24px;
  line-height: 30px;
  font-weight: 700;
  color: @black;

  @media (min-width: 1350px) {
    font-size: 28px;
    line-height: 18px;
    font-weight: 400;
    color: @title-1;
  }
}

.headline-bold {
  font-size: 24px;
  line-height: 30px;
  font-weight: 700;
  color: @black;

  @media (min-width: 768px) {
    font-size: 18px;
    line-height: 18px;

  }
  @media (min-width: 1350px) {
    font-size: 20px;
    line-height: 25px;

  }
}

.headline-body {
  font-size: 16px;
  line-height: 22px;
  font-weight: 700;
  color: @black;

  @media (min-width: 768px) {
    font-size: 14px;
    line-height: 20px;
  }
}

.text-body, .text-body h3 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: @black;

  @media (min-width: 768px) {
    font-size: 14px;
    line-height: 18px;
  }
}

.text-body h2 {
  font-size: 20px;
  line-height: 18px;
  font-weight: 700;
  color: #000;
  padding-bottom: 1rem !important;
}

.text-body h3 {
  font-weight: 700;
}

.headline-bold--grey {
  color: #999;
}

.text-body--grey {
  color: #838385;
}

.help-block {
  color: @cherry;
}

.label {
  color: @black;
  font-size: 16px;
  font-weight: 700;
  margin-left: 0.1em;
}


//Extra css
.section-info{
  background-color: @bgChatPage;
  padding: 12px;
  border-radius: 5px;
}
.section-danger{
  background-color: @dangerBg;
  border: 1px solid @dangerBrd;
  padding: 12px;
  border-radius: 5px;
}

.simple-input.disabled {
  background-color: #e9ecef;
}

.input_desc {
  margin-top: -1.5rem;
  margin-left: 0.2rem;
  color: #a7a7a7;
}

.avatar:hover {
  border: 3px solid #2c81d1;
}

.loadavatar {
  overflow: hidden;
  cursor: pointer;
  touch-action: manipulation;
  padding-bottom: 10px
}

.w-75 {
  width: 75%;
}

.text-capitalize {
  text-transform: capitalize;
}

.cliploader{
  float: right;
  position: relative;
  top: -15px;
  @media (min-width: 768px) {
    top: -5px;

  }
}

textarea{
  border: 1px solid #e5e9f2;
  color: #000;
  border-radius: 5px;
  background-color: #fff;
  padding: 12px;
  margin-bottom: 25px;
  width: 100%;
  min-height: 200px;
}

input[name='rooms'] {
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  -ms-appearance: none;
  appearance: none;
  outline: none;

  &:after {
    content: attr(data-room);

    display: -webkit-box;
    display: -ms-flexbox;
    display: inline-flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 3px;
    border: 1px solid #2d82d1;
    color: #2d82d1;
    margin-right: 4px;
    width: 50px;
    height: 50px;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
    background: transparent;
    cursor: pointer;
  }

  &:hover, &:checked {
    &:after {
      content: attr(data-room);
      display: -webkit-box;
      display: -ms-flexbox;
      display: inline-flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      border-radius: 3px;
      border: 1px solid #2d82d1;
      margin-right: 4px;
      width: 50px;
      height: 50px;
      -webkit-transition: 0.5s;
      -o-transition: 0.5s;
      transition: 0.5s;
      cursor: pointer;
      color: #fff;
      background-color: #2d82d1;
    }
  }
}

input[name='btn_checkbox'] {
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  -ms-appearance: none;
  appearance: none;
  outline: none;
  width: 100%;

  &:after {
    content: attr(data-checkbox);
    display: -webkit-box;
    display: -ms-flexbox;
    display: inline-flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 3px;
    border: 1px solid #2d82d1;
    color: #2d82d1;
    width: 100%;
    height: 50px;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
    background: transparent;
    cursor: pointer;
  }

  &:hover, &:checked {
    &:after {
      content: attr(data-checkbox);
      display: -webkit-box;
      display: -ms-flexbox;
      display: inline-flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      border-radius: 3px;
      border: 1px solid #2d82d1;
      width: 100%;
      height: 50px;
      -webkit-transition: 0.5s;
      -o-transition: 0.5s;
      transition: 0.5s;
      cursor: pointer;
      color: #fff;
      background-color: #2d82d1;
    }
  }
}

input[class='btn_checkbox_mini'] {
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  -ms-appearance: none;
  appearance: none;
  outline: none;

  &:after {
    content: attr(data-checkbox);
    display: -webkit-box;
    display: -ms-flexbox;
    display: inline-flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 3px;
    border: 1px solid #2d82d1;
    color: #2d82d1;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
    background: transparent;
    cursor: pointer;
    padding: 0 5px;
    margin-right: 5px;
    margin-bottom: 5px;
  }

  &:checked {
    &:after {
      content: attr(data-checkbox);
      display: -webkit-box;
      display: -ms-flexbox;
      display: inline-flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      border-radius: 3px;
      border: 1px solid #2d82d1;
      -webkit-transition: 0.5s;
      -o-transition: 0.5s;
      transition: 0.5s;
      cursor: pointer;
      color: #fff;
      background-color: #2d82d1;
    }
  }
}

input[class='btn_checkbox_mini']:hover {
  opacity: 0.8;
}


input[class='switch'] {
  width: 100%;
  padding: 1px;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  -ms-appearance: none;
  appearance: none;
  outline: none;


  &:after {
    content: attr(data-radio);

    display: -webkit-box;
    display: -ms-flexbox;
    display: inline-flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: #2d82d1;
    width: 100%;
    height: 44px;
    -webkit-transition: 0.3s ease all;
    -o-transition: 0.3s ease all;
    transition: 0.3s ease all;
    background: transparent;
    cursor: pointer;
  }

 &:checked {
    &:after {
      content: attr(data-radio);
      display: -webkit-box;
      display: -ms-flexbox;
      display: inline-flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      border-radius: 3px;
      border: 1px solid #334860;
      width: 100%;
      height: 44px;
      -webkit-transition: 0.3s ease all;
      -o-transition: 0.3s;
      transition: 0.3s  ease all;
      cursor: pointer;
      color: #fff;
      background-color: #334860;
    }
  }
}

.switch-area{
    background: #fff;
    padding: 3px;
    border: 1px solid #e5e9f2;
    border-radius: 5px;
    margin-bottom: 22px;
  }

  //Filepond
  .filepond{
    border-radius: 5px;
  }
  .filepond--panel-root {
    background-color: #f5f6fa !important;
  }

  .filepond--item {
    width: calc(50% - .5em);
    @media (min-width: 768px) {
      width: calc(25% - .5em);
    }
  }

  .filepond--root .filepond--drop-label {
    font-family: 'Montserrat', sans-serif;
    color: #2c81d1;
    min-height: 200px !important;
  }
  .filepond--drop-label.filepond--drop-label label{
    font-weight: bold;
    display: block;
  }

  .filepond--file-action-button {
    cursor: pointer;
  }
  .filepond--label-action
  {
    text-decoration-color: #2c81d1;
  }
  .browse_desc{
    color: #a7a7a7;
    display: block;
    margin-top: 0.5rem;
    font-weight: normal;
  }
  .filepond--image-preview{
    background: #334860;
  }

  .input_middle{
    text-align: center;
    line-height: 3.1;
  }
  .greenbrd{
    border: 1px solid #41ae1b !important;
  }
  .filepond--drip{
    border: 1px dashed #2c81d1;
    opacity: 1!important;
  }