/*!
 * Bootstrap Reboot v4.1.3 (https://getbootstrap.com/)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 * Forked from Normalize.css, licensed MIT (https://github.com/necolas/normalize.css/blob/master/LICENSE.md)
 */
*,
::after,
::before {
  box-sizing: border-box;
}
html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent;
}
@-ms-viewport {
  width: device-width;
}
article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}
[tabindex="-1"]:focus {
  outline: 0 !important;
}
hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}
p {
  margin-top: 0;
  margin-bottom: 1rem;
}
abbr[data-original-title],
abbr[title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
}
address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}
dl,
ol,
ul {
  margin-top: 0;
  margin-bottom: 1rem;
}
ol ol,
ol ul,
ul ol,
ul ul {
  margin-bottom: 0;
}
dt {
  font-weight: 700;
}
dd {
  margin-bottom: .5rem;
  margin-left: 0;
}
blockquote {
  margin: 0 0 1rem;
}
dfn {
  font-style: italic;
}
b,
strong {
  font-weight: bolder;
}
small {
  font-size: 80%;
}
sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}
a:hover {
  color: #0056b3;
  text-decoration: underline;
}
a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([tabindex]):focus,
a:not([href]):not([tabindex]):hover {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([tabindex]):focus {
  outline: 0;
}
code,
kbd,
pre,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}
pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}
figure {
  margin: 0 0 1rem;
}
img {
  vertical-align: middle;
  border-style: none;
}
svg {
  overflow: hidden;
  vertical-align: middle;
}
table {
  border-collapse: collapse;
}
caption {
  padding-top: .75rem;
  padding-bottom: .75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}
th {
  text-align: inherit;
}
label {
  display: inline-block;
  margin-bottom: 0.5rem;
}
button {
  border-radius: 0;
}
button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}
button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
button,
input {
  overflow: visible;
}
button,
select {
  text-transform: none;
}
[type=reset],
[type=submit],
button,
html [type=button] {
  -webkit-appearance: button;
}
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner,
button::-moz-focus-inner {
  padding: 0;
  border-style: none;
}
input[type=checkbox],
input[type=radio] {
  box-sizing: border-box;
  padding: 0;
}
input[type=date],
input[type=datetime-local],
input[type=month],
input[type=time] {
  -webkit-appearance: listbox;
}
textarea {
  overflow: auto;
  resize: vertical;
}
fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}
legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}
progress {
  vertical-align: baseline;
}
[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}
[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}
[type=search]::-webkit-search-cancel-button,
[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}
output {
  display: inline-block;
}
summary {
  display: list-item;
  cursor: pointer;
}
template {
  display: none;
}
[hidden] {
  display: none !important;
}
/*# sourceMappingURL=bootstrap-reboot.min.css.map */
/* base color */
@media (min-width: 1350px) {
  .container {
    max-width: 1320px;
  }
}
@media (min-width: 1350px) {
  .col-hl {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-hl-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-hl-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-hl-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-hl-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-hl-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-hl-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-hl-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-hl-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-hl-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-hl-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-hl-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-hl-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-hl-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-hl-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }
  .order-hl-last {
    -webkit-box-ordinal-group: 14;
    -ms-flex-order: 13;
    order: 13;
  }
  .order-hl-0 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }
  .order-hl-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
  .order-hl-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }
  .order-hl-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
  }
  .order-hl-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
  }
  .order-hl-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5;
  }
  .order-hl-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6;
  }
  .order-hl-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7;
  }
  .order-hl-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8;
  }
  .order-hl-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9;
  }
  .order-hl-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10;
  }
  .order-hl-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11;
  }
  .order-hl-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-hl-0 {
    margin-left: 0;
  }
  .offset-hl-1 {
    margin-left: 8.333333%;
  }
  .offset-hl-2 {
    margin-left: 16.666667%;
  }
  .offset-hl-3 {
    margin-left: 25%;
  }
  .offset-hl-4 {
    margin-left: 33.333333%;
  }
  .offset-hl-5 {
    margin-left: 41.666667%;
  }
  .offset-hl-6 {
    margin-left: 50%;
  }
  .offset-hl-7 {
    margin-left: 58.333333%;
  }
  .offset-hl-8 {
    margin-left: 66.666667%;
  }
  .offset-hl-9 {
    margin-left: 75%;
  }
  .offset-hl-10 {
    margin-left: 83.333333%;
  }
  .offset-hl-11 {
    margin-left: 91.666667%;
  }
}
.col-hl-1,
.col-hl-2,
.col-hl-3,
.col-hl-4,
.col-hl-5,
.col-hl-6,
.col-hl-7,
.col-hl-8,
.col-hl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl,
.col-hl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 12px;
  padding-left: 12px;
}
.transition {
  -webkit-transition: all 0.2s ease-in;
  -moz-transition: all 0.2s ease-in;
  -ms-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}
.transition-shadow {
  -webkit-transition: box-shadow 0.2s ease-in;
  -moz-transition: box-shadow 0.2s ease-in;
  -ms-transition: box-shadow 0.2s ease-in;
  -o-transition: box-shadow 0.2s ease-in;
  transition: box-shadow 0.2s ease-in;
}
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
html,
body {
  width: 100%;
  height: 100%;
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  padding: 0;
  margin: 0;
  min-height: 100%;
  position: relative;
  min-width: 360px;
  background-color: #f5f6fa;
}
.wrapper-page {
  display: table;
  height: 100%;
  width: 100%;
}
.main-content {
  display: table-row;
  height: 100%;
}
/* fix for top margin collapsing and vertical scroll prevent for body tag, when body height = 100% */
body::before {
  content: "";
  display: block;
  width: 1px;
  height: 0px;
  background-color: transparent;
}
input,
textarea {
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  line-height: 1.5;
  resize: vertical;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-weight: 400;
  line-height: 1.3;
}
img {
  display: block;
}
a {
  color: #2c81d1;
}
a:hover {
  text-decoration: none;
  color: #d15656;
}
code,
pre {
  background-color: #f5f5f5;
  border-radius: 6px;
  -moz-osx-font-smoothing: auto;
  -webkit-font-smoothing: auto;
  font-family: monospace;
  font-family: Consolas, Monaco, Lucida Console, monospace;
  font-size: 1rem;
}
pre {
  color: #666;
  line-height: 1.5;
  margin: 15px 0;
  overflow: auto;
  padding: 10px;
}
code {
  color: #666;
  font-weight: 400;
  padding: .3em .8em;
}
pre code {
  padding: 0;
}
blockquote {
  background: #2d82d1;
  border-radius: 5px;
  padding: 30px 80px;
  position: relative;
  width: 100%;
  margin: 2em auto;
  overflow: hidden;
  font-size: 18px;
  line-height: 1.5;
}
blockquote:before {
  content: '“';
  font-weight: 900;
  color: #c4c4c4;
  opacity: 0.2;
  font-size: 4em;
  line-height: 1em;
  position: absolute;
  top: 30px;
  left: 30px;
  z-index: 0;
}
blockquote p {
  margin-bottom: 0;
  font-size: 18px;
  line-height: 1.5;
  color: #2a2a2a;
  font-weight: 700;
  position: relative;
  z-index: 1;
}
label {
  margin: 0;
  padding: 0;
}
.pt-16 {
  padding-top: 16px;
}
.clear {
  clear: both;
  display: table;
}
.body--dashboard {
  padding-left: 330px;
  padding-right: 15px;
}
.body--dashboard.ui-nav--hidden {
  padding-left: 0;
  padding-right: 0;
}
.body-bg {
  background-color: #f3f3f3;
}
.container--left {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.section-ui {
  padding: 15px 0px;
}
.ui-title {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
}
.section-ui pre {
  margin-top: 0;
}
.hr-line {
  border-top: 1px solid #C4C4C4;
}
.col--no-padding {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.photo-slider {
  display: flex;
  height: 250px;
  overflow-y: hidden;
  white-space: nowrap;
}
.photo-slider::-webkit-scrollbar {
  display: none;
}
.photo-slider .photo-slider-item {
  border-radius: 8px;
  width: 300px;
  margin-right: 20px;
}
@media (min-width: 480px) {
  .photo-slider .photo-slider-item {
    width: 360px;
  }
}
.photo-slider .photo-slider-item img {
  border-radius: 10px;
  height: 100%;
}
.card-slider .slick-slide {
  margin-right: 10px;
  width: 248px;
}
.appartment-card--mobile .photo-slider {
  height: auto;
}
.card--photo-slider .photo-slider-item {
  min-height: 150px;
  width: 250px;
}
@media (min-width: 480px) {
  .card--photo-slider .photo-slider-item {
    width: 300px;
  }
}
.fadeOut:after {
  display: none;
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  margin: 0;
  height: 3.0em;
  background-color: rgba(255, 255, 255, 0);
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0, #f5f6fa 100%);
}
@media (min-width: 768px) {
  .fadeOut:after {
    display: block;
  }
}
.box-visible-desk {
  display: none !important;
}
@media (min-width: 768px) {
  .box-visible-desk {
    display: inline-flex !important;
  }
}
.box-visible-mob {
  display: inline-flex !important;
}
@media (min-width: 768px) {
  .box-visible-mob {
    display: none !important;
  }
}
.card {
  border: none;
  border-radius: 6px;
  background-color: #fff;
  box-shadow: 0px 10px 40px 0px rgba(99, 117, 138, 0.3);
  padding: 20px;
  display: flex;
  flex-direction: column;
}
.card > .title-1,
.card > .title-2,
.card > .title-3,
.card > .title-4,
.card > .title-5,
.card > .title-6 {
  margin-top: 0;
}
.card__img {
  width: 100%;
  margin-bottom: 15px;
  overflow: hidden;
}
.card__title {
  font-size: 22px;
  line-height: 28px;
  font-weight: 600;
}
.card--post {
  width: 100%;
  height: 308px;
}
.card--portfolio {
  padding: 0;
  padding-bottom: 20px;
  width: 100%;
  height: 315px;
}
.card__desc {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex: 1;
}
.card-wrapper-fixed-size {
  width: 360px;
}
.card {
  padding-top: 60px;
}
.card .title-2 {
  margin-bottom: 20px;
}
.card a.button {
  font-size: 16px;
}
.title-1.mb-55 {
  margin-top: 55px;
}
.color-block {
  display: flex;
  margin-bottom: 40px;
  align-items: center;
}
.color {
  width: 30px;
  height: 30px;
  margin-right: 40px;
  border: 2px solid #f3f3f3;
}
.color-name {
  font-size: 18px;
}
.color-num {
  margin-left: 50px;
  font-size: 18px;
}
/*Типографика*/
.title-1 {
  font-size: 28px;
  line-height: 34px;
  font-weight: 400;
  color: #000;
}
@media (min-width: 1350px) {
  .title-1 {
    font-size: 34px;
    line-height: 38px;
  }
}
.title-2 {
  font-size: 24px;
  line-height: 30px;
  font-weight: 700;
  color: #000;
}
@media (min-width: 1350px) {
  .title-2 {
    font-size: 28px;
    line-height: 18px;
    font-weight: 400;
    color: #334860;
  }
}
.headline-bold {
  font-size: 24px;
  line-height: 30px;
  font-weight: 700;
  color: #000;
}
@media (min-width: 768px) {
  .headline-bold {
    font-size: 18px;
    line-height: 18px;
  }
}
@media (min-width: 1350px) {
  .headline-bold {
    font-size: 20px;
    line-height: 25px;
  }
}
.headline-body {
  font-size: 16px;
  line-height: 22px;
  font-weight: 700;
  color: #000;
}
@media (min-width: 768px) {
  .headline-body {
    font-size: 14px;
    line-height: 20px;
  }
}
.text-body,
.text-body h3 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: #000;
}
@media (min-width: 768px) {
  .text-body,
  .text-body h3 {
    font-size: 14px;
    line-height: 18px;
  }
}
.text-body h2 {
  font-size: 20px;
  line-height: 18px;
  font-weight: 700;
  color: #000;
  padding-bottom: 1rem !important;
}
.text-body h3 {
  font-weight: 700;
}
.headline-bold--grey {
  color: #999;
}
.text-body--grey {
  color: #838385;
}
.help-block {
  color: #d15656;
}
.label {
  color: #000;
  font-size: 16px;
  font-weight: 700;
  margin-left: 0.1em;
}
.section-info {
  background-color: #dbe6ff;
  padding: 12px;
  border-radius: 5px;
}
.section-danger {
  background-color: #ffd9d9;
  border: 1px solid #ffbfbf;
  padding: 12px;
  border-radius: 5px;
}
.simple-input.disabled {
  background-color: #e9ecef;
}
.input_desc {
  margin-top: -1.5rem;
  margin-left: 0.2rem;
  color: #a7a7a7;
}
.avatar:hover {
  border: 3px solid #2c81d1;
}
.loadavatar {
  overflow: hidden;
  cursor: pointer;
  touch-action: manipulation;
  padding-bottom: 10px;
}
.w-75 {
  width: 75%;
}
.text-capitalize {
  text-transform: capitalize;
}
.cliploader {
  float: right;
  position: relative;
  top: -15px;
}
@media (min-width: 768px) {
  .cliploader {
    top: -5px;
  }
}
textarea {
  border: 1px solid #e5e9f2;
  color: #000;
  border-radius: 5px;
  background-color: #fff;
  padding: 12px;
  margin-bottom: 25px;
  width: 100%;
  min-height: 200px;
}
input[name='rooms'] {
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  -ms-appearance: none;
  appearance: none;
  outline: none;
}
input[name='rooms']:after {
  content: attr(data-room);
  display: -webkit-box;
  display: -ms-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 3px;
  border: 1px solid #2d82d1;
  color: #2d82d1;
  margin-right: 4px;
  width: 50px;
  height: 50px;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  background: transparent;
  cursor: pointer;
}
input[name='rooms']:hover:after,
input[name='rooms']:checked:after {
  content: attr(data-room);
  display: -webkit-box;
  display: -ms-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 3px;
  border: 1px solid #2d82d1;
  margin-right: 4px;
  width: 50px;
  height: 50px;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  cursor: pointer;
  color: #fff;
  background-color: #2d82d1;
}
input[name='btn_checkbox'] {
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  -ms-appearance: none;
  appearance: none;
  outline: none;
  width: 100%;
}
input[name='btn_checkbox']:after {
  content: attr(data-checkbox);
  display: -webkit-box;
  display: -ms-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 3px;
  border: 1px solid #2d82d1;
  color: #2d82d1;
  width: 100%;
  height: 50px;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  background: transparent;
  cursor: pointer;
}
input[name='btn_checkbox']:hover:after,
input[name='btn_checkbox']:checked:after {
  content: attr(data-checkbox);
  display: -webkit-box;
  display: -ms-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 3px;
  border: 1px solid #2d82d1;
  width: 100%;
  height: 50px;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  cursor: pointer;
  color: #fff;
  background-color: #2d82d1;
}
input[class='btn_checkbox_mini'] {
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  -ms-appearance: none;
  appearance: none;
  outline: none;
}
input[class='btn_checkbox_mini']:after {
  content: attr(data-checkbox);
  display: -webkit-box;
  display: -ms-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 3px;
  border: 1px solid #2d82d1;
  color: #2d82d1;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  background: transparent;
  cursor: pointer;
  padding: 0 5px;
  margin-right: 5px;
  margin-bottom: 5px;
}
input[class='btn_checkbox_mini']:checked:after {
  content: attr(data-checkbox);
  display: -webkit-box;
  display: -ms-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 3px;
  border: 1px solid #2d82d1;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  cursor: pointer;
  color: #fff;
  background-color: #2d82d1;
}
input[class='btn_checkbox_mini']:hover {
  opacity: 0.8;
}
input[class='switch'] {
  width: 100%;
  padding: 1px;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  -ms-appearance: none;
  appearance: none;
  outline: none;
}
input[class='switch']:after {
  content: attr(data-radio);
  display: -webkit-box;
  display: -ms-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #2d82d1;
  width: 100%;
  height: 44px;
  -webkit-transition: 0.3s ease all;
  -o-transition: 0.3s ease all;
  transition: 0.3s ease all;
  background: transparent;
  cursor: pointer;
}
input[class='switch']:checked:after {
  content: attr(data-radio);
  display: -webkit-box;
  display: -ms-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 3px;
  border: 1px solid #334860;
  width: 100%;
  height: 44px;
  -webkit-transition: 0.3s ease all;
  -o-transition: 0.3s;
  transition: 0.3s  ease all;
  cursor: pointer;
  color: #fff;
  background-color: #334860;
}
.switch-area {
  background: #fff;
  padding: 3px;
  border: 1px solid #e5e9f2;
  border-radius: 5px;
  margin-bottom: 22px;
}
.filepond {
  border-radius: 5px;
}
.filepond--panel-root {
  background-color: #f5f6fa !important;
}
.filepond--item {
  width: calc(49.5%);
}
@media (min-width: 768px) {
  .filepond--item {
    width: calc(24.5%);
  }
}
.filepond--root .filepond--drop-label {
  font-family: 'Montserrat', sans-serif;
  color: #2c81d1;
  min-height: 200px !important;
}
.filepond--drop-label.filepond--drop-label label {
  font-weight: bold;
  display: block;
}
.filepond--file-action-button {
  cursor: pointer;
}
.filepond--label-action {
  text-decoration-color: #2c81d1;
}
.browse_desc {
  color: #a7a7a7;
  display: block;
  margin-top: 0.5rem;
  font-weight: normal;
}
.filepond--image-preview {
  background: #334860;
}
.input_middle {
  text-align: center;
  line-height: 3.1;
}
.greenbrd {
  border: 1px solid #41ae1b !important;
}
.filepond--drip {
  border: 1px dashed #2c81d1;
  opacity: 1!important;
}
i {
  display: inline-block;
  cursor: pointer;
  transition: .5;
}
.arrow-down--path {
  fill: #2c81d1;
  fill-rule: evenodd;
}
.icon-add--path {
  fill: #b3b3b3;
  fill-rule: evenodd;
}
.icon-brach--path {
  fill: #41ae1b;
  fill-rule: evenodd;
}
.icon-man--path {
  fill: #b0b0b0;
  fill-rule: evenodd;
}
.icon-notify--path {
  fill: #b3b3b3;
  fill-rule: evenodd;
}
.watch-announcements .simple-link {
  min-width: 273px;
}
.watch-announcements--active .icon-notify svg path {
  fill: #d15656;
  fill-rule: evenodd;
}
.icon-price-down--path {
  fill: #d15656;
  fill-rule: evenodd;
}
.icon-question--path {
  fill: #b3b3b3;
  fill-rule: evenodd;
}
.icon-mail--path {
  fill: #b3b3b3;
  fill-rule: evenodd;
}
.ic-dashboard {
  fill: #b3b3b3;
  fill-rule: evenodd;
}
.notify {
  position: relative;
  display: inline-block;
}
.notify .notify-marker {
  position: absolute;
  top: -5px;
  right: -7px;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  background-color: #d15656;
}
.svg-inline--fa {
  font-size: 24px;
  cursor: pointer;
}
.icon-heart.icon-heart--red svg {
  width: 18px ;
  height: 16px ;
}
.icon-heart.icon-heart--red path {
  fill: #d15656;
  width: 18px ;
  height: 16px ;
}
.icon-heart--grey {
  width: 24px;
}
.icon-heart--grey path {
  fill: #b3b3b3;
}
.icon-search svg {
  width: 23px;
  height: 23px;
}
.icon-search path {
  fill: #b3b3b3;
}
.arrow-down svg {
  width: 19px;
  height: 13px;
}
.arrow-down--rotate {
  transform: rotate(180deg);
}
.icon-notify svg {
  width: 20px;
  height: 23px;
}
.option-panel svg {
  width: 18px;
  height: 21px;
}
.icon-dashboard svg {
  width: 23px;
  height: 23px;
}
.icon-metro svg {
  width: 25px;
  height: 18px;
}
@media (min-width: 768px) {
  .icon-metro svg {
    width: 18px;
    height: 13px;
  }
}
.repaire-item-box {
  display: flex;
}
.repaire-item-box .icon-brach {
  margin-right: 10px;
}
.icon-pdf svg {
  width: 30px;
}
.icon-pdf svg polygon,
.icon-pdf svg path {
  fill: #999;
}
.icon-copy {
  width: 20px;
}
.icon-copy path {
  fill: #2c81d1;
}
.icon-copy:hover {
  opacity: .7;
}
.icon-group {
  display: inline-flex;
  cursor: pointer;
  align-items: flex-end;
  font-size: 14px;
  color: #b3b3b3;
  font-weight: 700;
}
.icon-group .icon-group__icon {
  margin-right: 6px;
}
.icon-group.group--icon-like {
  position: relative;
  bottom: 4px;
}
.icon-group.group--icon-like .icon-group__num--color {
  color: #41ae1b;
}
.icon-group.group--icon-like:hover .icon-like use {
  fill: #41ae1b;
}
.icon-group.group--icon-like .icon-group use {
  fill: #b3b3b3;
}
.icon-group.group--icon-like .clicked use {
  fill: #41ae1b;
}
.icon-group.group--icon-dislike .icon-group__num--color {
  color: #d15656;
}
.icon-group.group--icon-dislike:hover .icon-dislike use {
  fill: #d15656;
}
.icon-group.group--icon-dislike .icon-group__num {
  padding-bottom: 4px;
}
.icon-group.group--icon-dislike .icon-group use {
  fill: #b3b3b3;
}
.icon-group.group--icon-dislike .clicked use {
  fill: #d15656;
}
.filter-toggle {
  align-items: center;
  display: flex;
}
.filter-toggle__text {
  font-size: 16px;
  color: #000;
  padding-right: 10px;
}
@media (min-width: 768px) {
  .filter-toggle__text {
    font-size: 14px;
  }
}
.filter-toggle-item {
  cursor: pointer;
  border-bottom: 1px dashed rgba(44, 129, 209, 0.2);
  color: #2c81d1;
  transition: .5s;
  margin-right: 10px;
  font-size: 16px;
}
.filter-active {
  height: 24px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 3px;
  border-bottom: transparent;
  background-color: #334860;
  color: #fff;
}
@media (min-width: 768px) {
  .filter-active {
    padding-top: 1px;
  }
}
.filter-active a {
  color: #fff;
}
.filter-toggle--border {
  border-radius: 3px;
  width: 100%;
  height: 24px;
  text-align: center;
  border: 1px solid #334860;
}
@media (min-width: 768px) {
  .filter-toggle--border {
    width: auto;
  }
}
.filter-toggle--border .filter-toggle__text {
  display: none;
}
@media (min-width: 768px) {
  .filter-toggle--border .filter-toggle__text {
    display: block;
  }
}
.filter-toggle--border .filter-toggle-item,
.filter-toggle--border .filter-active {
  margin-right: 0;
}
.filter-toggle-item--left {
  border-radius: 3px 0px 0px 3px;
  width: 50%;
}
.filter-toggle-item--right {
  border-radius: 0px 3px 3px 0px;
  width: 50%;
}
@media (min-width: 768px) {
  .filter-toggle--border {
    border: 0px;
    border-radius: 0px;
  }
  .filter-toggle-item--left {
    border-radius: 3px;
    width: auto;
  }
  .filter-toggle-item--right {
    border-radius: 3px;
    width: auto;
    margin-left: 10px;
  }
  .filter-toggle-item {
    font-size: 14px;
  }
}
.visible-desktop {
  display: none;
}
@media (min-width: 768px) {
  .visible-desktop {
    display: flex;
  }
}
@media (min-width: 768px) {
  .visible-mobile {
    display: none;
  }
}
.metro-station {
  display: inline-flex;
  flex-direction: column;
  width: fit-content;
}
@media (min-width: 768px) {
  .metro-station {
    flex-direction: row;
    align-items: center;
  }
}
.metro-station .icon-metro {
  padding-bottom: 3px;
  margin-right: 7px;
}
.metro-station .first-text {
  display: inline-block;
  position: relative;
}
.metro-station .middle-text {
  padding-right: 5px;
  color: #838385;
}
.metro-station .last-text {
  padding-right: 5px;
  color: #838385;
}
.last-icon {
  display: block;
  margin-top: 4px;
}
@media (min-width: 768px) {
  .last-icon {
    display: none;
  }
}
@media (min-width: 768px) {
  .last-icon {
    align-items: flex-end;
  }
}
@media (min-width: 1350px) {
  .last-icon {
    margin-top: 0px;
  }
}
.text-info--metro {
  display: flex;
  flex-direction: column;
  position: relative;
}
@media (min-width: 768px) {
  .text-info--metro {
    flex-direction: row;
  }
}
.text-info--metro .icon-metro {
  position: absolute;
  top: -3px;
  right: -35px;
}
@media (min-width: 768px) {
  .text-info--metro .icon-metro {
    right: auto;
    left: -25px;
    top: -2px;
  }
}
.text-info--metro {
  flex-wrap: wrap;
  padding-left: 8px;
}
@media (min-width: 768px) {
  .text-info--metro {
    padding-left: 25px;
  }
}
.text-info--metro .metro-station:last-child .last-text--comma {
  display: none;
}
.metro-station--flex {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 40px;
}
@media (min-width: 768px) {
  .metro-station--flex {
    padding-left: 28px;
  }
}
.metro-station--flex .icon-metro {
  position: absolute;
  left: -35px;
  top: -2px;
}
@media (min-width: 768px) {
  .metro-station--flex .icon-metro {
    left: -25px;
  }
}
.metro-station--flex .last-text {
  display: none;
}
@media (min-width: 768px) {
  .metro-station--flex .last-text {
    display: block;
  }
}
.metro-station--flex .icon-man {
  position: relative;
  top: -5px;
}
.metro-block {
  align-items: center;
}
.metro-block .icon-metro {
  margin-right: 9px;
  padding-bottom: 2px;
}
.metro-block .metro-name:last-child .metro-name--comma {
  display: none;
}
.input-block {
  padding-bottom: 20px;
}
.form-input {
  width: 100%;
  border: 1px solid #b3b3b3;
  border-radius: 3px;
  background: #fff !important;
  outline: none;
  background-color: #fff;
  color: #7f7f7f;
  height: 42px;
  padding-left: 9px;
  cursor: text;
  font-size: 14px;
  transition: 0.5s;
}
.form-input::placeholder {
  color: #7f7f7f;
}
@media (min-width: 768px) {
  .form-input {
    height: 30px;
  }
}
.form-input--left {
  width: 50%;
  border-radius: 3px 0 0 3px;
  border-right: 0px;
}
.form-input--right {
  width: 50%;
  border-radius: 0 3px 3px 0;
}
.form-input--focus {
  border: 1px solid #2d82d1;
}
@media (min-width: 1350px) {
  .container-search--block {
    width: 1340px;
    padding-left: 0;
    padding-right: 0;
  }
}
.search-block {
  display: flex;
  flex-wrap: wrap;
  margin-top: 40px;
  padding-top: 20px;
  padding-bottom: 30px;
  padding-left: 20px;
  padding-right: 20px;
  border: 1px solid #e5e9f2;
  border-radius: 10px;
}
@media (min-width: 768px) {
  .search-block-header {
    display: flex;
    flex-wrap: wrap;
  }
}
@media (min-width: 768px) {
  .search-row-item {
    flex-basis: auto;
    padding-right: 35px;
  }
}
.search-item-title {
  padding-bottom: 12px;
}
@media (min-width: 768px) {
  .search-item-title {
    padding-bottom: 14px;
  }
}
@media (min-width: 768px) {
  .search-block-header .item-order-2 {
    order: 2;
  }
  .search-block-header .item-order-3 {
    order: 3;
  }
  .search-block-header .item-order-4 {
    order: 4;
  }
  .search-block-header .item-order-5 {
    order: 5;
  }
  .search-block-header .item-order-6 {
    order: 6;
  }
}
@media (min-width: 768px) and (min-width: 768px) {
  .search-block-header .item-order-2 {
    padding-right: 10px;
    width: 220px;
  }
}
@media (min-width: 768px) and (min-width: 992px) {
  .search-block-header .item-order-2 {
    width: 250px;
    margin-left: auto;
  }
}
@media (min-width: 768px) and (min-width: 1350px) {
  .search-block-header .item-order-2 {
    padding-right: 28px;
    width: 220px;
  }
}
@media (min-width: 768px) and (min-width: 768px) {
  .search-block-header .item-order-3 {
    padding-right: 0;
    width: 220px;
  }
}
@media (min-width: 768px) and (min-width: 992px) {
  .search-block-header .item-order-3 {
    width: 250px;
    margin-left: auto;
  }
}
@media (min-width: 768px) and (min-width: 1350px) {
  .search-block-header .item-order-3 {
    padding-right: 28px;
    width: 220px;
  }
}
@media (min-width: 768px) and (min-width: 768px) {
  .search-block-header .item-order-4 {
    flex-grow: 2;
  }
}
@media (min-width: 768px) and (min-width: 992px) {
  .search-block-header .item-order-4 {
    flex-grow: 1;
    width: 63%;
    margin-right: auto;
  }
}
@media (min-width: 768px) and (min-width: 1350px) {
  .search-block-header .item-order-4 {
    width: 355px;
  }
  .search-block-header .item-order-4 .form-input {
    width: 100%;
  }
}
@media (min-width: 768px) and (min-width: 768px) {
  .search-block-header .item-order-6 {
    justify-content: center;
    padding-bottom: 7px;
    padding-right: 26px;
  }
}
@media (min-width: 768px) and (min-width: 992px) {
  .search-block-header .item-order-6 {
    padding-right: 0;
  }
}
@media (min-width: 768px) and (min-width: 1350px) {
  .search-block-header .item-order-6 {
    justify-content: flex-start;
    padding-bottom: 0;
    padding-right: 28px;
    padding-top: 4px;
  }
}
@media (min-width: 1350px) {
  .search-row-item--input {
    flex-grow: 1;
    margin-right: 0;
  }
}
.search-row-item--rooms {
  padding-bottom: 15px;
}
@media (min-width: 768px) {
  .search-row-item--rooms {
    padding-bottom: 0;
  }
}
.search-row-item-desk {
  display: none;
}
@media (min-width: 768px) {
  .search-row-item-desk {
    display: block;
  }
}
@media (min-width: 1350px) {
  .search-row-item-desk {
    width: 220px;
  }
}
.search-row-item--mobile {
  display: flex;
}
@media (min-width: 768px) {
  .search-row-item--mobile {
    display: none;
  }
}
.search-row-item--mobile .arrow-down {
  margin-left: 18px;
}
.search-row-item--col {
  flex-direction: column;
  align-items: flex-start;
}
@media (min-width: 768px) {
  .search-row-item--col .button {
    min-width: 155px;
  }
}
.search-row-item--col .link-block {
  display: none;
  padding-right: 0;
  margin-bottom: 10px;
}
@media (min-width: 1350px) {
  .search-row-item--col .link-block {
    display: inline-flex;
  }
}
.search-row-item--pb {
  width: 100%;
  padding-bottom: 20px;
}
.search-row-item--pb .input-block {
  padding-bottom: 10px;
}
.search-row-item-badge .badge:first-child {
  margin-right: 5px;
}
.search-row-item-badge .badge {
  height: 30px;
}
.search-row-item-badge .badge-group {
  display: flex;
}
.search-row-item-link {
  display: flex;
  align-items: center;
}
@media (min-width: 768px) {
  .search-row-item-link .text-link {
    font-size: 13px;
  }
}
@media (min-width: 1350px) {
  .search-row-item-link .text-link {
    font-size: 14px;
  }
}
.mobile-badge-block--first {
  margin-right: 40px;
}
.mobile-badge-block--first .badge {
  margin-bottom: 5px;
}
.mobile-badge-block--four {
  width: 100%;
  padding-right: 3px;
  flex-wrap: wrap;
  padding-top: 20px;
}
.mobile-badge-block--four .badge {
  margin-bottom: 5px;
}
.search-block--footer {
  padding-top: 30px;
  width: 100%;
  flex-wrap: wrap;
}
.search-block--footer .badge {
  margin-right: 5px;
}
.search-block--footer .badge {
  min-width: 30px;
}
.search-block--footer .badge-group {
  display: flex;
  flex-wrap: wrap;
}
.mobile-badge-block--second {
  margin-top: 20px;
  margin-bottom: 20px;
  margin-right: 40px;
}
.mobile-badge-block.mobile-badge-block--third {
  margin-bottom: 40px;
}
.mobile-badge-block.mobile-badge-block--third .badge {
  margin-bottom: 5px;
}
.search-row-item--flex {
  display: flex;
  padding-top: 20px;
  align-items: center;
}
.search-item-subtitle {
  padding-top: -14px;
  font-size: 13px;
  color: #8a8b8d;
}
.line {
  width: 100%;
  border-bottom: 1px solid #ced9f2;
  padding-bottom: 30px;
}
.item-order-7 {
  order: 7;
}
@media (min-width: 768px) {
  .dropdown-order-1.search-row-item--pb {
    padding-right: 25px;
  }
}
@media (min-width: 1350px) {
  .dropdown-order-1.search-row-item--pb {
    width: 600px;
  }
}
@media (min-width: 768px) {
  .dropdown-order-2 {
    display: inline-block;
    order: 2;
  }
}
@media (min-width: 768px) {
  .dropdown-order-3.box-visible-desk {
    order: 3;
    padding-right: 26px;
    padding-bottom: 6px;
    align-items: flex-end;
    margin-left: auto;
  }
}
@media (min-width: 1350px) {
  .dropdown-order-3.box-visible-desk {
    align-items: center;
  }
}
@media (min-width: 768px) {
  .dropdown-order-4 {
    order: 4;
  }
}
.dropdown-order-5 {
  order: 5;
}
@media (min-width: 768px) {
  .dropdown-order-6 {
    order: 6;
    padding-bottom: 0;
  }
}
.dropdown-order-7 {
  order: 7;
  display: none;
}
@media (min-width: 768px) {
  .dropdown-order-7 {
    display: block;
    width: 100%;
  }
}
.search-dropdown {
  padding-top: 18px;
}
@media (min-width: 768px) {
  .search-dropdown {
    display: flex;
    flex-wrap: wrap;
  }
  .search-dropdown .search-row-item--doubleinput {
    display: none;
  }
}
.search-dropdown-wrapper {
  display: flex;
  flex-wrap: wrap;
}
@media (min-width: 768px) {
  .mobile-badge-block--second,
  .mobile-badge-block--third {
    margin-top: 0;
    margin-bottom: 0;
  }
}
@media (min-width: 768px) {
  .mobile-badge-block--four {
    margin-bottom: 20px;
  }
}
.search-edit-header {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-top: 28px;
  padding-bottom: 21px;
}
@media (min-width: 768px) {
  .search-edit-header {
    flex-wrap: nowrap;
    align-items: flex-start;
  }
}
.search-edit-header .search-edit-title {
  flex-shrink: 0;
}
.search-edit-header .text-link {
  display: inline-table;
}
.search-edit-items {
  margin-left: 10px;
  margin-right: auto;
}
.search-edit-items .text-link:not(:last-child) {
  margin-right: 30px;
}
.search-row-item-link {
  display: flex;
  padding-bottom: 40px;
  align-items: center;
}
.search-row-item-link .text-link {
  color: #838385;
  border-bottom-color: #838385;
}
@media (min-width: 768px) {
  .search-row-item-link .text-link {
    font-size: 13px;
  }
}
@media (min-width: 1350px) {
  .search-row-item-link .text-link {
    font-size: 14px;
  }
}
.search-edit-basket .basket-text {
  padding-right: 10px;
}
.search-edit-body__item {
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid #e5e9f2;
  padding-top: 9px;
  padding-left: 9px;
  padding-right: 9px;
  justify-content: space-between;
  margin-bottom: 10px;
}
.search-edit-body__item .input-block {
  padding-bottom: 0;
}
.search-edit-body__item .badge {
  margin-top: 10px;
  margin-bottom: 10px;
}
.search-edit-body__item:last-child {
  margin-bottom: 0;
}
.search-edit-badges {
  width: 85%;
  max-width: 880px;
}
.search-link--desk {
  display: none !important;
}
.search-link--desk .link-block__icon {
  order: 2;
  margin-left: 10px;
}
@media (min-width: 768px) {
  .search-link--desk {
    display: inline-flex !important;
  }
}
.basket-text {
  font-size: 13px;
  display: none;
  color: #C3C3C3;
}
@media (min-width: 1350px) {
  .basket-text {
    font-size: 14px;
  }
}
.search-edit-basket:hover .basket-text {
  display: block;
}
.search-edit-basket:hover .icon-basket use {
  fill: red;
}
.search-edit-basket {
  transition: 0.5s;
}
.search-link--mob {
  display: inline-flex !important;
}
.search-link--mob .link-block__icon {
  order: 2;
  margin-left: 10px;
  transform: rotate(180deg);
}
@media (min-width: 768px) {
  .search-link--mob {
    display: none !important;
  }
}
.search-dropdown {
  display: none;
}
.show-full-search {
  display: none;
  width: 129px;
}
.show-full-search .arrow-down {
  margin-left: 9px;
}
@media (min-width: 768px) {
  .show-full-search {
    display: flex;
  }
}
.search-block {
  padding-bottom: 40px;
}
@media (min-width: 768px) {
  .search-block {
    padding-bottom: 0;
  }
}
.show-flex {
  display: flex;
}
.areas-badges .badge-group {
  display: none;
}
.areas-badges .badge-group.visible {
  display: flex;
}
.close-full-mobile {
  display: none;
}
@media (min-width: 768px) {
  .close-full-mobile {
    display: none !important;
  }
}
.close-full-mobile {
  border-top: 1px solid #e5e9f2;
}
.close-full-mobile .arrow-down {
  margin-left: 9px;
}
.line.dropdown-order-6 {
  display: none;
}
@media (min-width: 768px) {
  .line.dropdown-order-6 {
    display: block;
  }
}
.search-edit-body {
  display: none;
}
.show-block {
  display: block;
}
.search-edit-body__item,
.search-edit-basket {
  display: flex;
}
.currency-select {
  border-width: 0px;
  background-color: transparent;
  color: #2c81d1;
  border-bottom: 1px dashed rgba(44, 129, 209, 0.2);
  outline: none;
  padding-left: 2px;
  padding-right: 2px;
  font-weight: bold;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.currency-select:active,
.currency-select:focus {
  border-color: transparent;
  border-bottom: 1px dashed rgba(44, 129, 209, 0.2);
  outline: none;
}
.search-save-mob {
  display: block;
  margin-top: 20px;
  margin-bottom: 20px;
  padding-top: 20px;
  padding-bottom: 26px;
  padding-right: 12px;
  padding-left: 14px;
  border: 1px solid #e5e9f2;
  border-radius: 10px;
}
.search-save-mob .search-save-title {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  padding-bottom: 26px;
}
.search-save-mob .arrow-down {
  margin-left: 70px;
}
@media (min-width: 768px) {
  .search-save-mob {
    display: none;
  }
}
.search-edit-items--mobile {
  display: none;
}
.search-edit-items--mobile .search-edit-items--links {
  display: block;
}
.search-edit-items--mobile .search-edit-items--links .text-link {
  display: inline-flex;
  line-height: 1.5;
  margin-bottom: 15px;
}
.search-edit-items--mobile.visible {
  display: block;
}
.search-edit--mobile {
  flex-wrap: wrap;
  padding-top: 10px;
}
.icon-rotate {
  transform: rotate(180deg);
}
.search-row-item-link.box-visible-mob {
  flex-direction: column;
  align-items: flex-start;
}
.search-row-item-link.box-visible-mob .icon-heart--small {
  margin-bottom: 15px;
}
.button {
  font-size: 16px;
  line-height: 16px;
  border: none;
  border-radius: 3px;
  text-align: center;
  background-color: #2c81d1;
  color: #fff;
  display: inline-block;
  outline: none;
  transition: .25s;
}
.button:hover,
.button:active,
.button:focus {
  color: #fff;
  text-decoration: none;
  opacity: .85;
}
@media (min-width: 768px) {
  .button {
    font-size: 14px;
  }
}
.button--basic {
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  padding: 0 10px;
}
@media (min-width: 768px) {
  .button--basic {
    height: 30px;
    width: auto;
    display: inline-flex;
    /**padding-left: 32px;
		padding-right: 32px;**/
  }
}
.pointer {
  cursor: pointer;
}
.button--white {
  cursor: pointer;
  font-size: 16px;
  font-weight: 400;
  min-height: 30px;
  align-items: center;
  display: inline-flex;
  padding-left: 8px;
  padding-right: 8px;
  color: #fff;
  border: 1px solid #fff;
  border-radius: 3px;
  text-align: center;
  letter-spacing: .5px;
  margin-bottom: 3px;
}
@media (min-width: 768px) {
  .button--white {
    min-height: 24px;
    font-size: 14px;
  }
  .button--white .badge-group {
    flex-shrink: 0;
  }
}
.button--white:hover {
  color: #fff;
  border: 1px solid #fff;
  box-shadow: 0 0 10px 1px #ffffff3b;
}
#back-top {
  position: fixed;
  bottom: 40px;
  right: 1%;
}
.btn-add {
  position: relative;
  min-width: 270px;
  height: 60px;
  padding: 1.6rem 4.8rem ;
  white-space: nowrap;
  font-weight: 500;
}
@media (min-width: 768px) {
  .btn-add {
    padding: 7px 10px 8px 28px;
    min-width: 200px;
    height: 30px;
  }
}
.btn-add:before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(170%, -43%);
  width: 20px;
  height: 20px;
  background-image: url(../../img/plus.svg);
  background-repeat: no-repeat;
}
@media (min-width: 768px) {
  .btn-add:before {
    content: "\f067";
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(170%, -30%);
    width: 20px;
    height: 20px;
    font-size: 26px;
    font-family: FontAwesome;
    width: 4px;
    height: 11px;
    font-size: 14px;
    transform: translate(245%, -80%);
  }
}
.btn-tel {
  width: 120px;
  height: 30px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  line-height: normal;
  font-weight: 700;
}
@media (min-width: 768px) {
  .btn-tel {
    padding: 6px 10px;
    width: 108px;
    min-height: 24px;
  }
}
.mobile-button {
  display: flex;
  position: relative;
  color: #fff;
  border-radius: 3px;
  width: 40px;
  height: 40px;
  transition: .25s;
}
.mobile-button img {
  margin: auto;
}
.mobile-button:hover,
.mobile-button:active,
.mobile-button:focus {
  color: #fff;
  opacity: .85;
}
.btn-add.section-btn {
  padding-right: 32px;
  text-align: center;
  padding-left: 32px;
}
.btn-add.section-btn:before {
  display: none;
}
.card a.button {
  color: #fff;
  font-weight: 700;
  padding: 20px 50px;
}
.button-wrapper {
  margin-top: 35px;
  margin-bottom: 20px;
}
.button-wrapper--mobile {
  display: block;
}
@media (min-width: 768px) {
  .button-wrapper--mobile {
    display: none;
  }
}
.section-btn {
  border-radius: 5px;
}
.btn-mode1 {
  display: inline-flex;
  align-items: center;
  height: 60px;
  padding-left: 20px;
  padding-right: 20px;
  font-weight: 700;
  width: 100%;
}
.btn-mode1 .icon-notify {
  height: 60px;
  display: flex;
  padding-right: 19px;
  border-right: 1px solid #2874bc;
}
.btn-mode1 .icon-notify svg {
  margin: auto;
  width: 19px;
  height: 22px;
}
.btn-mode1 .icon-notify path {
  fill: #fff;
}
@media (min-width: 768px) {
  .btn-mode1 .icon-notify {
    border-right: 0px;
    padding-right: 9px;
  }
}
.btn-mode1 .btn-text--mobile {
  font-size: 16px;
  height: 60px;
  padding-top: 23px;
  padding-left: 39px;
  border-left: 1px solid #569ada;
}
@media (min-width: 768px) {
  .btn-mode1 .btn-text--mobile {
    display: none;
  }
}
.btn-mode1 .btn-text {
  display: none;
}
@media (min-width: 768px) {
  .btn-mode1 .btn-text {
    display: block;
  }
}
@media (min-width: 768px) {
  .btn-mode1 {
    width: auto;
    height: 30px;
  }
}
/*КАСТОМНЫЙ ЧЕКБОКС*/
.checkbox-label {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.checkbox-label .checkbox-title {
  margin-right: 10px;
}
.check--hc-type .checkbox-title {
  color: #2c81d1;
  border-bottom: 1px dashed rgba(44, 129, 209, 0.2);
}
.check--hc-type:hover .checkbox-title {
  color: #d15656;
  border-bottom-color: rgba(209, 86, 86, 0.2);
}
.form-checkbox {
  width: 0.01px;
}
.form-checkbox:focus + .custom-checkbox {
  border: 1px solid #2c81d1;
}
.custom-checkbox {
  position: relative;
  display: inline-block;
  min-width: 20px;
  min-height: 20px;
  border-radius: 50%;
  border: 1px solid #2c81d1;
  background-color: #fff;
  transition: 0.5s ease-out;
}
.form-checkbox:checked + .custom-checkbox:after {
  content: '';
  position: absolute;
  border: 1px solid #2c81d1;
  min-width: 24px;
  min-height: 24px;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.form-checkbox:checked + .custom-checkbox {
  border: 2px solid #fff;
  background-color: #2c81d1;
}
.buttons-reg {
  display: block;
}
@media (min-width: 768px) {
  .buttons-reg {
    display: flex;
  }
}
.buttons-reg--btn {
  width: 100%;
  max-width: 480px;
  height: 60px;
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
  border: 0px;
  font-size: 20px;
  font-weight: 700;
  padding-top: 20px;
  background-color: #3d5a96;
  box-shadow: 0 10px 20px rgba(61, 90, 150, 0.35);
  transition: .5s;
}
@media (min-width: 768px) {
  .buttons-reg--btn {
    font-size: 16px;
  }
}
@media (min-width: 992px) {
  .buttons-reg--btn {
    font-size: 20px;
  }
}
.buttons-reg--btn:hover {
  background-color: #334860;
}
.btn-fb {
  margin-bottom: 20px;
}
@media (min-width: 768px) {
  .btn-fb {
    margin-right: 20px;
    margin-bottom: 0;
  }
}
.btn-desk {
  display: none;
}
@media (min-width: 768px) {
  .btn-desk {
    display: block;
  }
}
.btn-mob {
  display: block;
}
@media (min-width: 768px) {
  .btn-mob {
    display: none;
  }
}
.form-group label {
  color: #000;
  font-size: 16px;
  font-weight: 700;
  margin-left: 0.1em;
  margin-bottom: 10px;
}
.header {
  background-color: #fff;
  box-shadow: 0px 2px 0px 0px #e5e9f2;
}
.logo-icon {
  margin-right: 20px;
}
.logo-icon svg {
  height: 40px;
  width: 200px;
}
.header-top {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 141px;
  background-color: #fff;
  padding-top: 18px;
  padding-bottom: 2rem;
}
@media (min-width: 768px) {
  .header-top {
    padding-top: 2em;
    align-items: center;
    flex-direction: row;
    height: 80px;
  }
}
.header-top .logoblock {
  margin-bottom: 1.8rem;
  margin-right: 2.5rem;
}
@media (min-width: 768px) {
  .header-top .logoblock {
    margin-bottom: 0px;
  }
}
@media (min-width: 768px) {
  .header-top .links-block {
    margin-left: auto;
    padding-left: 1rem;
  }
}
.header-top .mobile-button {
  display: flex;
  position: absolute;
  right: 0px;
  top: 18px;
}
@media (min-width: 768px) {
  .header-top .mobile-button {
    top: 0;
    right: 0;
    position: relative;
  }
}
@media (min-width: 992px) {
  .header-top .mobile-button {
    display: none;
  }
}
.header-top .button-block .button.btn-add {
  display: none;
}
@media (min-width: 992px) {
  .header-top .button-block .button.btn-add {
    display: block;
  }
}
.header-top .button-block .logo-text {
  display: none;
}
@media (min-width: 1350px) {
  .header-top .button-block .logo-text {
    display: block;
  }
}
.links-block {
  align-items: center;
}
.logoblock {
  align-items: center;
}
.logoblock .logo-icon {
  margin-right: 3rem;
}
@media (min-width: 1350px) {
  .logoblock .logo-icon {
    margin-right: 1.1rem;
  }
}
.logoblock .logo-text {
  display: none;
  color: #334860;
  width: 210px;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .logoblock .logo-text {
    display: block;
  }
}
@media (min-width: 768px) {
  .logoblock {
    justify-content: flex-start;
  }
}
@media (min-width: 992px) {
  .logoblock {
    width: auto;
  }
}
.header__icon-group {
  display: flex;
  align-items: center;
  padding-top: 6px;
  margin-right: 2.2rem;
}
@media (min-width: 768px) {
  .header__icon-group {
    margin-bottom: 0;
  }
}
.header__icon-group .header__icon-group__item--add {
  margin-right: -32px;
}
@media (min-width: 768px) {
  .header__icon-group .header__icon-group__item--add {
    margin-right: 20px;
  }
}
.header__icon-group__item {
  padding-bottom: 8px;
}
.header__icon-group__item:hover {
  border-bottom: 2px solid #5696d1;
  padding-bottom: 6px;
}
.header__icon-group__item:hover i path,
.header__icon-group__item:hover .icon-heart path {
  fill: #5696d1;
}
.header__icon-group__item:hover .icon-notify--path {
  fill: #5696d1;
}
.header__icon-group__item:not(:last-child) {
  margin-right: 32px;
}
@media (min-width: 768px) {
  .header__icon-group__item:not(:last-child) {
    margin-right: 20px;
  }
}
.header__user-lk {
  display: flex;
  align-items: end;
  justify-content: flex-end;
}
@media (min-width: 768px) {
  .header__user-lk {
    align-items: center;
    justify-content: flex-start;
  }
}
.header__user__avatar {
  font-weight: bold;
  right: 0px;
  border: 1px solid #e5e9f2;
  border-radius: 3px;
}
@media (min-width: 768px) {
  .header__user__avatar {
    position: relative;
    right: 0;
    bottom: 0;
  }
}
@media (min-width: 1200px) {
  .header__user__avatar {
    margin-right: 1.3rem;
  }
}
.header__user__avatar:hover {
  border: 1px solid #2c81d1;
}
.header__user__lk-dropdown {
  display: none;
}
.header__user__lk-dropdown a {
  margin-right: 10px;
  display: inline-block;
}
@media (min-width: 1200px) {
  .header__user__lk-dropdown {
    display: inherit;
  }
}
.invisible {
  visibility: hidden;
}
#usermenu {
  position: absolute;
  top: 0;
  margin-top: 141px;
  padding-right: 3rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  box-shadow: 0px 2px 0px 2px #e5e9f2;
  background: #fff;
  right: 0;
  border-bottom-left-radius: .25rem;
  border-bottom-right-radius: .25rem;
  z-index: 40;
}
@media (min-width: 768px) {
  #usermenu {
    margin-top: 80px;
  }
}
#usermenu ul li {
  margin-bottom: 1rem;
  list-style: none;
  display: flex;
}
.header-content {
  display: flex;
  align-items: center;
}
.header-content .logoblock {
  margin-right: auto;
}
.header-info-block {
  display: none;
  margin-right: 45px;
  color: #000;
}
@media (min-width: 1200px) {
  .header-info-block {
    display: flex;
  }
}
.header-info {
  display: flex;
  align-items: center;
}
.header-info__number {
  font-size: 40px;
}
.header-info__text {
  font-size: 14px;
  padding-left: 12px;
  max-width: 170px;
}
.header-btn {
  border: 0px;
  background-color: #2c81d1;
  color: #fff;
  font-weight: 700;
  border-radius: 5px;
  min-width: 90px;
  height: 30px;
  text-align: center;
  padding-top: 2px;
  transition: .5s;
  cursor: pointer;
}
.header-btn:hover {
  box-shadow: 0 0 12px 4px rgba(44, 129, 209, 0.45);
}
#contact-form {
  width: 100%;
}
.form-item {
  position: relative;
}
.input-placeholder {
  padding: 2px 4px;
  background-color: #FFF;
  font-size: 14px;
  color: #4d4d4d;
  transition: .3s;
}
.input-placeholder.active {
  top: -12px;
  color: #3d5a96;
  font-weight: 700;
}
.form-field {
  width: 100%;
  height: 45px;
  padding-left: 16px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  border: 1px solid #4d4d4d;
  margin-bottom: 20px;
}
.form-field.active {
  border: 1px solid #3d5a96;
  -webkit-box-shadow: 0 0 8px rgba(61, 90, 150, 0.35);
  box-shadow: 0 0 8px rgba(61, 90, 150, 0.35);
}
/*
.form-footer{
	display: flex;
	-webkit-align-items: center;
	align-items: center;
	-webkit-justify-content: center;
	justify-content: center;
}*/
.form-footer .buttons-reg--btn {
  height: 40px;
  margin-left: auto;
  color: #FFF;
  font-size: 16px;
  font-weight: 700;
  padding-top: 5px;
  padding-left: 16px;
  padding-right: 16px;
}
.contact-form__success {
  background-color: #41ae1b;
  position: absolute;
  top: 0%;
  left: 0%;
  right: 0;
  display: block;
  color: white;
  padding: 30px 20px;
  font-weight: 700;
  border-radius: 8px;
  padding: 40px;
  padding-bottom: 60px;
  font-size: 20px;
  bottom: 0;
  text-align: center;
  min-width: 300px;
}
#regButton {
  width: 220px;
}
#rezetButton {
  width: 130px;
  margin-left: 0;
}
/** СТИЛИ МОДАЛЬНЫХ ОКОН РЕГИСТРАЦИИ **/
.form-input {
  width: 100%;
  border: 1px solid #b3b3b3;
  border-radius: 3px;
  background: #fff !important;
  outline: none;
  background-color: #fff;
  color: #7f7f7f;
  height: 42px;
  padding-left: 9px;
  cursor: text;
  font-size: 14px;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}
.text-link {
  font-size: 16px;
  color: #2c81d1;
  border-bottom: 1px dashed rgba(44, 129, 209, 0.2);
}
.text-link--solid {
  border-bottom: 1px solid rgba(44, 129, 209, 0.2);
}
.button--basicbig {
  height: 60px;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-weight: 700;
  font-size: 20px;
  cursor: pointer;
  border: 0px;
  margin-right: 28px;
  margin-bottom: 0;
  -webkit-box-shadow: 0px 10px 20px 0px rgba(88, 122, 154, 0.35);
  box-shadow: 0px 10px 20px 0px rgba(88, 122, 154, 0.35);
}
/**
.button {
    font-size: 16px;
    line-height: 16px;
    border-radius: 3px;
    text-align: center;
    background-color: #2c81d1;
    color: #fff;
    display: inline-block;
    outline: none;
    -webkit-transition: .25s;
    -o-transition: .25s;
    transition: .25s;
}**/
.footer-tooltip {
  display: none;
  padding: 10px;
  border-radius: 5px;
  background-color: #000;
  position: absolute;
  top: -20px;
  left: 50%;
  font-size: 14px;
  text-align: center;
  max-width: 250px;
  min-width: 250px;
  -webkit-transform: translate(-50%, -100%);
  -ms-transform: translate(-50%, -100%);
  transform: translate(-50%, -100%);
}
.footer-tooltip:after {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border: 10px solid #000;
  border-bottom: 0px;
  border-left-color: transparent;
  border-right-color: transparent;
  bottom: -8px;
  left: 50%;
}
.formModales .modal-dialog {
  max-width: 860px;
}
@media (max-width: 480px) {
  .formModales .modal-dialog {
    margin: 0;
  }
}
.formModales .modal-content {
  background-color: #FFF;
}
.formModales .modal-header {
  position: relative;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 25px 25px 10px;
  font-weight: 700;
  border-bottom: 0px;
}
@media (min-width: 768px) {
  .formModales .modal-header {
    padding: 30px 35px 10px;
  }
}
.formModales .header-border {
  display: none;
  position: absolute;
  left: 35px;
  right: 35px;
  top: 85px;
  height: 1px;
  background-color: #e5e5e5;
}
@media (min-width: 768px) {
  .formModales .header-border {
    display: block;
  }
}
.formModales .modal-title {
  font-size: 28px;
  margin-bottom: 23px;
  line-height: 1;
}
@media (min-width: 768px) {
  .formModales .modal-title {
    margin-bottom: 0;
    font-size: 40px;
  }
}
.formModales .modal-title-mb-0 {
  margin-bottom: 0;
}
.formModales .modal-title--mr {
  margin-right: 1.1em;
}
.formModales .close {
  outline: none;
  position: absolute;
  right: 16px;
  top: 20px;
  color: #CECECE;
  line-height: 0.7;
}
@media (min-width: 768px) {
  .formModales .close {
    right: 40px;
    top: 30px;
  }
}
.formModales .close:hover {
  opacity: 0.7;
}
.formModales .facebook-btn {
  height: 40px;
  font-size: 14px;
  font-weight: 700;
  padding: 10px 31px;
  color: #FFF;
  background-color: #3d5a96;
  border-radius: 4px;
  display: inline-block;
}
@media (min-width: 768px) {
  .formModales .facebook-btn {
    padding: 10px 21px;
  }
}
.formModales .facebook-btn:hover {
  background-color: #334860;
  color: #FFF;
}
.formModales .modal-body {
  padding: 25px 25px 35px;
}
@media (min-width: 768px) {
  .formModales .modal-body {
    padding: 25px 35px 38px;
  }
}
.formModales form {
  width: 100%;
  position: relative;
  z-index: 1000;
}
.formModales .form-item {
  max-width: 500px;
  margin-bottom: 10px;
  position: relative;
}
.formModales .form-item__icon {
  position: absolute;
  right: 13px;
  top: 36px;
  cursor: pointer;
}
.formModales .form-item__icon:hover .footer-tooltip {
  display: block;
}
.formModales .form-item__label {
  color: #000;
  font-size: 16px;
  font-weight: 700;
}
.formModales .form-input {
  outline: none;
  display: block;
  width: 100%;
  color: #838385;
  font-size: 18px;
  height: 50px;
}
@media (min-width: 768px) {
  .formModales .form-input {
    font-size: 20px;
  }
}
.formModales .form-item--email {
  margin-bottom: 20px;
}
.formModales .form-footer {
  padding: 30px 25px 38px;
  background-color: #DBE6FF;
}
@media (min-width: 768px) {
  .formModales .form-footer {
    padding: 30px 35px 50px;
  }
}
.formModales .button--basic {
  font-size: 20px;
  cursor: pointer;
  border: 0px;
  -webkit-box-shadow: 0px 10px 20px 0px rgba(88, 122, 154, 0.35);
  box-shadow: 0px 10px 20px 0px rgba(88, 122, 154, 0.35);
  margin-bottom: 45px;
}
@media (min-width: 480px) {
  .formModales .button--basic {
    max-width: 240px;
  }
}
@media (min-width: 768px) {
  .formModales .button--basic {
    margin-right: 28px;
    margin-bottom: 0;
  }
}
.formModales .add-block {
  font-size: 16px;
  color: #000;
}
.formModales .add-block p {
  line-height: 1.5;
  padding-bottom: 4px;
}
@media (min-width: 768px) {
  .formModales .add-block--restory {
    max-width: 200px;
  }
}
@media (min-width: 768px) {
  .formModales .add-block--fix {
    max-width: 500px;
  }
}
.formModales .form-footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: left;
}
.formModales .modal-flex {
  display: block;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
@media (min-width: 768px) {
  .formModales .modal-flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}
.formModales .modal-tooltip {
  min-width: 170px;
  color: #FFF;
  -webkit-transform: translate(-78%, -100%);
  -ms-transform: translate(-78%, -100%);
  transform: translate(-78%, -100%);
}
.formModales .footer-tooltip:after {
  left: 71%;
}
.formModales .mt-45 {
  margin-top: 40px;
}
.text-link--bold {
  font-weight: 700;
}
.modal-body--reg {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.modal-col {
  width: 100%;
  max-width: 480px;
}
@media (min-width: 768px) {
  .modal-col:first-child {
    margin-right: 40px;
  }
}
@media (min-width: 1200px) {
  .modal-col:first-child {
    min-width: 480px;
  }
}
.modal-col:last-child {
  display: none;
  padding-top: 26px;
}
@media (min-width: 768px) {
  .modal-col:last-child {
    display: block;
  }
}
.page-modal {
  position: relative;
}
.modal-content-part {
  -webkit-transition: .5s;
  -o-transition: .5s;
  transition: .5s;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  border-radius: 0px 0px 10px 10px;
  overflow: hidden;
  opacity: 0;
}
@media (min-width: 480px) {
  .modal-content-part {
    border-radius: 10px;
  }
}
@media (min-width: 768px) {
  .modal-content-part {
    top: auto;
  }
}
.modal-content-part.active {
  opacity: 1;
}
.link-block {
  display: inline-flex;
  align-items: center;
  padding-right: 24px;
  color: #2c81d1;
}
.link-block .link-block__icon {
  padding-right: 10px;
}
@media (min-width: 768px) {
  .link-group {
    flex-shrink: 0;
  }
}
.simple-link {
  font-size: 16px;
  color: #2c81d1;
  transition: .25s;
}
.simple-link span {
  background: linear-gradient(to right, rgba(44, 129, 209, 0.2), rgba(44, 129, 209, 0.2) 60%, transparent 60%);
  background-position: 0 100%;
  background-repeat: repeat-x;
  background-size: 5px 1px;
  padding-bottom: 4px;
}
.simple-link span:hover {
  background: linear-gradient(to right, rgba(209, 86, 86, 0.2), rgba(209, 86, 86, 0.2) 60%, transparent 60%);
  background-position: 0 100%;
  background-repeat: repeat-x;
  background-size: 5px 1px;
  color: #d15656;
}
@media (min-width: 768px) {
  .simple-link {
    font-size: 14px;
  }
}
.simple-link.simple-link--line {
  background-image: none;
}
@media (min-width: 768px) {
  .fix-width {
    min-width: 97px;
  }
}
.dropdown-double-item {
  font-size: 16px;
}
.dropdown-double-item .icon-notify {
  margin-right: 10px;
}
.dropdown-double-item .dropdown-double-item__text {
  display: flex;
  flex-direction: column;
}
.dropdown-double-item .dropdown-double-item__text span {
  width: auto;
  display: inline;
  border-bottom: 1px dashed rgba(44, 129, 209, 0.5);
  color: #2c81d1;
}
.dropdown-double-item .dropdown-double-item__text .short-span {
  width: 85px;
  padding-top: 4px;
}
@media (min-width: 480px) {
  .dropdown-double-item .dropdown-double-item__text .short-span {
    padding-top: 0;
    width: 85px;
    padding-left: 4px;
  }
}
@media (min-width: 480px) {
  .dropdown-double-item .dropdown-double-item__text {
    flex-direction: row;
  }
}
@media (min-width: 768px) {
  .dropdown-double-item {
    font-size: 14px;
  }
}
.simple-link--error {
  display: none;
}
@media (min-width: 1200px) {
  .simple-link--error {
    display: block;
  }
}
.text-link {
  font-size: 16px;
  color: #2c81d1;
  border-bottom: 1px dashed rgba(44, 129, 209, 0.2);
}
.text-link:hover {
  color: #d15656;
  border-bottom: 1px dashed rgba(209, 86, 86, 0.2);
}
@media (min-width: 768px) {
  .text-link {
    font-size: 14px;
  }
}
.text-link--line {
  border-bottom: 1px solid rgba(44, 129, 209, 0.2);
}
.text-link--line:hover {
  border-bottom: 1px solid rgba(209, 86, 86, 0.2);
}
.simple-link.link-solid-border,
.link-solid-border {
  border-bottom: 1px solid rgba(44, 129, 209, 0.2);
}
.simple-link.link-solid-border:hover,
.link-solid-border:hover {
  border-color: rgba(209, 86, 86, 0.2);
}
.fletter {
  text-transform: capitalize;
}
.link-btn-block {
  display: flex;
  align-items: center;
}
.link-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  border: 1px solid #2d82d1;
  color: #2d82d1;
  margin-right: 4px;
  width: 40px;
  height: 40px;
  transition: 0.5s;
  background: transparent;
  cursor: pointer;
}
@media (min-width: 768px) {
  .link-btn {
    width: 30px;
    height: 30px;
  }
}
.link-btn:hover {
  color: #fff;
  background-color: #2d82d1;
}
.link-btn--active {
  color: #fff;
  background-color: #2d82d1;
}
.link-title--middle {
  font-size: 20px;
}
.text-link--grey {
  color: #888;
  padding-bottom: 2px;
  border-bottom-color: rgba(154, 154, 154, 0.3);
}
.text-link--grey:hover {
  opacity: .7;
}
.hc-photo-wrapper {
  display: none;
  margin-bottom: 40px;
}
@media (min-width: 768px) {
  .hc-photo-wrapper {
    display: block;
  }
}
.hc-photo-mobile {
  display: block;
}
@media (min-width: 768px) {
  .hc-photo-mobile {
    display: none ;
  }
}
.hc-photo-mobile .carousel-item img {
  border-radius: 5px;
}
.switch-block__main-photo {
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  position: relative;
  margin-bottom: 8px;
}
.switch-block__main-photo img {
  max-height: 100%;
  max-width: 100%;
  width: 100%;
  display: block;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
@media (min-width: 768px) {
  .switch-block__main-photo {
    height: 100%;
    max-height: 570px;
    margin-bottom: 10px;
  }
}
@media (min-width: 1200px) {
}
@media (min-width: 1350px) {
}
.switch-block__thumbnails {
  display: none;
  cursor: pointer;
}
@media (min-width: 768px) {
  .switch-block__thumbnails {
    display: flex;
    max-width: 850px;
    margin-left: auto;
    margin-right: auto;
  }
}
@media (min-width: 1200px) {
}
@media (min-width: 1350px) {
}
.thumbnails__image {
  width: 80px;
  height: 70px;
  margin-right: 10px;
  overflow: hidden;
  position: relative;
  border-radius: 8px;
}
.thumbnails__image:last-child {
  margin-right: 0;
}
.thumbnails__image:last-child:after {
  display: flex;
  position: absolute;
  align-items: center;
  padding: 25px;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  font-size: 20px;
  line-height: 1.25;
  font-weight: 700;
  color: #fff;
}
@media (min-width: 768px) {
  .thumbnails__image:last-child {
    margin-right: 0;
  }
}
@media (min-width: 1200px) {
  .thumbnails__image {
    width: 76px;
    height: 66px;
  }
}
@media (min-width: 1350px) {
  .thumbnails__image {
    width: 80px;
    height: 70px;
  }
}
.thumbnails__image img {
  max-width: 100%;
  display: block;
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}
.wimg {
  display: inline-block;
  padding: 10px;
  opacity: 0.5;
  max-width: 350px;
  max-height: 250px;
}
.watermark {
  position: absolute;
  z-index: 5;
  width: 100%;
  top: 20px;
  opacity: 0.7;
}
.badge {
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  min-height: 30px;
  align-items: center;
  display: inline-flex;
  padding-left: 8px;
  padding-right: 8px;
  color: #5696d1;
  border: 1px solid #5696d1;
  border-radius: 3px;
  text-align: center;
  letter-spacing: .5px;
  margin-bottom: 3px;
}
@media (min-width: 768px) {
  .badge {
    min-height: 24px;
    font-size: 14px;
  }
  .badge .badge-group {
    flex-shrink: 0;
  }
}
.badge--dark {
  background-color: #334860;
  border: 1px solid #334860;
  color: #fff;
}
.big-badge {
  background-color: #334860;
  color: #fff;
  min-height: 82px;
  width: 200px;
  line-height: 1.3;
  padding-top: 14px;
  padding-left: 24px;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
}
@media (min-width: 768px) {
  .big-badge {
    padding-top: 3px;
    padding-left: 10px;
    padding-bottom: 3px;
    min-height: 55px;
    border-radius: 4px;
  }
}
@media (min-width: 1200px) {
  .big-badge {
    width: 160px;
  }
}
@media (min-width: 1350px) {
  .big-badge {
    width: 200px;
    padding-left: 15px;
  }
}
.big-badge__top {
  font-size: 24px;
  font-weight: 700;
}
@media (min-width: 768px) {
  .big-badge__top {
    font-size: 20px;
  }
}
.big-badge__bottom {
  font-size: 16px;
}
@media (min-width: 768px) {
  .big-badge__bottom {
    font-size: 14px;
  }
}
.badge--active {
  background-color: #2c81d1;
  color: #fff;
}
.badge--red {
  background-color: #d15656;
  border: 1px solid #d15656;
  color: #fff;
}
.badge--green {
  background-color: #41ae1b;
  border: 1px solid #41ae1b;
  color: #fff;
}
.blue {
  background-color: #2c81d1 !important;
}
.red {
  background-color: #d15656 !important;
}
.orange {
  background-color: #ff8c00 !important;
}
.text-orange {
  color: #ff8c00 !important;
}
.dark {
  background-color: #334860 !important;
}
.text-navi {
  color: #95aabf !important;
}
.black {
  background-color: #000 !important;
}
.cherry {
  background-color: #d15656 !important;
}
.green {
  background-color: #41ae1b !important;
}
.rounded {
  border-radius: 10px;
}
.apartment-database {
  margin-bottom: 50px;
}
.apartment-item {
  border: 1px solid #e5e9f2;
  border-radius: 8px;
  height: 230px;
  padding: 18px 14px ;
  font-size: 16px;
  margin-bottom: 12px;
  background-color: #fff;
  overflow: hidden;
  color: #000;
}
@media (min-width: 768px) {
  .apartment-item {
    width: 100%;
    height: 50px;
    font-size: 14px;
    padding: 0;
    padding-right: 15px;
  }
}
.apartment-item .icon-group .icon-heart svg {
  width: 24px;
  height: 24px;
}
@media (min-width: 768px) {
  .apartment-item .icon-group .icon-heart svg {
    width: 19px;
    height: 17px;
  }
}
.apartment-item .icon-price-down {
  cursor: default;
}
.apartment-item-column {
  height: 140px;
  display: flex;
  flex-wrap: wrap;
}
@media (min-width: 768px) {
  .apartment-item-column {
    height: 50px;
  }
}
.apartment-info {
  width: 100%;
  flex-direction: column;
  align-items: center;
}
@media (min-width: 768px) {
  .apartment-info {
    flex-direction: row;
  }
}
.apartment-info--pb {
  margin-bottom: 16px;
}
@media (min-width: 768px) {
  .apartment-info--pb {
    margin-bottom: 0;
  }
}
.apartment-info__col {
  width: 100%;
  height: 25px;
  margin-bottom: 5px;
}
@media (min-width: 768px) {
  .apartment-info__col {
    width: 50%;
    margin-bottom: 0;
  }
}
.info-item {
  min-width: 105px;
  text-align: center;
  font-weight: 500;
  align-items: center;
  display: flex;
  padding-left: 10px;
}
@media (min-width: 768px) {
  .info-item {
    width: 50%;
  }
}
.apartment-img {
  background-color: #fefefe;
  background-position: center;
  background-repeat: no-repeat;
  min-width: 60px;
  height: 50px;
  border-radius: 5px 0 0 5px;
}
.column-item {
  width: 100%;
}
@media (min-width: 768px) {
  .column-item {
    width: 50%;
  }
}
@media (min-width: 768px) {
  .info-item--rooms {
    width: 55%;
    padding-left: 28px;
  }
}
.info-item--area {
  width: 45%;
}
@media (min-width: 768px) {
  .info-item--floor {
    width: 40%;
  }
}
.info-item--repair {
  width: 60%;
}
.info-item--area,
.info-item--rooms {
  font-size: 16px;
  font-weight: 700;
  color: #5696d1;
}
.info-item--area span,
.info-item--rooms span {
  border-bottom: 1px solid rgba(44, 129, 209, 0.2);
}
@media (min-width: 768px) {
  .info-item--area,
  .info-item--rooms {
    font-size: 14px;
    font-weight: 500;
  }
}
@media (min-width: 768px) {
  .info-item--rooms {
    color: #5696d1;
  }
}
@media (min-width: 768px) {
  .info-item--area {
    color: #000;
  }
}
@media (min-width: 768px) {
  .info-item--area span {
    border-bottom: 0px;
  }
}
.info-item--floor,
.info-item--repair {
  font-size: 16px;
}
@media (min-width: 768px) {
  .info-item--floor,
  .info-item--repair {
    font-size: 14px;
  }
}
.hidden-rub {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 10px;
}
.visible-usd,
.visible-rub {
  display: none;
}
@media (min-width: 768px) {
  .visible-usd,
  .visible-rub {
    display: inline-block;
  }
}
.hidden-rub,
.hidden-usd {
  display: inline-block;
}
@media (min-width: 768px) {
  .hidden-rub,
  .hidden-usd {
    display: none;
  }
}
.visible-usd,
.visible-rub,
.hidden-usd {
  font-weight: 700;
}
.info-item.info-item--price-rub {
  width: 120px;
}
@media (min-width: 768px) {
  .info-item.info-item--price-rub {
    width: 100%;
  }
}
@media (min-width: 1152px) {
  .info-item.info-item--price-rub {
    width: 60%;
  }
}
.info-item--price-meter {
  font-weight: 400;
}
@media (min-width: 768px) {
  .info-item--price-meter {
    width: 40%;
  }
}
.column-contacts {
  position: relative;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  margin-top: 10px;
}
.column-contacts .icon-group {
  margin-left: auto;
  display: flex;
}
@media (min-width: 768px) {
  .column-contacts {
    margin-top: 0;
  }
}
.contact-phone {
  width: 125px;
  padding-left: 11px;
  height: 30px;
  line-height: 1.7;
  padding-right: 5px;
  border-radius: 3px;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: #2c81d1;
  color: #fff;
  transition: .8s;
}
@media (min-width: 768px) {
  .contact-phone {
    height: 24px;
    width: 110px;
    line-height: 0.9;
  }
}
.contact-phone:hover {
  color: #fff;
}
.show-phoneNumber {
  width: 150px;
}
.icon-mail {
  display: block;
  margin-right: 20px;
}
@media (min-width: 768px) {
  .icon-mail {
    display: none;
  }
}
.icon-mail:hover {
  opacity: .8;
}
.apartment-info__col--short {
  width: 100%;
  justify-content: flex-start;
}
@media (min-width: 768px) {
  .apartment-info__col--short {
    border-right: 1px solid #e5e9f2;
  }
  .apartment-info__col--short .info-item {
    justify-content: flex-end;
    width: 100%;
  }
}
@media (min-width: 1350px) {
  .apartment-info__col--short {
    padding-right: 20px;
    width: 30%;
  }
  .apartment-info__col--short .info-item {
    justify-content: flex-end;
    width: 100%;
  }
}
.apartment-info__col--short .info-item--price {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (min-width: 768px) {
  .apartment-info__col--short .info-item--price {
    justify-content: flex-end;
  }
}
.apartment-info__col--short .icon-price-down {
  display: block;
  padding-bottom: 14px;
}
.apartment-info__col--short .icon-price-down svg {
  width: 24px;
  height: 24px;
}
@media (min-width: 768px) {
  .apartment-info__col--short .icon-price-down {
    display: none;
  }
}
.apartment-info__col--large {
  width: 100%;
}
@media (min-width: 768px) {
  .apartment-info__col--large {
    width: 70%;
  }
  .apartment-info__col--large .info-item--price-rub {
    padding-left: 20px;
  }
}
.apartment-info__col--large .icon-price-down {
  display: none;
  padding-bottom: 2px;
  margin-left: 8px;
}
@media (min-width: 768px) {
  .apartment-info__col--large .icon-price-down {
    display: block;
  }
  .apartment-info__col--large .icon-price-down svg {
    width: 20px;
    height: 20px;
  }
}
.full-width {
  width: 220px;
  position: relative;
  z-index: 10;
}
.price-down,
.repaire-icon {
  display: inline-block;
  position: relative;
}
.hidden-rub,
.hidden-usd {
  display: inline-block;
}
@media (min-width: 768px) {
  .hidden-rub,
  .hidden-usd {
    display: none;
  }
}
.repaire-icon:after {
  position: absolute;
  content: "";
  right: 0px;
  top: 50%;
  transform: translate(150%, -50%);
  height: 22px;
  width: 15px;
  background: url(../../img/icons/icon-brush.svg);
  background-position: center;
  background-repeat: no-repeat;
}
.visible-usd,
.visible-rub {
  display: none;
}
@media (min-width: 768px) {
  .visible-usd,
  .visible-rub {
    display: inline-block;
  }
}
.price-down {
  color: #d15656;
}
.apartment-table {
  position: relative;
  margin-bottom: 32px;
}
@media (min-width: 768px) {
  .apartment-table {
    overflow-x: auto;
  }
}
@media (min-width: 768px) {
  .container.container--table {
    max-width: 96%;
  }
}
@media (min-width: 1152px) {
  .container.container--table {
    max-width: 1064px ;
  }
}
@media (min-width: 1200px) {
  .container.container--table {
    max-width: 1140px ;
  }
}
@media (min-width: 1350px) {
  .container.container--table {
    max-width: 1320px ;
  }
}
@media (min-width: 768px) {
  .apartment-table .contact-phone {
    position: relative;
    left: -10px;
  }
  .column-item {
    width: 70%;
  }
  .column-item--tablet {
    width: 30%;
  }
  .apartment-info__col {
    width: 59%;
  }
  .apartment-info__col--tablet {
    width: 41%;
  }
  .apartment-info__price-rub--tablet {
    width: 100%;
  }
  .info-item.info-item--floor {
    min-width: 65px;
  }
  .info-item--repair,
  .apartment-info__col--short {
    font-size: 0;
    width: 0;
  }
  .info-item.info-item--price-meter {
    display: none;
  }
}
@media (min-width: 1152px) {
  .column-item {
    width: 55%;
  }
  .column-item--tablet {
    width: 45%;
  }
  .info-item.info-item--price-meter {
    width: 43%;
    display: block;
  }
  .apartment-info__price-rub--tablet {
    width: 100%;
  }
}
@media (min-width: 1350px) {
  .apartment-table .contact-phone {
    position: relative;
    left: 0px;
  }
  .apartment-info__col {
    width: 100% ;
  }
  .apartment-info__col--tablet {
    width: 50%;
  }
  .info-item {
    min-width: 105px;
  }
  .column-item {
    width: 50%;
  }
  .column-item--tablet {
    width: 50%;
  }
  .info-item--repair,
  .apartment-info__col--short {
    font-size: 14px;
    width: auto;
  }
}
@media (min-width: 1200px) {
  .appartment-card__row--description {
    padding-bottom: 4px;
  }
}
.title-block-wrapper {
  justify-content: space-between;
  align-items: center;
  padding-top: 32px;
  padding-bottom: 24px;
}
.title-block-wrapper .text-info {
  flex-direction: column;
}
@media (min-width: 768px) {
  .title-block-wrapper .text-info {
    padding-top: 14px;
  }
}
@media (min-width: 1200px) {
  .title-block-wrapper .text-info {
    padding-top: 0px;
  }
}
.title-block-wrapper .metro-station:last-child .last-text--comma {
  display: none;
}
.title-block--left {
  width: 100%;
}
@media (min-width: 768px) {
  .title-block--left {
    width: 75%;
  }
}
@media (min-width: 1320px) {
  .title-block--left {
    width: 70%;
  }
}
.main-title-block {
  position: relative;
  flex-direction: column;
  padding-bottom: 1.2rem;
}
@media (min-width: 1200px) {
  .main-title-block {
    flex-direction: row;
    align-items: baseline;
  }
}
.main-title-block .span-title {
  padding-right: 16px;
  color: #334860;
}
@media (min-width: 1200px) {
  .main-title-block .span-title {
    padding-left: 20px;
  }
}
.main-title-block .span-details {
  color: #999;
}
.main-title-block .title-1 {
  padding-bottom: 12px;
}
@media (min-width: 1200px) {
  .main-title-block .title-1 {
    padding-bottom: 0px;
  }
}
.main-title-block .subtitle {
  font-size: 1rem;
}
.main-title-block .price-badge {
  display: block;
  border-radius: 0 5px 5px 0;
  background-color: #fff;
  padding-left: 24px;
  padding-top: 11px;
  padding-bottom: 12px;
  width: 295px;
  position: relative;
  left: -22px;
  margin-top: 18px;
}
@media (min-width: 480px) {
  .main-title-block .price-badge {
    left: auto;
  }
}
@media (min-width: 768px) {
  .main-title-block .price-badge {
    display: none;
  }
}
.main-badge-block {
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}
.main-badge-block .link-block {
  padding-right: 0;
}
.main-badge-block .badge-group {
  display: none;
  align-items: center;
}
@media (min-width: 768px) {
  .main-badge-block .badge-group {
    display: flex;
  }
}
.main-badge-block .badge-group .badge {
  margin-right: 12px;
}
.main-badge-block .link-group {
  font-size: 16px;
  line-height: 1.7;
}
@media (min-width: 1200px) {
  .main-badge-block .link-group {
    font-size: 14px;
    padding-right: 24px;
    line-height: 1.7;
  }
}
@media (min-width: 1350px) {
  .main-badge-block .link-group {
    font-size: 14px;
    padding-right: 32px;
    line-height: 1.7;
  }
}
.main-badge-block .link-group .link-hidden {
  display: inline-block;
  padding-right: 7px;
}
@media (min-width: 768px) {
  .main-badge-block .link-group .link-hidden {
    display: none;
  }
}
.main-badge-block .metro-station {
  margin-top: 12px;
}
@media (min-width: 768px) {
  .main-badge-block .metro-station {
    margin-top: 0;
  }
}
.title-block--right {
  width: 25%;
  text-align: right;
}
@media (min-width: 992px) {
  .title-block--right {
    width: 30%;
  }
}
@media (min-width: 1350px) {
  .title-block--right {
    width: 25%;
  }
}
.title-block--right .update-box {
  display: none;
  padding-bottom: 16px;
  color: #999;
}
@media (min-width: 768px) {
  .title-block--right .update-box {
    display: block;
  }
}
.price-box {
  display: none;
}
@media (min-width: 768px) {
  .price-box {
    font-size: 18px;
    display: block;
  }
}
@media (min-width: 992px) {
  .price-box {
    font-size: 20px;
  }
}
.option-panel-wrapper {
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 30px;
}
@media (min-width: 992px) {
  .option-panel-wrapper {
    width: 965px;
  }
}
@media (min-width: 1200px) {
  .option-panel-wrapper {
    width: 1170px;
  }
}
@media (min-width: 1350px) {
  .option-panel-wrapper {
    width: 1330px;
  }
}
.option-panel {
  position: relative;
  display: flex;
  align-items: center;
  height: 50px;
  border-radius: 5px;
  border: 1px solid #e5e9f2;
  padding-left: 20px;
  padding-right: 20px;
  background-color: transparent;
}
.option-panel .icon-add svg {
  width: 16px;
}
.order-item {
  margin-left: auto;
  padding-right: 0;
}
.order-item .link-block__text {
  order: 1;
}
.order-item .link-block__icon {
  padding-left: 10px;
  padding-right: 0;
  order: 2;
}
.icon-heart--small .icon-heart path {
  fill: #d15656;
}
.icon-heart--small .fa-heart {
  width: 19px;
  height: 17px;
}
.dropdown {
  display: none;
  position: relative;
  left: 0px;
  right: 0px;
  padding: 0px;
  border-radius: 0 0 5px 5px;
  z-index: 10;
  background-color: #f5f6fa;
  border: 1px solid #e5e9f2;
  border-top: 0px;
  margin-top: -6px;
  padding-bottom: 15px;
}
.dropdown .dropdown-item {
  width: 100%;
  padding: 10px 20px;
  white-space: unset;
}
.dropdown .dropdown-item .link-block--pl,
.dropdown .dropdown-item .order-item {
  margin-left: 28px;
}
.dropdown .link-block__text--line {
  margin-left: 28px;
}
.option-panel--desktop {
  display: none;
}
@media (min-width: 1200px) {
  .option-panel--desktop {
    display: flex;
  }
}
.option-panel--mobile {
  display: flex;
}
@media (min-width: 1200px) {
  .option-panel--mobile {
    display: none;
  }
}
.icon-down--rotate {
  transform: rotate(180deg) translate(-25%);
}
.dropdown-item:hover {
  background-color: #2c81d1;
}
.dropdown-item:hover .simple-link span {
  color: #fff;
  background: linear-gradient(to right, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2) 60%, transparent 60%);
  background-position: 0 100%;
  background-repeat: repeat-x;
  background-size: 5px 1px;
}
.dropdown-item:hover a {
  color: #fff;
  border-bottom-color: #fff;
}
.dropdown-item:hover .icon-add path,
.dropdown-item:hover .icon-question path,
.dropdown-item:hover .icon-notify path {
  fill: #fff;
}
.dropdown-item:hover .watch-announcements .simple-link {
  color: #fff;
}
.dropdown-item:hover .watch-announcements--active .simple-link {
  color: #fff;
}
.dropdown-item:hover .watch-announcements--active .icon-notify path {
  fill: #d15656;
}
.option-panel__modal-block {
  display: none;
  z-index: 20;
  position: absolute;
  bottom: 0;
  right: 0;
  transform: translateY(119%);
  border-radius: 5px;
  padding: 12px;
  background-color: #334860;
}
.option-panel__modal-block a {
  color: #fff;
  display: inline-block;
  white-space: nowrap;
  border-bottom-color: rgba(255, 255, 255, 0.4);
}
.option-panel__modal-block a:first-child {
  margin-bottom: 12px;
}
.option-panel__modal-block:after {
  content: '';
  width: 0;
  height: 0;
  border: 10px solid #334860;
  border-top: 0px;
  border-left-color: transparent;
  border-right-color: transparent;
  position: absolute;
  top: -8px;
  right: 18px;
}
.option-panel__modal {
  cursor: pointer;
  padding-right: 15px;
  position: relative;
}
.option-panel__modal-block--active {
  display: block;
}
.block-item--note {
  position: relative;
}
.write-note-textarea {
  display: none;
  width: 100%;
  height: 160px;
  color: #000;
  border-color: #e5e9f2;
  border-radius: 5px;
  background-color: #fff;
  padding: 12px;
  margin-bottom: 25px;
  resize: none;
  transition: 1s;
}
@media (min-width: 768px) {
  .write-note-textarea {
    height: 70px;
  }
}
@media (min-width: 1200px) {
  .write-note-textarea {
    padding: 20px;
  }
}
.write-note-textarea:focus {
  outline: none !important;
  border-color: #2d82d1;
}
.simple-input {
  border: 1px solid #e5e9f2;
  color: #000;
  border-radius: 5px;
  background-color: #fff;
  padding: 12px;
  margin-bottom: 25px;
  width: 100%;
  font-weight: bold;
  height: 50px;
}
.simple-inputa:focus {
  outline: none !important;
  border-color: #2d82d1;
}
.block-item--note {
  padding-right: 25px;
}
.block-item--note .icon-add {
  margin-right: 10px;
}
@media (min-width: 1350px) {
  .block-item--note {
    min-width: 185px;
  }
}
.icon-add.icon-add--active .icon-add--path {
  fill: #d15656 !important;
}
@media (min-width: 1200px) {
  .icon-add.icon-add--active + .text-link {
    color: #325b82;
    border-bottom-color: #325b82;
  }
}
.avatar {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  border: 3px solid #e2e2e2e2;
  margin: 1rem 0;
  display: inline;
}
.full-description {
  padding-bottom: 24px;
}
.hc-description {
  font-size: 16px;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 30px;
}
@media (min-width: 1350px) {
  .hc-description {
    font-size: 16px;
    padding-left: 25px;
    padding-right: 0;
    padding-top: 10px;
  }
}
.hc-description .big-badge {
  position: relative;
  left: -25px;
  padding-left: 27px;
}
@media (min-width: 576px) {
  .hc-description .big-badge {
    left: auto;
  }
}
.hc-description-column {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.hc-description-column--area {
  margin-bottom: 25px;
}
.hc-description-item--align {
  align-items: flex-end;
  height: 100%;
  justify-content: center;
}
@media (min-width: 768px) {
  .hc-description-item--align {
    align-items: center;
    justify-content: flex-start;
    height: auto;
  }
}
@media (min-width: 992px) {
  .hc-description-item--align {
    align-items: flex-end;
    text-align: left;
  }
}
@media (min-width: 1350px) {
  .hc-description-item--align {
    align-items: center;
  }
}
.hc-description-item {
  display: flex;
  flex-direction: column;
}
@media (min-width: 768px) {
  .hc-description-item--btn {
    align-items: flex-end;
    justify-content: center;
  }
}
.hc-description-item--btn .big-badge {
  top: -12px;
}
.hc-description__center-item {
  padding-bottom: 28px;
}
.hc-description__column {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
@media (min-width: 768px) {
  .headline-body--pl {
    padding-left: 10px;
  }
}
@media (min-width: 992px) {
  .headline-body--pl {
    padding-left: 0px;
  }
}
@media (min-width: 768px) {
  .text-body--pr {
    padding-right: 10px;
  }
}
@media (min-width: 992px) {
  .text-body--pr {
    padding-right: 0px;
  }
}
@media (min-width: 1350px) {
  .text-body--pr {
    padding-right: 30px;
  }
}
.hc-description__top {
  padding-bottom: 20px;
}
.col-pl-0 {
  padding-left: 0;
}
@media (min-width: 768px) {
  .col-pl-0 {
    padding-left: 12px;
  }
}
.hc-description__footer {
  margin-left: -16px;
}
.hc-description__footer .footer-links-item {
  font-size: 14px;
}
.hc-description__footer-links {
  padding-top: 4px;
  display: flex;
  flex-direction: column;
}
.hc-description__footer-links .simple-link {
  margin-bottom: 12px;
}
@media (min-width: 576px) {
  .hc-description__footer-links {
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }
}
/**.hc-description__footer-content{
	display: none;
	@media(min-width: 768px){
		display: block;
	}
}**/
.description__visible-text {
  position: relative;
}
.description__hidden-text {
  display: none;
}
.hc-card {
  margin-bottom: 20px;
  padding: 0;
  min-height: 400px;
  max-width: 320px;
  padding-left: ;
  box-shadow: 0px 10px 40px 0px rgba(255, 255, 255, 0.2);
  border-radius: 5px;
  background-color: #fff;
  margin-left: auto;
  margin-right: auto;
}
@media (min-width: 1200px) {
  .hc-card {
    margin-bottom: 32px;
  }
}
.hc-card .headline-body {
  padding-bottom: 1.5px;
}
.hc-card .hc-card-body {
  padding: 16px;
}
.hc-card .simple-link--hc-card {
  border-bottom-style: solid;
  border-bottom-color: #d9e8f6;
}
.hc-card .hc-card-body__address {
  padding-top: 12px;
  padding-bottom: 6px;
}
.hc-card .hc-card-body__data {
  color: #7f7f7f;
}
.hc-card .hc-card-body__price {
  padding-top: 16px;
  font-weight: 700;
}
.hc-card .badgesline {
  position: absolute;
  left: 0.7em;
  top: 0.7em;
}
.hc-card .card-badge {
  position: relative;
  display: table;
  margin-bottom: 4px;
  padding-left: 10px;
  padding-right: 10px;
  height: 24px;
  padding-top: 1px;
  color: #fff;
  border-radius: 5px;
  text-align: center;
  background-color: #41ae1b;
  opacity: 0.85;
}
.footer {
  padding-top: 66px;
  height: 207px;
  position: relative;
  padding-left: 17px;
}
@media (min-width: 768px) {
  .footer {
    padding-left: 0px;
  }
}
@media (min-width: 1200px) {
  .footer {
    height: 270px;
    overflow: hidden;
  }
}
@media (min-width: 1350px) {
  .footer {
    flex-direction: row;
    align-items: center;
    height: 207px;
  }
}
.footer .footer-top {
  background: transparent;
  position: relative;
  z-index: 2;
  padding-bottom: 2.2rem;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}
@media (min-width: 992px) {
  .footer .footer-top {
    flex-direction: row;
    align-items: center;
  }
}
@media (min-width: 1350px) {
  .footer .footer-top {
    justify-content: flex-start;
  }
}
.footer .simple-link {
  border-bottom: 1px solid rgba(44, 129, 209, 0.2);
  margin-right: 2rem;
  padding-top: 1rem;
}
@media (min-width: 992px) {
  .footer .simple-link {
    padding-top: 0px;
  }
}
@media (min-width: 992px) and (min-width: 1350px) {
  .footer .simple-link {
    margin-right: 38px;
  }
}
.footer .links-block {
  width: 300px;
  flex-wrap: wrap;
  margin-bottom: 2.2rem;
}
@media (min-width: 480px) {
  .footer .links-block {
    width: auto;
  }
}
@media (min-width: 992px) {
  .footer .links-block {
    margin-bottom: 0;
  }
}
@media (min-width: 1350px) {
  .footer .button-block {
    margin-left: auto;
  }
}
.footer .logoblock {
  align-items: flex-start;
  flex-direction: column;
  padding-bottom: 20px;
}
@media (min-width: 1350px) {
  .footer .logoblock {
    align-items: center;
    flex-direction: row;
    margin-right: 15.2em;
    padding-bottom: 0;
  }
}
.footer .logoblock .logo-text {
  width: 270px;
  font-size: 16px;
  display: block;
}
@media (min-width: 768px) {
  .footer .logoblock .logo-text {
    font-size: 14px;
  }
}
@media (min-width: 1350px) {
  .footer .logoblock .logo-text {
    width: 210px;
  }
}
.footer .logoblock .logo-icon {
  margin-bottom: 12px;
}
@media (min-width: 1350px) {
  .footer .logoblock .logo-icon {
    margin-bottom: 0;
  }
}
.footer-bottom {
  flex-direction: column;
  background: transparent;
  position: relative;
  z-index: 2;
  border-top: 1px solid #ced9f2;
  padding-top: 1rem;
  padding-bottom: 2.2rem;
}
@media (min-width: 992px) {
  .footer-bottom {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}
.footer-bottom .simple-link {
  display: inline-block;
  order: 1;
  margin-right: 0;
  margin-bottom: 22px;
}
@media (min-width: 992px) {
  .footer-bottom .simple-link {
    order: 2;
    margin-bottom: 0;
  }
}
.footer-bottom__copyright {
  color: #999;
  order: 2;
  font-size: 16px;
}
@media (min-width: 992px) {
  .footer-bottom__copyright {
    order: 1;
  }
}
@media (min-width: 768px) {
  .footer-bottom__copyright {
    font-size: 14px;
  }
}
.footer-bottom__politic {
  order: 1;
}
@media (min-width: 992px) {
  .footer-bottom__politic {
    order: 2;
  }
}
.realty-cat-img {
  display: none;
  position: absolute;
  bottom: -73px;
  left: 50%;
  transform: translateX(-50%) translateX(30px);
}
.realty-cat-img svg {
  width: 230px;
}
@media (min-width: 1200px) {
  .realty-cat-img {
    display: block;
    transform: translate(-32%, -10%);
  }
}
@media (min-width: 1350px) {
  .realty-cat-img {
    transform: translateX(-50%) translateX(22px);
  }
}
.section-cards-wrapper {
  position: relative;
}
.vertical-border {
  display: none;
  width: 5px;
  height: auto;
  background-color: #2d82d1;
  position: absolute;
  left: -15px;
  top: 20px;
  bottom: 20px;
}
.vertical-border.active {
  display: block;
}
.appartment-card {
  display: none;
  position: relative;
  border: 1px solid #e5e9f2;
  background-color: #fff;
  border-radius: 10px;
  padding: 20px ;
  margin-bottom: 24px;
  min-height: 250px;
}
.appartment-card:last-child {
  margin-bottom: 0px;
}
@media (min-width: 768px) {
  .appartment-card {
    display: flex;
  }
}
@media (min-width: 1350px) {
  .appartment-card {
    padding: 20px 30px 28px 20px;
  }
}
.appartment-card .appartment-photo {
  background-color: #f5f6fa;
  position: relative;
  z-index: 2;
}
.appartment-card .badge-group {
  display: flex;
}
.appartment-card .badge-group .badge:not(:last-child) {
  margin-right: 9px;
}
.appartment-card .text-part:not(:last-child) {
  padding-bottom: 7px;
}
@media (min-width: 768px) {
  .appartment-card__img--empty {
    position: relative;
    height: 100%;
    min-width: 240px;
    min-height: 268px;
    background: url(../../img/appartments/cat-placeholder.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 5px;
  }
  .appartment-card__img--empty:after {
    content: "Объект без фото";
    position: absolute;
    left: 0;
    right: 0;
    top: 32px;
    text-align: center;
    font-size: 14px;
    color: #999;
  }
}
.appartment-photo {
  display: none;
}
@media (min-width: 768px) {
  .appartment-photo {
    display: block;
  }
}
.hc-photo-mobile {
  display: block;
}
@media (min-width: 768px) {
  .hc-photo-mobile {
    display: none ;
  }
}
.hc-photo-mobile .carousel-item img {
  border-radius: 5px;
}
.appartment__main-photo {
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  position: relative;
  margin-bottom: 8px;
}
.appartment__main-photo img {
  margin: auto;
  max-height: 100%;
  max-width: 100%;
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
@media (min-width: 768px) {
  .appartment__main-photo {
    width: 180px;
    height: 150px;
    margin-bottom: 10px;
  }
}
@media (min-width: 1200px) {
  .appartment__main-photo {
    width: 240px;
    height: 200px;
  }
}
@media (min-width: 1350px) {
  .appartment__main-photo {
    width: 240px;
    height: 200px;
  }
}
.appartment__thumbnails {
  display: none;
  justify-content: space-between;
  cursor: pointer;
}
@media (min-width: 768px) {
  .appartment__thumbnails {
    display: flex;
    width: 180px;
    margin-left: auto;
    margin-right: auto;
  }
}
@media (min-width: 1200px) {
  .appartment__thumbnails {
    width: 240px;
  }
}
@media (min-width: 1350px) {
  .appartment__thumbnails {
    width: 240px;
  }
}
.appartment-photo .thumbnails__image--appartment {
  width: 57px;
  height: 47px;
  overflow: hidden;
  position: relative;
  border-radius: 3px;
}
.appartment-photo .thumbnails__image--appartment:last-child {
  margin-right: 0;
}
@media (min-width: 1200px) {
  .appartment-photo .thumbnails__image--appartment {
    width: 77px;
    height: 66px;
  }
}
@media (min-width: 1350px) {
  .appartment-photo .thumbnails__image--appartment {
    width: 77px;
    height: 66px;
  }
}
.appartment-photo .thumbnails__image--appartment img {
  max-height: 100%;
  max-width: 100%;
  display: block;
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}
.appartment-photo .thumbnails__image--appartment:last-child:after {
  display: none;
}
.appartment-card__content {
  display: none;
  width: 100%;
  height: 100%;
  padding-left: 19px;
  min-height: 268px;
  flex-direction: column;
  justify-content: space-between;
}
@media (min-width: 768px) {
  .appartment-card__content {
    display: flex;
  }
}
@media (min-width: 1200px) {
  .appartment-card__content {
    min-height: 268px;
  }
}
.appartment-card__row {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding-left: 10px;
  padding-bottom: 10px;
}
@media (min-width: 992px) {
  .appartment-card__row {
    padding-bottom: 16px;
  }
}
@media (min-width: 1200px) {
  .appartment-card__row {
    padding-bottom: 10px;
  }
}
.appartment-card__row--header {
  flex-direction: column;
  align-items: flex-start;
}
@media (min-width: 1200px) {
  .appartment-card__row--header {
    flex-direction: row;
    align-items: center;
  }
}
.appartment-card__title {
  padding-right: 25px;
  padding-bottom: 14px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
@media (min-width: 992px) {
  .appartment-card__title {
    padding-bottom: 20px;
  }
}
@media (min-width: 1200px) {
  .appartment-card__title {
    padding-bottom: 0px;
  }
}
.appartment-card__price {
  display: flex;
  align-items: center;
}
.appartment-card__price--num {
  padding-right: 28px;
}
.appartment-card__price-block {
  padding-right: 28px;
  align-items: center;
}
.appartment-card__price-block .appartment-card__price--num {
  color: #d15656;
  padding-right: 8px;
}
.appartment-card__price--area {
  position: relative;
  display: block;
}
@media (min-width: 1200px) {
  .appartment-card__price--area {
    display: block;
  }
}
.appartment-card__price--area .icon-brach.icon-brach--hidden {
  position: absolute;
  right: -35px;
  bottom: 0px;
  display: block;
}
@media (min-width: 992px) {
  .appartment-card__price--area .icon-brach.icon-brach--hidden {
    display: none;
  }
}
.appartment-card__row .repaire-item {
  display: none;
}
@media (min-width: 992px) {
  .appartment-card__row .repaire-item {
    display: block;
  }
}
.appartment-card__row .icon-brach {
  display: none;
}
@media (min-width: 992px) {
  .appartment-card__row .icon-brach {
    display: block;
  }
}
.appartment-card__row .badge--dark {
  position: relative;
  left: -10px;
  margin-right: 9px;
}
.appartment-card__row .metro-block {
  padding-top: 10px;
}
@media (min-width: 992px) {
  .appartment-card__row .metro-block {
    padding-top: 0px;
  }
}
.appartment-card__row--transform {
  align-items: flex-start;
  flex-direction: column;
  padding-bottom: 0;
}
@media (min-width: 992px) {
  .appartment-card__row--transform {
    align-items: center;
    flex-direction: row;
    padding-bottom: 6px;
  }
}
.appartment-card__row-col {
  align-items: center;
}
.appartment-page-link {
  display: none;
  cursor: pointer;
  align-items: center;
  position: absolute;
  top: 20px;
  right: 20px;
}
@media (min-width: 768px) {
  .appartment-page-link {
    display: flex;
  }
}
@media (min-width: 1350px) {
  .appartment-page-link {
    right: 30px;
  }
}
.appartment-page-link .share-link-text {
  color: #2c81d1;
  padding-right: 10px;
  display: none;
}
@media (min-width: 1350px) {
  .appartment-page-link .share-link-text {
    display: block;
  }
}
.appartment-page-link .share-link {
  position: relative;
  top: -3px;
}
.appartment-page-link:hover .share-link svg path,
.appartment-page-link:active .share-link svg path {
  fill: #d15656;
}
.appartment-page-link:hover .share-link-text,
.appartment-page-link:active .share-link-text {
  color: #d15656;
}
.appartment-page-link:hover .tooltip-box,
.appartment-page-link:active .tooltip-box {
  display: block;
}
.appartment--built {
  padding-right: 28px;
  display: none;
}
@media (min-width: 992px) {
  .appartment--built {
    display: block;
  }
}
.appartment-card--link-title {
  padding-right: 28px;
}
@media (min-width: 992px) {
  .appartment-card--link-title {
    padding-right: 9px;
  }
}
.appartment-card--address {
  margin-left: 10px;
}
@media (min-width: 992px) {
  .appartment-card--address {
    margin-right: 28px;
  }
}
.appartment-card__text-box {
  width: 99%;
}
.appartment-card__text-box p {
  padding: 0;
  margin: 0;
  line-height: 1.3;
  padding-bottom: 2px;
}
@media (min-width: 1200px) {
  .appartment-card__text-box {
    width: 90%;
  }
}
@media (min-width: 1350px) {
  .appartment-card__text-box {
    width: 800px;
  }
}
.appartment-card__toolbar-item {
  padding-right: 28px;
}
.appartment-card__toolbar-item .link-block {
  padding-right: 0;
}
.appartment-card__toolbar-item .icon-heart--tablet {
  display: flex;
}
@media (min-width: 1350px) {
  .appartment-card__toolbar-item .icon-heart--tablet {
    display: none;
  }
}
.appartment-card__toolbar-item .icon-heart--tablet .simple-link {
  display: none;
}
.appartment-card__toolbar-item .icon-heart--tablet path {
  fill: red;
}
.appartment-card__toolbar-item .icon-heart--tablet svg {
  width: 24px;
}
.appartment-card__toolbar-item .icon-heart--tablet .link-block__icon {
  padding-right: 0;
}
.appartment-card__toolbar-item .icon-heart--desk {
  display: none;
}
@media (min-width: 1350px) {
  .appartment-card__toolbar-item .icon-heart--desk {
    display: flex;
  }
}
.appartment-card__toolbar-item--mail .text-link {
  display: none;
}
@media (min-width: 1054px) {
  .appartment-card__toolbar-item--mail .text-link {
    display: block;
  }
}
.appartment-card__toolbar-item--mail .icon-mail {
  display: block;
  margin-right: 0;
}
@media (min-width: 1054px) {
  .appartment-card__toolbar-item--mail .icon-mail {
    display: none;
  }
}
.appartment-card__toolbar-item--pdf {
  padding-right: 0;
}
.appartment-publication {
  position: absolute;
  right: 30px;
  bottom: 28px;
  display: none;
}
@media (min-width: 1350px) {
  .appartment-publication {
    display: block;
  }
}
.appartment-card__row--description {
  display: none;
}
@media (min-width: 1200px) {
  .appartment-card__row--description {
    display: block;
  }
}
.appartment-card__row--toolbar {
  padding-bottom: 0;
}
@media (min-width: 768px) {
  .appartment-card__row--toolbar {
    padding-top: 8px;
  }
}
@media (min-width: 1200px) {
  .appartment-card__row--toolbar {
    padding-top: 12px;
  }
}
.appartment-card__row--badges {
  padding-bottom: 0;
}
.appartment-card__row--badges .badge-group {
  padding-bottom: 4px;
}
.appartment-card__row--badges .appartment-card--address {
  margin-bottom: 4px;
}
.appartment-card__row--badges .repaire-item {
  padding-bottom: 4px;
}
.fotorama__img {
  border-radius: 5px !important;
}
.logo-house {
  max-width: 100%;
}
.tooltip-box {
  width: 325px;
  height: 115px;
  position: absolute;
  border-radius: 10px;
  padding: 10px 20px 20px 10px;
  color: #fff;
  font-size: 14px;
  background-color: #334860;
  top: -8px;
  transform: translateY(-110%);
  right: -35px;
  z-index: 20;
  display: none;
}
.tooltip-box input {
  height: 30px;
  margin-top: 8px;
}
.tooltip-box .input-block {
  padding-bottom: 8px;
}
.tooltip-box .tooltip__links {
  padding-top: 6px;
  align-items: center;
  justify-content: space-between;
}
.tooltip-box .tooltip__links .text-link {
  border-bottom-color: rgba(255, 255, 255, 0.2);
  color: #fff;
}
.tooltip-box:after {
  content: "";
  position: absolute;
  width: 0px;
  height: 0px;
  border: 10px solid #334860;
  border-bottom-width: 0px;
  border-left-color: transparent;
  border-right-color: transparent;
  bottom: -7px;
  right: 33px;
}
.tooltip-box--visible {
  display: block;
}
.tooltip__input {
  position: relative;
}
.tooltip__input .icon-copy {
  position: absolute;
  right: 10px;
  top: 59%;
  transform: translateY(-50%);
}
.pagination {
  display: none;
}
@media (min-width: 768px) {
  .pagination {
    display: inline-flex;
  }
}
.pagination-item {
  font-weight: 500;
  display: inline-block;
  width: 30px;
  height: 30px;
  border-radius: 3px;
  transition: background-color 0.5s;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 9px;
}
.pagination-item:hover {
  color: #000;
}
.pagination-item:last-child {
  margin-right: 0;
}
.pagination-item--active {
  border: 1px solid #e5e9f2;
  background-color: #fff;
  color: #000;
}
.appartment-card--mobile {
  display: block;
}
@media (min-width: 768px) {
  .appartment-card--mobile {
    display: none;
  }
}
.appartment-card--mobile .appartment-card__img {
  width: 110%;
  left: -16px;
  right: -16px;
  position: relative;
  min-height: 145px;
  background-color: #f8f8f8;
}
@media (min-width: 420px) {
  .appartment-card--mobile .appartment-card__img {
    width: 109%;
  }
}
@media (min-width: 475px) {
  .appartment-card--mobile .appartment-card__img {
    width: 108%;
    min-height: 175px;
  }
}
@media (min-width: 500px) {
  .appartment-card--mobile .appartment-card__img {
    width: 107%;
  }
}
.appartment-card--mobile .appartment-card__img:after {
  position: absolute;
  content: "Объект без фото";
  left: 0;
  right: 0;
  top: 20px;
  text-align: center;
}
.appartment-card--mobile .hc-photo-mobile {
  background-color: #fff;
  position: relative;
  z-index: 2;
}
.appartment-content--mobile-row {
  position: relative;
}
.appartment-content--mobile .photo-slider {
  height: 195px;
}
.appartment-content--mobile .appartment-content--mobile-row {
  padding-bottom: 10px;
}
.appartment-content--mobile .appartment-card__price--usd {
  font-size: 16px;
  font-weight: 700;
  color: #000;
}
.appartment-content--mobile .icon-price-down {
  padding-left: 8px;
}
.appartment-content--mobile .appartment-card__price--num {
  padding-right: 0px;
  font-size: 20px;
}
.appartment-content--mobile .appartment-card__price--area {
  padding-left: 24px;
}
.appartment-content--mobile .link-block .simple-link {
  display: none;
}
.appartment-content--mobile .appartment-card__toolbar-icons {
  padding-left: 70px;
  align-items: center;
}
.appartment-content--mobile .appartment-card__toolbar-icons .icon-heart path {
  fill: #d15656;
}
.appartment-content--mobile .appartment-card__toolbar-item {
  padding-right: 0;
}
.appartment-content--mobile .btn-tel {
  line-height: 1.7;
}
.appartment-card__toolbar {
  display: flex;
  align-items: center;
}
.appartment-card__toolbar .appartment-card__toolbar-item {
  align-items: center;
  display: flex;
}
.appartment-content--mobile-row.mobile-row--price {
  padding-bottom: 20px;
}
.cat-placeholder {
  margin-right: auto;
  margin-left: auto;
}
.content-block {
  width: 320px;
}
@media (min-width: 360px) {
  .content-block {
    width: 100%;
  }
}
.ch-block {
  width: 100%;
  display: block;
}
@media (min-width: 768px) {
  .ch-block {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 14px;
  }
}
.message-hidden-show {
  display: flex;
  padding-bottom: 35px;
  cursor: pointer;
  padding-right: 10px;
}
@media (min-width: 768px) {
  .message-hidden-show {
    padding-right: 0;
    display: inline-flex;
  }
}
.message-hidden-show__title span.desk {
  padding-right: 9px;
  display: none;
}
@media (min-width: 768px) {
  .message-hidden-show__title span.desk {
    display: block;
  }
}
.message-hidden-show__title span.mb {
  display: block;
}
@media (min-width: 768px) {
  .message-hidden-show__title span.mb {
    display: none;
  }
}
.message-hidden-show__subtitle {
  display: flex;
  text-align: center;
  margin-left: auto;
}
@media (min-width: 992px) {
  .message-hidden-show__subtitle {
    margin-left: 7px;
  }
}
.message-hidden-show__subtitle span {
  color: #838385;
}
.message-hidden-show__subtitle span.first {
  display: none;
}
@media (min-width: 768px) {
  .message-hidden-show__subtitle span.first {
    display: block;
  }
}
.message-hidden-show__subtitle span.mob {
  font-weight: 700;
  margin: auto;
}
@media (min-width: 768px) {
  .message-hidden-show__subtitle span.mob {
    padding-left: 7px;
    font-weight: 400;
  }
}
.message-block {
  min-width: 100%;
  max-width: 100%;
  display: block;
  margin-bottom: 20px;
  border-radius: 10px;
  position: relative;
}
@media (min-width: 768px) {
  .message-block {
    min-width: 345px;
    max-width: 700px;
    position: relative;
    height: 100%;
    padding-top: 12px;
    padding-bottom: 12px;
    background-color: #f2f3f7;
  }
}
.messages-block .message:last-child .message-block {
  margin-bottom: 0;
}
.message {
  display: flex;
}
.message-hidden-items {
  margin-bottom: 20px;
}
.message-top {
  display: flex;
  margin-bottom: 12px;
}
@media (min-width: 768px) {
  .message-top {
    display: flex;
    position: relative;
    z-index: 2;
    flex-direction: row;
    padding-left: 15px;
  }
}
@media (min-width: 768px) {
  .message-time {
    padding-left: 5px;
  }
}
.message-author {
  display: none;
  background: url(../../img/message/profile.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
@media (min-width: 768px) {
  .message-author {
    display: block;
    margin-right: 11px;
    border: 1px solid #e5e9f2;
    border-radius: 3px;
    min-width: 34px;
    height: 34px;
  }
}
.message-author img {
  min-width: 34px;
  background-color: #fff;
}
.message-author--mob {
  display: block;
  margin-right: 20px;
  min-width: 34px;
  height: 34px;
}
@media (min-width: 768px) {
  .message-author--mob {
    display: none;
  }
}
.message-author-name {
  display: flex;
  flex-direction: column;
}
@media (min-width: 768px) {
  .message-author-name {
    flex-direction: row;
  }
}
.comment {
  padding-right: 35px;
  font-size: 16px;
  outline: none;
  background-color: transparent;
  border-radius: 10px;
  padding-top: 12px;
  padding-bottom: 12px;
  min-width: 300px;
  white-space: pre-wrap;
}
@media (min-width: 768px) {
  .comment {
    position: relative;
    max-width: 700px;
    padding-left: 15px;
    background-color: #f2f3f7;
    z-index: 2;
    border: none;
    outline: none;
    font-size: 14px;
    border: 0px;
    cursor: text;
    padding-top: 0;
    padding-bottom: 0;
    padding-right: 45px;
  }
}
.comment:empty {
  max-height: 48px;
  border: 1px solid #e5e9f2;
  background-color: #fff;
  padding-left: 12px;
}
@media (min-width: 768px) {
  .comment:empty {
    max-height: 40px;
    border: 0px;
    background-color: transparent;
    padding-left: 15px;
  }
}
@media (min-width: 768px) {
  .message-block:before {
    z-index: 1;
    content: "";
    position: absolute;
    left: 0px;
    top: 9px;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border: 8px solid #f2f3f7;
    border-bottom: 0;
    border-right-color: transparent;
    border-left-color: transparent;
  }
}
.comment:empty:after {
  border-radius: 10px;
  border: none;
  outline: none;
  min-width: 270px;
  height: 100%;
  content: "Написать комментарий...";
  display: block;
  padding-right: 15px;
  font-size: 16px;
  margin-right: 15px;
}
@media (min-width: 768px) {
  .comment:empty:after {
    background-color: transparent;
    font-size: 14px;
    text-align: start;
    padding-top: 0;
    padding-bottom: 0;
  }
}
.hidden-comments {
  display: none;
}
.hidden-comments-show {
  display: block;
}
.ch-title-row-hidden {
  display: none;
}
.message-hidden-items {
  display: none;
}
.message-send {
  position: absolute;
  cursor: pointer;
  bottom: 11px;
  right: 16px;
  z-index: 10;
  color: #b3b3b3;
  transition: .5s;
}
.message-send svg {
  font-size: 20px;
}
.message-send:hover {
  opacity: 0.5;
}
.card-offer {
  display: block;
  border: 1px solid #e5e9f2;
  border-radius: 10px 10px 10px 10px;
  margin-top: 20px;
  margin-bottom: 20px;
}
@media (min-width: 992px) {
  .card-offer {
    display: flex;
  }
}
.card-offer .card-slider {
  position: relative;
  margin-left: -23px;
  margin-right: -29px;
  margin-bottom: 20px;
}
@media (min-width: 1200px) {
  .card-offer .card-slider {
    margin-right: 0;
    margin-left: 0;
  }
}
.card-offer .card-description {
  width: 100%;
  position: relative;
  background-color: #fff;
  border-radius: 10px 10px 10px 10px;
}
@media (min-width: 768px) {
  .card-offer .card-description {
    border-radius: 10px;
  }
}
@media (min-width: 992px) {
  .card-offer .card-description {
    border-radius: 10px 0 0 10px;
    width: 80%;
    padding: 20px;
  }
}
.card-description-top {
  padding: 10px;
  padding-top: 27px;
  padding-bottom: 0;
  border-radius: 10px 10px 10px 10px;
}
@media (min-width: 768px) {
  .card-description-top {
    padding-top: 16px;
  }
}
@media (min-width: 992px) {
  .card-description-top {
    padding-top: 0;
  }
}
.card-description-top .fadeOut:after {
  display: block;
  bottom: 60px;
  background-color: rgba(255, 255, 255, 0);
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0, #ffffff 100%);
}
@media (min-width: 768px) {
  .card-description-top .fadeOut:after {
    display: none;
  }
}
.offer-row {
  display: flex;
  align-items: center;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
}
.offer-row .appartment-card--address {
  margin-right: 15px;
}
.offer-row .badge {
  margin-right: 10px;
}
@media (min-width: 768px) {
  .offer-row .metro-block {
    padding-right: 10px;
  }
}
.offer-row .appartment-page-link {
  margin-left: auto;
}
.offer-row .appartment-page-link .share-link-text {
  display: none;
}
.offer-row .appartment-card__toolbar {
  width: 100%;
}
.offer-row--swipe {
  box-sizing: border-box;
  padding: 16px;
  padding-top: 0;
  padding-bottom: 30px;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
}
.offer-row--swipe::-webkit-scrollbar {
  display: none;
}
@media (min-width: 768px) {
  .offer-row--swipe {
    margin-bottom: 0;
    width: 93%;
    max-width: 940px;
  }
}
.offer-row--transform {
  display: block;
}
@media (min-width: 768px) {
  .offer-row--transform {
    display: flex;
  }
}
@media (min-width: 1200px) {
  .offer-row--transform {
    padding-top: 0;
  }
}
.offer-row--transform .appartment-card__price--num {
  font-size: 20px;
  padding-right: 0;
}
@media (min-width: 768px) {
  .offer-row--transform .appartment-card__price--num {
    padding-bottom: 0;
    margin: 0;
  }
}
.appartment-card__price-section {
  display: flex;
  align-items: center;
  margin-bottom: 18px;
}
.appartment-card__price-section .icon-price-down {
  display: flex;
  margin-left: 10px;
  display: inline-block;
  cursor: pointer;
}
@media (min-width: 768px) {
  .appartment-card__price-section {
    padding-right: 28px;
  }
}
.offer-row--info {
  display: block;
  position: relative;
}
.offer-row--info .text-hidden {
  display: none;
}
@media (min-width: 768px) {
  .offer-row--info .text-hidden {
    display: block;
  }
}
.show-hidden-text {
  padding-top: 12px;
}
@media (min-width: 768px) {
  .show-hidden-text {
    display: none;
    padding-top: 0;
  }
}
.offer-row--title {
  align-items: flex-start;
  flex-direction: column;
  padding-right: 25px;
}
@media (min-width: 1350px) {
  .offer-row--title {
    flex-direction: row;
  }
}
.offer-row--title .simple-link {
  margin-bottom: 17px;
  font-size: 16px;
}
@media (min-width: 768px) {
  .offer-row--title .simple-link {
    font-size: 20px;
    margin-right: 30px;
  }
}
.offer-row--title .appartment-card__price--area {
  margin-right: 30px;
}
.icons-like {
  display: flex;
  margin-left: auto;
}
@media (min-width: 768px) {
  .icons-like .icon-group {
    display: flex;
  }
}
.icons-like .group--icon-dislike {
  margin-left: 22px;
}
.row-line {
  padding-bottom: 20px;
  height: 1px;
  width: 100%;
  border-bottom: 1px solid #ced9f2;
  display: none;
}
@media (min-width: 992px) {
  .row-line {
    display: block;
  }
}
.card-description-chat {
  padding: 10px;
  padding-bottom: 20px;
  padding-top: 20px;
  background-color: #f5f6fa;
  border-radius: 0 0 10px 10px;
}
@media (min-width: 768px) {
  .card-description-chat {
    background-color: #fff;
  }
}
@media (min-width: 992px) {
  .card-description-chat {
    border-radius: 0;
  }
}
.offer-row--tools .appartment-card__toolbar {
  flex-wrap: wrap;
}
@media (min-width: 480px) {
  .offer-row--tools .appartment-card__toolbar {
    flex-wrap: nowrap;
  }
}
.offer-row--tools .appartment-card__toolbar-item--mail {
  padding-right: 0;
}
@media (min-width: 768px) {
  .offer-row--tools .appartment-card__toolbar-item--mail {
    padding-right: 28px;
  }
}
.offer-row--tools .appartment-card__toolbar-item--tel {
  margin-right: auto;
}
@media (min-width: 1200px) {
  .offer-row--tools .appartment-card__toolbar-item--tel {
    margin-right: 0;
  }
}
.offer-row--tools .appartment-card__toolbar-item--heart {
  padding-right: 20px;
}
@media (min-width: 768px) {
  .offer-row--tools .appartment-card__toolbar-item--heart {
    padding-right: 28px;
  }
}
.offer-row--tools .appartment-card__toolbar-item--btn {
  width: 100%;
  margin-bottom: 20px;
}
@media (min-width: 480px) {
  .offer-row--tools .appartment-card__toolbar-item--btn {
    padding-right: 28px;
    flex-grow: inherit;
    width: auto;
    margin-bottom: 0;
  }
}
.offer-row--tools .appartment-card__toolbar-item--btn a {
  width: 100%;
}
@media (min-width: 768px) {
  .offer-row--tools .appartment-card__toolbar-item--btn a {
    width: auto;
  }
}
.card-offer--hot .appartment-card__price--usd {
  display: block;
  color: #000;
  font-weight: 700;
  font-size: 16px;
  padding-right: 16px;
}
@media (min-width: 768px) {
  .card-offer--hot .appartment-card__price--usd {
    display: none;
  }
}
.card-offer--hot .repaire-item-box {
  display: none;
}
@media (min-width: 768px) {
  .card-offer--hot .repaire-item-box {
    display: flex;
  }
}
.card-offer--hot .appartment-card__price--area {
  margin-right: 0;
}
@media (min-width: 768px) {
  .card-offer--hot .appartment-card__price--area {
    margin-right: 30px;
  }
}
.card-offer--new .mobile-price {
  display: none;
}
@media (min-width: 768px) {
  .card-offer--new .mobile-price {
    display: flex;
  }
}
.card-offer--new .appartment-card__price--area {
  display: none;
}
@media (min-width: 768px) {
  .card-offer--new .appartment-card__price--area {
    display: block;
  }
}
.card-offer--new .appartment-card__price--usd {
  display: none;
}
.mobile-price {
  display: flex;
}
.double-repaire-box .repaire-item-box {
  display: flex;
}
@media (min-width: 768px) {
  .double-repaire-box {
    display: none;
  }
}
.section-top--chat-title {
  width: 62%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-left: 15px;
}
@media (min-width: 360px) {
  .section-top--chat-title {
    width: 100%;
  }
}
@media (min-width: 768px) {
  .section-top--chat-title {
    padding-right: 19px;
    align-items: center;
  }
}
.section-top--chat-title .section-top__link {
  padding-top: 16px;
  display: flex;
  align-items: center ;
}
.section-top--chat-title .section-top__link .icon-fire {
  display: none;
  margin-right: 6px;
}
@media (min-width: 768px) {
  .section-top--chat-title .section-top__link .icon-fire {
    padding-top: 0;
    display: block;
  }
}
.section-top--chat-title .text-link {
  display: flex;
}
.section-top--chat-title .text-link .text-link--mob {
  display: none;
}
@media (min-width: 992px) {
  .section-top--chat-title .text-link .text-link--mob {
    display: block;
  }
}
.chat-title__filters {
  background-color: #dbe6ff;
  padding: 20px;
  padding-bottom: 40px;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  margin-bottom: 40px;
}
.chat-title__filters .input-block {
  position: relative;
  z-index: 2;
}
@media (min-width: 768px) {
  .chat-title__filters .input-block {
    padding-bottom: 0;
  }
}
@media (min-width: 768px) {
  .chat-title__filters {
    padding-bottom: 28px;
  }
}
.chat-title__filters-top {
  padding-bottom: 27px;
  display: inline-block;
  position: relative;
}
.chat-title__filters-title {
  font-size: 20px;
  color: #212226;
  line-height: 1.5;
  font-weight: 700;
}
.help-link {
  display: block;
  position: absolute;
  right: 0;
  top: 6px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: #fff;
  color: #2c81d1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
}
@media (min-width: 1200px) {
  .help-link {
    top: auto;
    transform: translateX(150%);
    bottom: 28px;
  }
}
.chat-title__filters-inputs {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: wrap;
}
.chat-title__filters-inputs input {
  width: 100%;
}
@media (min-width: 768px) {
  .chat-title__filters-inputs {
    flex-direction: row;
    align-items: flex-end;
  }
  .chat-title__filters-inputs input[name='appart-areas'] {
    min-width: 250px;
  }
  .chat-title__filters-inputs input[name='appart-price'] {
    min-width: 250px;
  }
  .chat-title__filters-inputs input[name='appart-address'] {
    min-width: 470px;
  }
}
@media (min-width: 992px) {
  .chat-title__filters-inputs input[name='appart-areas'] {
    min-width: 210px;
  }
  .chat-title__filters-inputs input[name='appart-price'] {
    min-width: 210px;
  }
  .chat-title__filters-inputs input[name='appart-address'] {
    min-width: 400px;
  }
}
@media (min-width: 1200px) {
  .chat-title__filters-inputs input[name='appart-areas'] {
    min-width: 200px;
  }
  .chat-title__filters-inputs input[name='appart-price'] {
    min-width: 200px;
  }
  .chat-title__filters-inputs input[name='appart-address'] {
    min-width: 440px;
  }
}
@media (min-width: 1350px) {
  .chat-title__filters-inputs input[name='appart-areas'] {
    min-width: 210px;
  }
  .chat-title__filters-inputs input[name='appart-price'] {
    min-width: 230px;
  }
  .chat-title__filters-inputs input[name='appart-address'] {
    min-width: 605px;
  }
}
.chat-title__filters-inputs .button--basic {
  z-index: 10;
}
@media (min-width: 992px) {
  .chat-title__filters-inputs .button--basic {
    margin-left: auto;
    margin-top: 20px;
  }
}
@media (min-width: 1200px) {
  .chat-title__filters-inputs .button--basic {
    margin-left: 0;
    margin-top: 0px;
  }
}
.chat-title__filters-item-title {
  font-size: 12px;
  font-weight: 700;
  color: #000;
  padding-bottom: 7px;
}
@media (min-width: 768px) {
  .chat-title__filters-item-title {
    padding-top: 12px;
  }
}
@media (min-width: 992px) {
  .chat-title__filters-item-title {
    padding-top: 0px;
  }
}
.chat-title__filters-bg {
  display: none;
  position: absolute;
  bottom: -95px;
  right: 87px;
  z-index: 1;
}
.chat-title__filters-bg svg {
  width: 175px;
}
@media (min-width: 1200px) {
  .chat-title__filters-bg {
    display: block;
  }
}
.agent-info {
  padding-top: 20px;
  padding-bottom: 25px;
  padding-left: 15px;
  padding-right: 15px;
  background-color: #e5e9f2;
  height: 100%;
}
@media (min-width: 992px) {
  .agent-info {
    border-radius: 0 10px 10px 0;
  }
}
@media (min-width: 1350px) {
  .agent-info {
    max-width: 270px;
  }
}
.agent-card {
  width: 100%;
}
@media (min-width: 768px) {
  .agent-card {
    border-radius: 0 0 10px 10px;
  }
}
@media (min-width: 992px) {
  .agent-card {
    width: 20%;
  }
}
.agent-info-content {
  display: flex;
  justify-content: space-between;
}
@media (min-width: 768px) {
  .agent-info-content {
    flex-direction: column;
    justify-content: flex-start;
  }
}
.posted-time {
  margin-bottom: 14px;
}
@media (min-width: 768px) {
  .posted-time {
    margin-bottom: 19px;
  }
}
.agent-info-text {
  max-width: 57%;
}
@media (min-width: 768px) {
  .agent-info-text {
    order: 2;
  }
}
.agent-info-img {
  width: 78px;
  height: 78px;
  border-radius: 5px;
  overflow: hidden;
}
.agent-info-img img {
  border-radius: 5px;
}
@media (min-width: 768px) {
  .agent-info-img {
    margin-bottom: 16px;
    order: 1;
  }
}
.agent-info-download {
  display: none;
  padding-top: 100px;
}
@media (min-width: 992px) {
  .agent-info-download {
    display: block;
  }
}
.download-num-item {
  position: relative;
  width: 30px;
  height: 30px;
  margin-right: 5px;
  border-radius: 5px;
}
.agent-info-download-num {
  display: none;
  position: relative;
  padding-bottom: 12px;
}
@media (min-width: 1200px) {
  .agent-info-download-num {
    display: flex;
  }
}
.download-num-item:last-child {
  padding-right: 0;
}
.download-num-item-last {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  width: 30px;
  right: 0;
  background-color: rgba(0, 0, 0, 0.4);
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
}
.agent-card-desk {
  display: none;
}
@media (min-width: 992px) {
  .agent-card-desk {
    display: block;
  }
}
.agent-card-mob {
  display: block;
}
@media (min-width: 992px) {
  .agent-card-mob {
    display: none;
  }
}
.swape-block {
  width: 300px;
  box-sizing: border-box;
  border: 1px solid #999;
  padding: 16px;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
}
.swape-block::-webkit-scrollbar {
  display: none;
}
@media (min-width: 1200px) {
  .card-price--desktop {
    position: sticky;
    top: 5px;
  }
}
.appartment-price {
  margin-bottom: 40px;
  position: relative;
}
@media (min-width: 768px) {
  .appartment-price {
    margin-top: 5px;
    margin-bottom: 25px;
    border: 1px solid #e5e9f2;
    border-radius: 5px;
    background-color: #fff;
    padding: 14px;
  }
}
@media (min-width: 1200px) {
  .appartment-price {
    position: static;
    top: 0;
    max-width: 410px;
    margin-bottom: 0;
  }
}
.appartment-price .appartment-page-link {
  display: none;
}
@media (min-width: 768px) {
  .appartment-price .appartment-page-link {
    display: block;
    top: 24px;
  }
}
.appartment-price .share-link-text {
  display: none;
}
.block-fixed {
  position: fixed;
  top: 0;
}
.appartment-price__row-title {
  display: flex;
}
@media (min-width: 768px) {
  .appartment-price__row-title {
    padding-bottom: 0;
  }
}
.appartment-price__row-title .appartment-price__title {
  position: relative;
  display: block;
  font-size: 24px;
  border: 1px solid #e5e9f2;
  background-color: #fff;
  border-radius: 0 10px 10px 0;
  padding-left: 30px;
  padding-top: 18px;
  width: 274px;
  height: 60px;
  left: -16px;
}
@media (min-width: 768px) {
  .appartment-price__row-title .appartment-price__title {
    left: 0;
    line-height: normal;
    height: auto;
    padding-left: 0;
    padding-top: 0;
    padding-bottom: 8px;
    font-size: 30px;
    border: none;
    margin-top: 0;
  }
}
.appartment-price__row-subtitle {
  display: flex;
  align-items: center;
  padding-bottom: 17px;
  padding-top: 16px;
}
@media (min-width: 768px) {
  .appartment-price__row-subtitle {
    padding-top: 0;
  }
}
.appartment-price__row-subtitle .appartment-price__usd {
  font-size: 20px;
  font-weight: 700;
  color: #000;
  padding-right: 20px;
}
.appartment-price__row-subtitle .text-body--grey {
  font-weight: 700;
}
.appartment-price__btn {
  padding-bottom: 20px;
}
@media (min-width: 768px) {
  .appartment-price__btn {
    padding-bottom: 26px;
  }
}
.appartment-price__message .appartment-price__message-title {
  display: none;
}
@media (min-width: 768px) {
  .appartment-price__message .appartment-price__message-title {
    display: block;
  }
}
.appartment-price__message .appartment-price__textarea {
  width: 100%;
  padding: 14px;
  outline: none;
  border: 1px solid #e5e9f2;
  border-radius: 5px;
  min-height: 60px;
  resize: none;
  transition: .5s;
}
.appartment-price__message .appartment-price__textarea:focus {
  border-color: #2c81d1;
  min-height: 160px;
}
@media (min-width: 768px) {
  .appartment-price__message .appartment-price__textarea {
    padding: 20px;
    min-height: 100px;
    margin-bottom: 8px;
  }
}
.appartment-price__textarea::placeholder {
  color: #aaaaaa;
}
@media (min-width: 768px) {
  .appartment-price__textarea::placeholder {
    color: transparent;
  }
}
.appartment-price__phone-block {
  margin-top: 10px;
  display: none;
  padding: 20px;
  background-color: #fff8eb;
}
.phone-block-item--flex {
  display: flex;
  justify-content: space-between;
}
@media (min-width: 1200px) {
  .phone-block-item--flex {
    flex-wrap: wrap;
  }
}
.phone-block-item--flex .text-body {
  display: none;
}
@media (min-width: 768px) {
  .phone-block-item--flex .text-body {
    display: inline-flex;
  }
}
.phone-block-item--flex .add-favorite {
  display: none;
}
@media (min-width: 1200px) {
  .phone-block-item--flex .add-favorite {
    padding-top: 14px;
  }
}
@media (min-width: 1350px) {
  .phone-block-item--flex .add-favorite {
    padding-top: 0px;
  }
}
.add-favorite.block-visible {
  display: flex;
}
.phone-block-item:first-child {
  padding-bottom: 14px;
}
.phone-block-item .agent-name {
  margin-right: 5px;
}
.price-card-form {
  position: relative;
}
.btn-send-message {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -150%);
  border: 0px;
  color: #fff;
  min-height: 40px;
  background-color: #2c81d1;
  padding-top: 2px;
  font-weight: 700;
  min-width: 230px;
  cursor: pointer;
  border-radius: 3px;
  transition: .5s;
  display: none;
}
.btn-send-message:hover {
  opacity: .8;
}
@media (min-width: 768px) {
  .btn-send-message {
    position: relative;
    transform: translate(0%, 0%);
    left: 0;
    min-width: 200px;
    min-height: 30px;
  }
}
.price-card__toolbar {
  display: none;
}
@media (min-width: 1200px) {
  .price-card__toolbar {
    position: relative;
    margin-top: 20px;
    border: 1px solid #e5e9f2;
    border-radius: 5px;
    background-color: transparent;
    padding: 16px;
  }
}
.price-card__toolbar.flex-visible {
  display: flex;
}
.price-card__toolbar-col {
  display: flex;
  flex-direction: column;
  width: 50%;
}
.price-card__toolbar-col:last-child {
  padding-left: 25px;
}
.toolbar-col__item:first-child {
  padding-bottom: 12px;
}
.price-card__toolbar-note {
  display: none;
  border: 1px solid #e5e9f2;
  border-radius: 0 0 5px 5px;
  background-color: transparent;
  padding: 16px;
  padding-top: 10px;
  position: relative;
  top: -10px;
  border-top: 0px;
}
.price-card-note {
  display: block;
  padding: 14px;
  resize: none;
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid #e5e9f2;
  border-radius: 5px;
  height: 100px;
  outline: none;
}
.price-card-note:focus {
  border: 1px solid #2c81d1;
}
@media (min-width: 768px) {
  .price-card-note {
    padding: 20px;
  }
}
.block-visible {
  display: block;
}
.broker_cabinet {
  background: #fff8eb;
  border: 1px solid #f2ebdf;
  border-radius: 10px;
  padding: 20px 5px;
  margin-top: 50px;
}
.broker_cabinet .filter-toggle {
  font-weight: bold;
}
.broker_cabinet .filter-toggle .filter-toggle-item {
  font-size: 16px;
  height: 26px;
  margin-right: 26px;
}
.broker_cabinet .card-description-chat {
  background: transparent;
}
.broker_cabinet .card-description-chat .message-block {
  background-color: #fff;
}
.broker_cabinet .card-description-chat .comment {
  background-color: #fff;
}
.broker_cabinet .card-description-chat .message-block:before {
  border: 8px solid #fff;
  border-bottom: 0;
  border-right-color: transparent;
  border-left-color: transparent;
}
.fadecab {
  height: 3em;
  position: relative;
  top: -3em;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0, #fff8eb 100%);
}
.commission {
  color: #41ae1b;
  font-size: 30px;
  font-weight: bold;
  margin-top: 45px;
  line-height: 1;
}
.externallink {
  position: relative;
  top: -3px;
  left: 3px;
}
.refreshed {
  color: #b3aea4;
}
.text-yellow-muted {
  color: #b3aea4;
}
.yellowhr {
  border-top: 1px solid #f2ebdf;
}
.list-links {
  line-height: 250%;
}
.infoblock {
  min-height: 350px;
}
.transparent-panel {
  border: 1px solid #e5e9f2;
  border-radius: 5px;
  padding: 14px;
}
.maillink {
  font-size: 12px;
}
.white-section {
  background: #fff;
  border-radius: 10px;
  padding: 20px 5px;
  margin-top: 50px;
}
.cabvisible {
  overflow: hidden;
}
.inlineblock {
  display: inline;
}
/* Левая панель в документации */
.left-panel {
  background-color: #094071;
  width: 300px;
  height: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  color: #fff;
  position: fixed;
  z-index: 1;
}
.ui-nav--hidden .left-panel {
  display: none;
}
#ui-nav-toggle {
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: #094071;
  color: #fff;
  font-size: 11px;
  padding: 2px 8px 2px;
  border-radius: 0px 0px 8px 8px;
  cursor: pointer;
  opacity: 0.2;
  -webkit-transition: all 0.2s ease-in;
  -moz-transition: all 0.2s ease-in;
  -ms-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}
#ui-nav-toggle:hover {
  opacity: 1;
}
.left-panel__section {
  padding: 15px;
  border-bottom: 1px solid rgba(138, 188, 232, 0.2);
}
.left-panel__section:last-child {
  border-bottom: none;
}
.left-panel-nav {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.left-panel-nav__link {
  -webkit-transition: all 0.2s ease-in;
  -moz-transition: all 0.2s ease-in;
  -ms-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
  display: inline-block;
  margin-bottom: 15px;
  color: #ceedff;
  font-size: 90%;
  text-decoration: none;
  border-bottom: 1px solid rgba(138, 188, 232, 0.5);
  padding: 5px 0;
}
.left-panel-nav__link:hover {
  color: #fff;
}
.section-top {
  padding-bottom: 24px;
  padding-top: 24px;
  flex-direction: column;
}
@media (min-width: 768px) {
  .section-top {
    flex-direction: row;
    align-items: center;
  }
}
.section-top-title {
  padding-right: 7px;
  padding-left: 9px;
}
@media (min-width: 768px) {
  .section-top-title {
    padding-left: 0px;
  }
}
.section-top-subtitle {
  padding-bottom: 28px;
  padding-left: 9px;
}
@media (min-width: 768px) {
  .section-top-subtitle {
    padding-bottom: 0;
    padding-left: 0px;
  }
}
@media (min-width: 768px) {
  .section-top--apartment .filter-toggle {
    align-items: center;
    margin-left: auto;
  }
}
.text-body--title {
  display: none;
  padding-right: 10px;
}
@media (min-width: 1200px) {
  .text-body--title {
    display: block;
  }
}
.link-filter.simple-link {
  font-size: 16px;
  padding-top: 2px;
  padding-bottom: 4px;
  text-align: center;
}
@media (min-width: 768px) {
  .link-filter.simple-link {
    font-size: 14px;
    padding-left: 0px;
    padding-right: 0px;
    margin-left: 2px;
    margin-right: 8px;
    width: auto;
    border-bottom: 1px dashed #97bfe7;
  }
}
.simple-link.link-filter--active {
  padding-left: 10px;
  padding-right: 10px;
  border-bottom: 0px;
}
.link-filter--left.link-filter--active {
  border-radius: 3px 0px 0px 3px;
}
@media (min-width: 768px) {
  .link-filter--left.link-filter--active {
    border-radius: 3px;
  }
}
.link-filter--right.link-filter--active {
  border-radius: 0px 3px 3px 0px;
}
@media (min-width: 768px) {
  .link-filter--right.link-filter--active {
    border-radius: 3px;
  }
}
.apartment-database__button .button {
  padding-left: 27px;
  padding-right: 27px;
  height: 60px;
  font-weight: 400;
  border-radius: 5px;
}
@media (min-width: 768px) {
  .apartment-database__button .button {
    padding-left: 75px;
    padding-right: 75px;
    height: 30px;
    border-radius: 3px;
  }
}
.section-top--hc-cards .section-top-title {
  padding-bottom: 16px;
}
@media (min-width: 768px) {
  .section-top--hc-cards .section-top-title {
    padding-right: 26px;
    padding-bottom: 0;
  }
}
#map {
  width: 100%;
  height: 420px;
  padding-bottom: 30px;
}
@media (min-width: 768px) {
  .section-map {
    padding-bottom: 25px;
    padding-top: 25px;
  }
}
.section-map--title {
  padding-bottom: 20px;
}
.section-bottom {
  flex-direction: column;
}
.section-bottom .link-group {
  margin-bottom: 14px;
  margin-top: 14px;
  padding-left: 8px;
}
@media (min-width: 768px) {
  .section-bottom .link-group {
    padding-left: 0;
  }
}
@media (min-width: 1200px) {
  .section-bottom .link-group {
    width: 40%;
    margin-bottom: 0;
    margin-top: 0;
    margin-right: 30px;
  }
}
@media (min-width: 1200px) {
  .section-bottom .link-group {
    width: auto;
  }
}
.section-bottom .link-block {
  padding-right: 0;
}
@media (min-width: 768px) {
  .section-bottom .link-block {
    padding-right: 24px;
  }
}
@media (min-width: 1200px) {
  .section-bottom {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}
@media (min-width: 1200px) {
  .section-bottom {
    justify-content: flex-start;
  }
}
.metro-station-mobile {
  position: relative;
  display: flex;
  flex-direction: column;
}
.metro-station-mobile .metro-station-mobile__item {
  padding-bottom: 15px;
}
@media (min-width: 768px) {
  .metro-station-mobile {
    display: none;
  }
}
.metro-station-mobile .icon-metro {
  width: 27px;
  height: 23px;
  position: absolute;
  right: -37px;
  top: 0px;
}
.section-top--search-result {
  flex-wrap: wrap;
}
.section-top--search-result .title-1 {
  padding-left: 14px;
}
@media (min-width: 768px) {
  .section-top--search-result {
    display: flex;
    align-items: center;
  }
  .section-top--search-result .title-1 {
    padding-left: 0px;
  }
}
@media (min-width: 1350px) {
  .section-top--search-result {
    padding-bottom: 30px;
  }
}
.section-top--search-result .search-result-save-box {
  border: 1px solid #e5e9f2;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 20px;
  padding: 15px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 5px;
  background-color: #fff;
}
@media (min-width: 768px) {
  .section-top--search-result .search-result-save-box {
    order: 3;
  }
}
.section-top--search-result .search-result-save-box .search-result-save-badges {
  margin-right: auto;
}
.section-top--search-result .search-result-save-box .badge {
  margin-bottom: 10px;
}
@media (min-width: 768px) {
  .section-top--search-result .search-result-save-box {
    padding: 10px;
    width: 100%;
    padding-bottom: 0;
    margin-right: auto;
  }
}
@media (min-width: 992px) {
  .section-top--search-result .search-result-save-box {
    width: 65%;
  }
}
@media (min-width: 1350px) {
  .section-top--search-result .search-result-save-box {
    width: 70%;
  }
}
.section-top--search-result .save-box--option {
  margin-bottom: 10px;
}
@media (min-width: 768px) {
  .section-top--search-result .filter-toggle {
    margin-left: auto;
    margin-top: 20px;
    order: 4;
  }
}
@media (min-width: 768px) {
  .search-result__top--filters {
    order: 2;
    display: flex;
    align-items: center;
    margin-left: auto;
    padding-top: 0;
  }
}
.search-result__top--filters .filter-toggle__text {
  display: none;
}
@media (min-width: 992px) {
  .search-result__top--filters .filter-toggle__text {
    display: block;
  }
}
.check--hc-type {
  padding-left: 14px;
  padding-top: 24px;
  padding-bottom: 20px;
  padding-right: 28px;
}
@media (min-width: 768px) {
  .check--hc-type {
    padding-left: 0;
    padding-top: 0px;
    padding-bottom: 0px;
    padding-right: 0px;
  }
}
.check--hc-type:hover .checkbox-title {
  color: #d15656;
  border-bottom-color: rgba(209, 86, 86, 0.2);
}
.search-result__cards {
  padding-bottom: 40px;
}
.search-result__footer {
  justify-content: center;
  align-items: center;
  padding-bottom: 55px;
  padding-top: 30px;
}
@media (min-width: 768px) {
  .search-result__footer {
    justify-content: space-between;
    padding-top: 0;
  }
}
.search-result__footer .button--basic {
  max-width: 270px;
}
@media (min-width: 992px) {
  .section-top--popular-objects {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
.popular-objects-top__link {
  display: none;
}
@media (min-width: 768px) {
  .popular-objects-top__link {
    display: flex;
  }
}
.popular-objects-top__link .icon-flag {
  align-self: flex-start;
  margin-right: 9px;
  position: relative;
  top: -5px;
}
.popular-objects-top__link-title {
  color: #000;
  padding-right: 5px;
}
.title-2--middle {
  font-size: 28px;
  font-weight: 400;
  width: 60%;
  padding-left: 14px;
}
@media (min-width: 360px) {
  .title-2--middle {
    width: 100%;
  }
}
@media (min-width: 768px) {
  .title-2--middle {
    width: auto;
    padding-left: 0;
    padding-bottom: 16px;
  }
}
@media (min-width: 992px) {
  .title-2--middle {
    padding-bottom: 0px;
  }
}
.appartment-card--mobile-repaire-icon {
  position: absolute;
  top: -35px;
  left: -6px;
  width: 40px;
  height: 40px;
  background-color: #41ae1b;
  border-radius: 50%;
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (min-width: 1200px) {
  .offer-page-wrapper .logoblock {
    margin-right: 1.5em;
  }
}
@media (min-width: 1350px) {
  .offer-page-wrapper .logoblock {
    margin-right: 2.5em;
  }
}
@media (min-width: 992px) {
  .offer-page-wrapper .logoblock .logo-text {
    display: none;
  }
}
@media (min-width: 1350px) {
  .offer-page-wrapper .logoblock .logo-text {
    display: block;
  }
}
.offer-page-wrapper .header__icon-group__item {
  margin-right: 20px;
}
@media (min-width: 992px) {
  .offer-page-wrapper .header__icon-group__item {
    margin-right: 32px;
  }
}
.section-top--new-offer {
  display: block;
  margin-top: 60px;
  margin-bottom: 20px;
}
@media (min-width: 768px) {
  .section-top--new-offer {
    display: flex;
    justify-content: space-between;
    margin-top: 0;
    margin-bottom: 0;
  }
}
.section-top--new-offer .badge--green {
  margin-bottom: 20px;
}
@media (min-width: 768px) {
  .section-top--new-offer .badge--green {
    margin-bottom: 0;
  }
}
.section-cards {
  margin-bottom: 30px;
}
@media (min-width: 768px) {
  .section-cards {
    margin-bottom: 50px;
  }
}
.new-offer-footer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 80px;
}
.new-offer-footer .btn-text {
  display: none;
}
@media (min-width: 768px) {
  .new-offer-footer .btn-text {
    display: block;
  }
}
.new-offer-footer .btn-text--mobile {
  display: block;
}
@media (min-width: 768px) {
  .new-offer-footer .btn-text--mobile {
    display: none;
  }
}
.section-top--hot-offer {
  display: block;
  padding-top: 0;
  padding-bottom: 0;
}
.section-top--hot-offer .title-1--desk {
  display: none;
}
@media (min-width: 992px) {
  .section-top--hot-offer .title-1--desk {
    display: block;
    padding-right: 28px;
  }
}
.section-top--hot-offer .title-1--mob {
  display: block;
  padding-bottom: 14px;
}
@media (min-width: 768px) {
  .section-top--hot-offer .title-1--mob {
    padding-bottom: 0;
    padding-right: 28px;
  }
}
@media (min-width: 992px) {
  .section-top--hot-offer .title-1--mob {
    display: none;
  }
}
.section-top--hot-offer .badge {
  margin-bottom: 20px;
}
@media (min-width: 768px) {
  .section-top--hot-offer .badge {
    margin-bottom: 0;
    margin-right: 28px;
  }
}
@media (min-width: 768px) {
  .section-top--hot-offer {
    display: flex;
  }
}
@media (min-width: 768px) {
  .hot-offer-footer .button--basic {
    width: 100%;
  }
}
.hot-offer-footer .btn-text {
  display: none;
}
@media (min-width: 768px) {
  .hot-offer-footer .btn-text {
    display: block;
  }
}
.hot-offer-footer .btn-text--mobile {
  display: block;
}
@media (min-width: 768px) {
  .hot-offer-footer .btn-text--mobile {
    display: none;
  }
}
.button-download {
  height: 30px;
  text-align: center;
  background-color: #2c81d1;
  color: #fff;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  border-radius: 3px;
  font-weight: 700;
}
.button-download:hover,
.button-download:active,
.button-download:focus {
  color: #fff;
  text-decoration: none;
  opacity: .85;
}
.button-download .button-download--desk {
  display: none;
}
@media (min-width: 768px) {
  .button-download .button-download--desk {
    display: block;
  }
}
.button-download .button-download--mob {
  display: block;
}
@media (min-width: 768px) {
  .button-download .button-download--mob {
    display: none;
  }
}
@media (min-width: 1200px) {
  .offer-row--tools .icon-heart--tablet .simple-link {
    display: block;
    padding-left: 8px;
  }
}
.offer-row--info {
  position: relative;
}
.offer-row--info .appartment-card--mobile-repaire-icon {
  top: 114px;
}
@media (min-width: 768px) {
  .offer-row--info .appartment-card--mobile-repaire-icon {
    display: none;
  }
}
.section-left-column {
  position: relative;
}
@media (min-width: 1200px) {
  .appartment-page__map .section-map--bottom {
    flex-wrap: wrap;
  }
  .appartment-page__map .section-map--bottom .badge-group,
  .appartment-page__map .section-map--bottom .link-group,
  .appartment-page__map .section-map--bottom .text-info--metro {
    padding-bottom: 7px;
  }
}
.title-block--appartment-page .title-block-wrapper {
  align-items: flex-end;
}
.title-block--appartment-page .update-box {
  padding-bottom: 0;
}
@media (min-width: 992px) {
  .title-block--appartment-page .text-info {
    padding-left: 14px;
    padding-top: 3px;
  }
}
@media (min-width: 1350px) {
  .title-block--appartment-page .text-info {
    padding-left: 0;
  }
}
.section-top--similar-objects {
  padding-top: 52px;
}
.similar-objects-top__link {
  display: none;
}
@media (min-width: 768px) {
  .similar-objects-top__link {
    display: flex;
  }
}
.similar-objects-top__link .icon-flag {
  align-self: flex-start;
  margin-right: 9px;
  position: relative;
  top: -5px;
}
.similar-objects-top__link-title {
  color: #000;
  padding-right: 5px;
}
.card-price--mobile {
  display: block;
  margin-bottom: 40px;
}
.card-price--mobile .price-card__toolbar {
  display: none;
}
@media (min-width: 1200px) {
  .card-price--mobile {
    display: none;
  }
}
.card-price--desktop {
  display: none;
}
@media (min-width: 1200px) {
  .card-price--desktop {
    display: block;
  }
}
.similar-objects--cards .share-link-text {
  display: none;
}
.footer {
  padding-top: 50px;
  position: relative;
}
@media (min-width: 768px) {
  .footer {
    padding-left: 0px;
  }
}
@media (min-width: 1200px) {
  .footer {
    padding-top: 60px;
    overflow: hidden;
  }
}
@media (min-width: 1350px) {
  .footer {
    flex-direction: row;
    align-items: center;
  }
}
.footer .footer-top {
  background: transparent;
  position: relative;
  z-index: 2;
  padding-bottom: 2.2rem;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}
@media (min-width: 768px) {
  .footer .footer-top {
    padding-bottom: 1rem;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
  }
}
@media (min-width: 1350px) {
  .footer .footer-top {
    padding-bottom: 30px;
    justify-content: flex-start;
  }
}
.footer .simple-link {
  width: fit-content;
  padding-top: 5px;
}
@media (min-width: 768px) {
  .footer .simple-link {
    padding-top: 16px;
  }
}
@media (min-width: 992px) {
  .footer .simple-link {
    padding-top: 0px;
  }
}
.footer .links-block {
  width: 300px;
  flex-wrap: wrap;
  margin-bottom: 30px;
}
@media (min-width: 480px) {
  .footer .links-block {
    width: auto;
  }
}
@media (min-width: 768px) {
  .footer .links-block {
    margin-bottom: 0;
    display: flex;
    margin-right: auto;
    justify-content: space-between;
  }
}
@media (min-width: 800px) {
  .footer .links-block {
    width: 550px;
  }
}
@media (min-width: 992px) {
  .footer .links-block {
    align-items: center;
    width: auto;
    margin-right: 0;
    margin-bottom: 0;
  }
}
@media (min-width: 1200px) {
  .footer .links-block {
    flex-wrap: nowrap;
  }
}
.footer .button-block {
  position: relative;
}
.footer .logoblock {
  align-items: flex-start;
  flex-direction: column;
  padding-bottom: 20px;
}
@media (min-width: 768px) {
  .footer .logoblock {
    padding-bottom: 0px;
  }
}
@media (min-width: 992px) {
  .footer .logoblock {
    margin-right: 0;
    padding-bottom: 20px;
  }
}
@media (min-width: 1350px) {
  .footer .logoblock {
    align-items: center;
    flex-direction: row;
    margin-right: auto;
    padding-bottom: 0;
  }
}
.footer .logoblock .logo-text {
  width: 270px;
  font-size: 14px;
  display: block;
  color: #fff;
}
@media (min-width: 1350px) {
  .footer .logoblock .logo-text {
    width: 210px;
  }
}
.footer .logoblock .logo-icon {
  margin-bottom: 6px;
}
@media (min-width: 1350px) {
  .footer .logoblock .logo-icon {
    margin-bottom: 0;
  }
}
.footer-bottom {
  overflow: hidden;
  flex-direction: column;
  background: transparent;
  position: relative;
  z-index: 2;
  padding-top: 0.5rem;
  padding-bottom: 1.0rem;
}
@media (min-width: 992px) {
  .footer-bottom {
    padding-top: 1rem;
    padding-bottom: 2.2rem;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}
@media (min-width: 1440px) {
  .footer-bottom {
    padding-bottom: 40px;
    padding-top: 15px;
  }
}
.footer-bottom .simple-link {
  display: inline-block;
  color: #fff;
  order: 1;
  margin-right: 0;
  margin-bottom: 22px;
}
@media (min-width: 768px) {
  .footer-bottom .simple-link {
    margin-bottom: 10px;
  }
}
@media (min-width: 992px) {
  .footer-bottom .simple-link {
    margin-bottom: 22px;
    order: 2;
    margin-bottom: 0;
  }
}
.footer-bottom__copyright {
  color: #fff;
  order: 2;
  font-size: 14px;
}
@media (min-width: 992px) {
  .footer-bottom__copyright {
    order: 1;
  }
}
.footer-bottom__politic {
  order: 1;
}
@media (min-width: 992px) {
  .footer-bottom__politic {
    order: 2;
  }
}
.realty-cat-img {
  display: none;
  position: absolute;
  bottom: -73px;
  left: 50%;
  transform: translateX(-50%) translateX(30px);
}
.realty-cat-img svg {
  width: 230px;
}
@media (min-width: 768px) {
  .realty-cat-img {
    bottom: -81px;
    display: block;
    transform: translate(-32%, -10%);
  }
}
@media (min-width: 992px) {
  .realty-cat-img {
    bottom: -91px;
  }
}
@media (min-width: 1350px) {
  .realty-cat-img {
    bottom: -76px;
    transform: translateX(-50%) translateX(27px) translateY(-5px);
  }
}
.simple-link {
  border-bottom: 1px solid #569ada;
  font-size: 14px;
  color: #fff;
  transition: .5s;
}
.simple-link:hover {
  color: #569ada;
}
.btn-add {
  min-width: 270px;
  width: 100%;
  max-width: 300px;
  padding-top: 18px;
  padding-bottom: 19px;
  border-radius: 5px;
  border: 2px solid #fff;
  display: block;
  text-align: center;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
}
@media (min-width: 768px) {
  .btn-add {
    border-width: 1px;
    font-size: 14px;
    font-weight: 400;
    min-width: 200px;
    padding-top: 5px;
    padding-bottom: 5px;
  }
}
.item-plus {
  font-size: 38px;
  position: relative;
  top: 8px;
  line-height: 0;
}
@media (min-width: 768px) {
  .item-plus {
    top: 2px;
    font-size: 20px;
  }
}
.simple-link.simple-link--block {
  display: block;
  margin-bottom: 6px;
}
@media (min-width: 768px) {
  .simple-link.simple-link--block {
    margin-bottom: 0px;
    display: inline-block;
  }
}
.simple-link.simple-link--ib {
  padding-top: 6px;
  display: inline-block;
}
.simple-link--ib:nth-child(2) {
  margin-right: 20px;
}
.footer-tooltip {
  display: none;
  padding: 10px;
  border-radius: 5px;
  background-color: #000;
  position: absolute;
  top: -20px;
  left: 50%;
  font-size: 14px;
  text-align: center;
  max-width: 250px;
  min-width: 250px;
  transform: translate(-50%, -100%);
}
.footer-tooltip:after {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border: 10px solid #000;
  border-bottom: 0px;
  border-left-color: transparent;
  border-right-color: transparent;
  bottom: -8px;
  left: 50%;
}
.button-block:hover .footer-tooltip {
  display: block;
}
#header {
  position: sticky;
  top: 0;
  z-index: 100;
  background-color: white;
  border-bottom: 1px solid rgba(44, 129, 209, 0.35);
  color: #334860;
  min-height: 135px;
  padding-top: 25px;
  overflow: hidden;
}
@media (min-width: 768px) {
  #header {
    padding-top: 22px;
    min-height: 80px;
  }
}
.logo-icon {
  margin-right: 20px;
}
.logo-icon svg {
  height: 40px;
  width: 200px;
}
.logoblock {
  display: block;
  align-items: center;
  margin-bottom: 1.8rem;
  margin-right: 2.5rem;
}
.logoblock .logo-icon {
  margin-right: 1rem;
}
@media (min-width: 1350px) {
  .logoblock .logo-icon {
    margin-right: 1.1rem;
  }
}
.logoblock .logo-text {
  color: black;
  width: 228px;
  line-height: 1.2;
  font-size: 14px;
}
@media (min-width: 480px) {
  .logoblock .logo-text {
    width: 250px;
  }
}
@media (min-width: 768px) {
  .logoblock {
    display: flex;
    margin-bottom: 0px;
    justify-content: flex-start;
  }
}
@media (min-width: 992px) {
  .logoblock {
    width: auto;
  }
}
.header-content {
  display: flex;
  align-items: center;
}
.header-content .logoblock {
  margin-right: auto;
}
.header-info-block {
  display: none;
  margin-right: 45px;
}
@media (min-width: 1200px) {
  .header-info-block {
    display: flex;
  }
}
.header-info {
  display: flex;
  align-items: center;
}
.header-info__number {
  font-size: 40px;
}
.header-info__text {
  font-size: 14px;
  padding-left: 12px;
  max-width: 170px;
}
.header-btn {
  border: 0px;
  background-color: #2c81d1;
  color: #fff;
  font-weight: 700;
  border-radius: 5px;
  min-width: 90px;
  height: 30px;
  text-align: center;
  padding-top: 2px;
  transition: .5s;
  cursor: pointer;
}
.header-btn:hover {
  box-shadow: 0 0 12px 4px rgba(44, 129, 209, 0.45);
}
.rcat-landing {
  position: relative;
  background-color: #1b2e46;
  font-size: 16px;
  color: #fff;
  overflow: hidden;
  padding-top: 50px;
}
.search-param {
  padding-bottom: 60px;
  padding-top: 160px;
}
@media (min-width: 1200px) {
  .search-param {
    padding-bottom: 0px;
  }
}
.search-param-descrption {
  padding-top: 55px;
}
@media (min-width: 992px) {
  .search-param-descrption {
    padding-top: 0px;
  }
}
@media (min-width: 1200px) {
  .search-param-descrption {
    display: block;
  }
}
.parallax-wrapper2,
.parallax-wrapper {
  -webkit-border-radius: 5px;
  border-radius: 5px;
  left: -25px;
  height: 555px;
  position: relative;
  overflow: hidden;
  width: 121%;
}
@media (min-width: 650px) {
  .parallax-wrapper2,
  .parallax-wrapper {
    left: 0px;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
}
@media (min-width: 768px) {
  .parallax-wrapper2,
  .parallax-wrapper {
    height: 780px;
  }
}
@media (min-width: 992px) {
  .parallax-wrapper2,
  .parallax-wrapper {
    width: 100%;
    height: 700px;
  }
}
@media (min-width: 1200px) {
  .parallax-wrapper2,
  .parallax-wrapper {
    height: 1050px;
  }
}
.parallax-wrapper2 .parallax-screen,
.parallax-wrapper .parallax-screen {
  position: absolute;
  width: 578px;
  z-index: 10;
}
@media (min-width: 480px) {
  .parallax-wrapper2 .parallax-screen,
  .parallax-wrapper .parallax-screen {
    width: 650px;
  }
}
@media (min-width: 650px) {
  .parallax-wrapper2 .parallax-screen,
  .parallax-wrapper .parallax-screen {
    width: 100%;
  }
}
@media (min-width: 992px) {
  .parallax-wrapper2 .parallax-screen,
  .parallax-wrapper .parallax-screen {
    width: 110%;
  }
}
@media (min-width: 1200px) {
  .parallax-wrapper2 .parallax-screen,
  .parallax-wrapper .parallax-screen {
    width: 100%;
  }
}
.parallax-wrapper2 img,
.parallax-wrapper img {
  -webkit-border-radius: 5px;
  border-radius: 5px;
}
@media (min-width: 1200px) {
  .parallax-wrapper2 {
    height: 1414px;
  }
}
.parallax-screen img {
  height: 690px;
}
@media (min-width: 576px) {
  .parallax-screen img {
    height: 785px;
  }
}
@media (min-width: 768px) {
  .parallax-screen img {
    height: auto;
  }
}
#screen2 img {
  height: 1800px;
}
@media (min-width: 576px) {
  #screen2 img {
    height: 1900px;
  }
}
@media (min-width: 768px) {
  #screen2 img {
    height: auto;
  }
}
.text-box {
  font-size: 16px;
  line-height: 1.5;
  color: #fff;
  font-weight: normal !important;
}
.bgblue {
  background-color: #2b82d1;
}
.bggreen {
  background-color: #41ae1b;
}
.text-box--bold {
  font-weight: 700;
}
.text-box--number {
  line-height: 0.9;
  font-size: 80px;
}
@media (min-width: 992px) {
  .text-box--number {
    line-height: 1;
  }
}
.emoji-item {
  margin-bottom: 16px;
}
.descrption_item-top,
.descrption_item--middle,
.descrption_item--bottom {
  max-width: 250px;
}
.descrption_item-top {
  padding-bottom: 55px;
}
@media (min-width: 550px) {
  .descrption_item-top {
    padding-top: 50px;
  }
}
@media (min-width: 768px) {
  .descrption_item-top {
    padding-top: 0px;
  }
}
@media (min-width: 992px) {
  .descrption_item-top {
    padding-top: 30px;
    padding-bottom: 100px;
  }
}
@media (min-width: 1200px) {
  .descrption_item-top {
    padding-bottom: 150px;
  }
}
@media (min-width: 1280px) {
  .descrption_item-top {
    padding-bottom: 200px;
  }
}
.descrption_item--middle {
  padding-bottom: 55px;
}
@media (min-width: 992px) {
  .descrption_item--middle {
    padding-bottom: 100px;
  }
}
@media (min-width: 1200px) {
  .descrption_item--middle {
    padding-bottom: 200px;
  }
}
@media (min-width: 1280px) {
  .descrption_item--middle {
    padding-bottom: 300px;
  }
}
/*----преимущества----*/
.benefits {
  padding-top: 90px;
  padding-bottom: 25px;
}
@media (min-width: 1200px) {
  .benefits {
    padding-bottom: 75px;
  }
}
@media (min-width: 1200px) {
  .benefits {
    padding-top: 150px;
  }
}
.block-title {
  font-size: 26px;
  font-weight: 700;
}
@media (min-width: 768px) {
  .block-title {
    font-size: 36px;
  }
}
.block-subtitle {
  padding-top: 16px;
  padding-bottom: 40px;
  font-size: 16px;
  line-height: 1.5;
}
@media (min-width: 768px) {
  .block-subtitle {
    font-size: 20px;
  }
}
.block-subtitle--benefits {
  max-width: 490px;
}
.text-box__title {
  font-size: 16px;
  font-weight: 700;
  padding-bottom: 10px;
}
@media (min-width: 768px) {
  .text-box__title {
    font-size: 20px;
  }
}
.agent-card-img {
  margin-bottom: 38px;
  height: 275px;
  width: 120%;
  border-radius: 10px;
  background: url('../../img/landing/agent-card-img.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left;
}
@media (min-width: 480px) {
  .agent-card-img {
    height: 330px;
  }
}
@media (min-width: 768px) {
  .agent-card-img {
    margin-bottom: 50px;
    width: 100%;
  }
}
@media (min-width: 850px) {
  .agent-card-img {
    height: 360px;
  }
}
@media (min-width: 900px) {
  .agent-card-img {
    height: 390px;
  }
}
@media (min-width: 992px) {
  .agent-card-img {
    height: 405px;
  }
}
.benefits__bottom-text {
  padding-top: 30px;
}
@media (min-width: 992px) {
  .benefits__bottom-text {
    padding-top: 40px;
  }
}
@media (min-width: 1200px) {
  .benefits__bottom-text {
    padding-top: 90px;
  }
}
.text-box--bg {
  display: inline-block;
  padding-top: 3px;
  font-weight: 700;
  height: 30px;
  padding-left: 12px;
  padding-right: 12px;
  background-color: #41ae1b;
  -webkit-border-radius: 3px;
  border-radius: 3px;
}
.benefits-cards {
  display: none;
}
@media (min-width: 768px) {
  .benefits-cards {
    display: block;
  }
}
.benefits-card {
  background-color: #334860;
  border-radius: 10px;
  overflow: hidden;
  padding: 15px 30px 0px 20px;
  width: 100%;
  height: 420px;
  max-width: 630px;
  margin-left: auto;
  margin-right: auto;
}
@media (min-width: 768px) {
  .benefits-card-left {
    margin-bottom: 55px;
  }
}
@media (min-width: 1200px) {
  .benefits-card-left {
    margin-bottom: 0px;
  }
}
.benefits-descrip {
  margin-bottom: 50px;
}
@media (min-width: 768px) {
  .benefits-descrip {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
@media (min-width: 992px) {
  .benefits-descrip {
    margin-bottom: 0;
    display: block;
  }
}
.benefits-descrip .benefits__top-text {
  max-width: 300px;
}
.landing-button {
  cursor: pointer;
  border-style: solid;
  border-width: 1px;
  border-color: #ffffff;
  border-radius: 3px;
  background-color: transparent;
  min-height: 30px;
  font-family: inherit;
  font-weight: 500;
  padding-left: 12px;
  padding-right: 12px;
  display: inline-flex;
  align-items: center;
  color: #fff;
  margin-top: 17px;
  margin-bottom: 40px;
  transition: .5s;
}
.btn-add:hover,
.landing-button:hover {
  color: #fff;
  box-shadow: 0 0 10px 2px rgba(255, 255, 255, 0.4);
  background-color: rgba(70, 136, 241, 0);
}
.card-left-img {
  height: 230px;
  background-color: #fff;
  position: relative;
}
.benefits-cards--mobile {
  display: block;
  background-color: #334860;
}
@media (min-width: 768px) {
  .benefits-cards--mobile {
    display: none;
  }
}
.benefits-cards--mobile .benefits-card__top {
  position: relative;
  padding-top: 40px;
  height: 650px;
}
.benefits-cards--mobile .card-left-img {
  position: absolute;
  left: -100px;
  right: 25px;
  bottom: 30px;
  height: 280px;
  background-color: #ffffff;
  box-shadow: 0px 40px 60px 0px rgba(0, 0, 0, 0.75);
}
.benefits-cards--mobile .card-left__icons {
  top: -40px;
}
.benefits-cards--mobile .card-left__whatsapp {
  position: relative;
  right: -35px;
}
.benefits-cards--mobile .card-left__email {
  position: relative;
  right: -15px;
}
.benefits-cards--mobile .benefits-card__bottom {
  padding-top: 40px;
  height: 1020px;
  position: relative;
}
.benefits-cards--mobile .card__bottom-img {
  width: 300px;
  height: 550px;
  position: absolute;
  left: 10px;
  bottom: 0;
  background-color: #fff;
  background: url(../img/landing/agent.png);
  background-size: cover;
}
.landing-button--arrow {
  font-weight: 700;
  padding-left: 15px;
}
.card-left__icons {
  position: absolute;
  display: flex;
  align-items: center;
  right: 10px;
  top: -50px;
}
.benefits-card-right {
  width: 100%;
  background-color: #334860;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  padding-top: 15px;
  padding-left: 20px;
}
.card-right__bottom {
  padding-top: 40px;
}
.text-box--strong {
  font-weight: 700;
}
.card-right-text {
  width: 52%;
  max-width: 320px;
}
.card-right-img {
  width: 230px;
  height: 400px;
  position: absolute;
  right: 30px;
  bottom: 0px;
  overflow: hidden;
}
.screen3 {
  position: absolute;
  z-index: 10;
}
.emoji-item--selfie {
  font-size: 100px;
  position: relative;
  left: -40px;
  margin-bottom: 17px;
}
/*--------горячие предложения-------*/
.emoji-item--megaphone {
  position: relative;
  top: -20px;
}
.urgent-sale-description {
  padding-top: 90px;
  padding-bottom: 160px;
}
@media (min-width: 768px) {
  .urgent-sale-description {
    padding-bottom: 100px;
  }
}
@media (min-width: 1200px) {
  .urgent-sale-description {
    padding-bottom: 145px;
    padding-top: 50px;
  }
}
.urgent-top-text {
  margin-bottom: 40px;
}
@media (min-width: 1200px) {
  .urgent-top-text {
    margin-bottom: 130px;
  }
}
@media (min-width: 992px) {
  .urgent-sale-text--flex {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 60px;
  }
}
.urgent-sale-benefits {
  margin-top: 70px;
}
.urgent-sale-img {
  background-color: #fff;
  background: url(../../img/landing/hot-card.png);
  background-size: cover;
  height: 370px;
  width: 630px;
  margin-top: 40px;
  border-radius: 10px;
}
@media (min-width: 992px) {
  .urgent-sale-img {
    width: 960px;
    height: 560px;
  }
}
.benefits-text,
.card__bottom-text,
.urgent-sale-text {
  max-width: 300px;
}
@media (min-width: 1300px) {
  .benefits-text,
  .card__bottom-text,
  .urgent-sale-text {
    max-width: 388px;
  }
}
.mb-75 {
  margin-bottom: 75px;
}
@media (min-width: 992px) {
  .mb-75 {
    margin-bottom: 0;
  }
}
/*--------шахматки-------*/
.hc-param-wrapper {
  position: relative;
  border-radius: 10px;
  background-color: #000;
  padding-top: 35px;
  padding-bottom: 50px;
}
.text-number {
  font-size: 80px;
  color: #41ae1b;
}
@media (min-width: 768px) {
  .text-number {
    font-size: 60px;
  }
}
@media (min-width: 1200px) {
  .text-number {
    font-size: 80px;
  }
}
.sub-title-block {
  align-items: flex-end;
  display: flex;
}
.hc-param-top {
  list-style-type: 35px;
  padding-left: 35px;
}
.title-3 {
  font-size: 26px;
  line-height: 1.3;
  width: 90%;
  font-weight: 700;
}
@media (min-width: 768px) {
  .title-3 {
    font-size: 32px;
  }
}
@media (min-width: 992px) {
  .title-3 {
    font-size: 36px;
  }
}
@media (min-width: 1200px) {
  .title-3 {
    font-size: 40px;
  }
}
.hc-cards-block {
  position: relative;
  overflow: hidden;
  height: 430px;
}
.screen4 {
  position: absolute;
  z-index: 10;
  width: 104%;
}
/**
.hc-param--mobile{
	position: relative;
	.title-3{padding-bottom: 20px;}
	.hc-param-wrapper{
		height: 1180px;
		padding-top: 30px;
		padding-bottom: 30px;
		border-radius: 0px;
		@media(min-width: 375px){height: 1100px;}
		@media(min-width: 425px){height: 1050px;}
		@media(min-width: 500px){height: 1000px;}
		@media(min-width: 600px){height: 950px;}
		@media(min-width: 700px){height: 900px;}
	}
	.text-number{
		padding-top: 40px;
		padding-bottom: 18px;
	}
	.param-wrapper-top{
		padding-left: 30px;
		padding-right: 30px;
	}

	.hc-cards-block{bottom: -35px;}
	.screen4{
		width: 1300px;
		// margin-left: -160px;
		margin-left: 90px;
		}
}**/
/*-----философия---*/
.philosophy {
  padding-top: 150px;
}
.philosophy-slogan {
  font-weight: 700;
  font-size: 40px;
  line-height: 1.6;
}
@media (min-width: 768px) {
  .philosophy-slogan {
    line-height: 1.3;
    font-size: 50px;
  }
}
@media (min-width: 992px) {
  .philosophy-slogan {
    font-size: 70px;
  }
}
@media (min-width: 1200px) {
  .philosophy-slogan {
    font-size: 80px;
  }
}
.pl-20 {
  max-width: 300px;
}
@media (min-width: 992px) {
  .pl-20 {
    padding-left: 20px;
  }
}
.pb-16 {
  padding-bottom: 16px;
}
.color-block {
  padding: 25px;
  width: 100%;
  position: relative;
}
@media (min-width: 435px) {
  .color-block {
    border-radius: 5px;
  }
}
@media (min-width: 1300px) {
  .color-block {
    left: -25px;
  }
}
.philosophy-right {
  padding-top: 70px;
}
@media (min-width: 768px) {
  .philosophy-right {
    padding-top: 30px;
  }
}
@media (min-width: 1200px) {
  .philosophy-right {
    padding-top: 40px;
  }
}
.philosophy-emoji {
  margin-bottom: 20px;
}
.philosophy-right-text {
  margin-bottom: 50px;
}
@media (min-width: 992px) {
  .color-block--desk {
    display: block;
  }
}
.color-block--mobile {
  display: block;
  max-width: 435px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
}
@media (min-width: 992px) {
  .color-block--mobile {
    display: none;
  }
}
.philosophy-blockquote {
  padding: 0;
  margin: 0;
  padding-top: 116px;
  margin-left: -50px;
}
@media (min-width: 768px) {
  .philosophy-blockquote {
    padding-top: 130px;
    margin-left: 0px;
  }
}
@media (min-width: 992px) {
  .philosophy-blockquote {
    padding-top: 116px;
  }
}
.philosophy-blockquote__text {
  padding-left: 50px;
  position: relative;
}
@media (min-width: 768px) {
  .philosophy-blockquote__text {
    padding-left: 0px;
  }
}
.philosophy-blockquote__text .block-subtitle {
  padding-bottom: 25px;
}
.philosophy-blockquote__text span.text-box__title {
  padding-bottom: 0;
  margin-right: 8px;
}
.philosophy-blockquote__text span {
  display: block;
}
@media (min-width: 768px) {
  .philosophy-blockquote__text span {
    display: inline-block;
  }
}
.quote-left {
  display: block;
  position: absolute;
  top: -100px;
  left: -256px;
}
@media (min-width: 768px) {
  .quote-left {
    left: -156px;
  }
}
@media (min-width: 992px) {
  .quote-left {
    display: none;
    top: -90px;
    left: -156px;
  }
}
.quote-right {
  display: block;
  position: absolute;
  bottom: -42px;
  right: -75px;
}
@media (min-width: 992px) {
  .quote-right {
    display: none;
    bottom: -35px;
    right: -140px;
  }
}
@media (min-width: 1200px) {
  .quote-right {
    bottom: -35px;
    right: -140px;
  }
}
.philosophy-blockquote--mobile {
  display: block;
}
@media (min-width: 992px) {
  .philosophy-blockquote--mobile {
    display: none;
  }
}
#scene {
  display: none;
}
@media (min-width: 992px) {
  #scene {
    display: block;
  }
}
/*cooperation*/
.cooperation {
  padding-top: 160px;
  position: relative;
}
.cooperation-block {
  position: relative;
  padding: 25px 25px 75px 30px;
  background-color: #000;
  margin-left: -30px;
  width: 120%;
}
@media (min-width: 768px) {
  .cooperation-block {
    margin-left: 0px;
    border-radius: 10px;
    width: 100%;
  }
}
.cooperation--parallax {
  padding-top: 160px;
}
.cooperation-title {
  font-size: 26px;
}
@media (min-width: 768px) {
  .cooperation-title {
    font-size: 36px;
  }
}
@media (min-width: 992px) {
  .cooperation-title {
    font-size: 40px;
  }
}
.cooperation-proportions__col {
  margin-bottom: 45px;
}
@media (min-width: 768px) {
  .cooperation-proportions__col {
    margin-bottom: 0px;
  }
}
@media (min-width: 768px) {
  .cooperation-proportions {
    margin-bottom: 0;
    display: flex;
    justify-content: space-between;
  }
}
.cooperation-right__img {
  width: 140px;
  margin-bottom: 25px;
}
@media (min-width: 768px) {
  .cooperation-right__img {
    width: 65%;
  }
}
@media (min-width: 992px) {
  .cooperation-right__img {
    width: 100%;
  }
}
.proportions-value {
  font-size: 80px;
}
@media (min-width: 768px) {
  .proportions-value {
    font-size: 50px;
  }
}
@media (min-width: 992px) {
  .proportions-value {
    font-size: 70px;
  }
}
@media (min-width: 1200px) {
  .proportions-value {
    font-size: 80px;
  }
}
@media (min-width: 768px) {
  .cooperation-right {
    margin-top: 70px;
  }
}
@media (min-width: 992px) {
  .cooperation-right {
    margin-top: 25px;
  }
}
.cooperation-right .text-box {
  max-width: 380px;
}
@media (min-width: 768px) {
  .cooperation-right .text-box {
    max-width: 100%;
  }
}
@media (min-width: 480px) {
  .cooperation-left .block-subtitle {
    max-width: 92%	;
  }
}
@media (min-width: 768px) {
  .cooperation-left .block-subtitle {
    max-width: 100%	;
  }
}
@media (min-width: 1200px) {
  .cooperation_item-top {
    padding-bottom: 0;
    padding-top: 50px;
  }
}
@media (min-width: 1440px) {
  .cooperation_item-top {
    padding-top: 80px;
  }
}
@media (min-width: 1200px) {
  .cooperation_item--middle {
    padding-bottom: 250px;
    padding-top: 180px;
  }
}
@media (min-width: 1440px) {
  .cooperation_item--middle {
    padding-bottom: 330px;
    padding-top: 250px;
  }
}
/*-----join------*/
.join {
  padding-top: 155px;
}
.title-3--join {
  padding-bottom: 15px;
  border-bottom: 1px solid #569ada;
  width: 100%;
}
.join-row {
  padding-top: 26px;
  align-items: flex-end;
  padding-top: 25px;
}
.join-row--center {
  padding-top: 55px;
  align-items: center;
  padding-bottom: 85px;
}
.text-box--join {
  max-width: 245px;
}
.text-box__title--join + .text-box {
  max-width: 630px;
}
.join-img {
  height: 220px;
  margin-right: -30px;
  position: relative;
  overflow: hidden;
}
@media (min-width: 480px) {
  .join-img {
    margin-left: auto;
    margin-right: auto;
    width: 420px;
  }
}
@media (min-width: 768px) {
  .join-img {
    margin-left: 0;
    width: 100%;
    height: 312px;
    margin-right: 0;
  }
}
.buttons-reg--join {
  margin-bottom: 15px;
}
.text-box--broker {
  max-width: 720px;
}
#screen5 {
  position: absolute;
  z-index: 10;
  width: 100%;
}
#screen5 img {
  max-width: 133%;
}
.line-bottom {
  height: 1px;
  background-color: #334860;
  position: absolute;
  left: -10px;
  right: 0;
  bottom: 0;
  z-index: 20;
}
/*---------ava flip-----------*/
/* ГОРИЗОНТАЛЬНЫЙ FLIP */
.flip_effect_horizontal {
  /* общие стили */
  width: 70px;
  /* ширина блока с flip */
  height: 70px;
  /* высота блока с flip */
  position: relative;
  /* позиционируем для дочерних блоков */
  border-radius: 5px;
  cursor: pointer;
  transition: 1s;
  /* скорость поворота */
  transform-style: preserve-3d;
  /* стиль трансформирования 3-д */
  /* для поддержки webkit */
  -webkit-transition: 1s;
  -webkit-transform-style: preserve-3d;
  -webkit-transform: rotateY(0deg);
  /* для поддержки moz */
  -moz-transition: 1s;
  -moz-transform-style: preserve-3d;
  -moz-transform: rotateY(0deg);
}
.flip_effect_horizontal:hover {
  /* общие стили */
  transform: rotateY(180deg);
  /* поворачиваем на 180 градусов по горизонтали */
  /* для поддержки webkit */
  -webkit-transform: rotateY(180deg);
  /* для поддержки moz */
  -moz-transform: rotateY(180deg);
}
.flip_effect_horizontal .front {
  /* общие стили */
  position: absolute;
  /* абсолютное позиционирование */
  width: 100%;
  /* на всю ширину родительского блока */
  height: 100%;
  /* на всю высоту родительского блока */
  background: #FF6600;
  /* цвет фона */
  backface-visibility: hidden;
  /* скрываем контент, который отвернут от пользователя */
  /* для поддержки webkit */
  -webkit-backface-visibility: hidden;
  /* для поддержки moz */
  -moz-backface-visibility: hidden;
}
.flip_effect_horizontal .back {
  /* общие стили */
  position: absolute;
  /* абсолютное позиционирование */
  width: 100%;
  /* на всю ширину родительского блока */
  height: 100%;
  /* на всю высоту родительского блока */
  background: #569ada;
  /* цвет фона */
  backface-visibility: hidden;
  /* скрываем контент, который отвернут от пользователя */
  transform: rotateY(180deg);
  /* отворачиваем задний блок */
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* для поддержки webkit */
  -webkit-backface-visibility: hidden;
  -webkit-transform: rotateY(180deg);
  /* для поддержки moz */
  -moz-backface-visibility: hidden;
  -moz-transform: rotateY(180deg);
}
.front {
  border-radius: 5px;
}
.agents {
  display: none;
  flex-wrap: wrap;
}
@media (min-width: 768px) {
  .agents {
    overflow: hidden;
    height: 70px;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: space-between;
  }
}
@media (min-width: 992px) {
  .agents {
    height: 311px;
  }
}
.agents-ava {
  margin-bottom: 8px;
  margin-right: 8px;
}
@media (min-width: 992px) {
  .agents-ava {
    margin-right: auto;
  }
}
.landing-button--join {
  margin-bottom: 0;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  margin-bottom: 50px;
}
@media (min-width: 992px) {
  .landing-button--join {
    margin-bottom: 0;
  }
}
.agents + .landing-button--join {
  display: none;
}
@media (min-width: 768px) {
  .agents + .landing-button--join {
    margin-right: auto;
    margin-left: 0;
    display: flex;
  }
}
.col-emoji {
  align-self: self-start;
  padding-top: 50px;
  margin-bottom: 20px;
}
@media (min-width: 768px) {
  .col-emoji {
    padding-top: 25px;
    margin-bottom: 0;
  }
}
.mb-35 {
  margin-bottom: 30px;
}
@media (min-width: 992px) {
  .mb-35 {
    margin-bottom: 35px;
  }
}
/*slider*/
.agents-slider-block {
  display: block;
  position: relative;
  height: 120px;
}
@media (min-width: 768px) {
  .agents-slider-block {
    display: none;
  }
}
.agents-slider-block .landing-button--join {
  position: absolute;
  left: 30px;
  bottom: 0;
  margin-bottom: 0;
}
.agents-slider {
  position: absolute;
  left: 30px;
  right: 0;
}
.agents-ava-item {
  margin-right: 10px;
  width: 70px;
}
/* параллакс на кавычках*/
#scene {
  width: 100%;
  height: 800px;
  position: relative;
  /*important!*/
  overflow: hidden;
  /*important!*/
}
@media (min-width: 768px) {
  #scene {
    height: 600px;
  }
}
@media (min-width: 1200px) {
  #scene {
    height: 425px;
  }
}
.layer {
  width: 100%;
  height: 100%;
}
.layer img {
  position: absolute;
  display: none;
}
@media (min-width: 992px) {
  .layer img {
    display: block;
  }
}
.layer__item1 img {
  display: none;
  top: -120px;
  right: -60px;
}
@media (min-width: 992px) {
  .layer__item1 img {
    display: block;
  }
}
.layer__item2 img {
  top: -86px;
  left: -165px;
}
/* //параллакс на кавычках*/
.col-item {
  position: relative;
}
.bgImg {
  position: absolute;
  top: 10px;
  right: -50px;
}
@media (min-width: 768px) {
  .bgImg {
    top: -28px;
    right: -51px;
  }
}
.text-fix-width {
  position: relative;
  padding-top: 80px;
  width: 205px;
}
@media (min-width: 480px) {
  .text-fix-width {
    width: 300px;
  }
}
@media (min-width: 768px) {
  .text-fix-width {
    padding-top: 50px;
    width: 100%;
  }
}
@media (min-width: 992px) {
  .text-fix-width {
    padding-top: 0px;
  }
}
.bonus {
  padding-top: 100px;
  padding-bottom: 100px;
}
.bonus-banner {
  margin-left: -30px;
  margin-right: -30px;
  background-color: #000;
  padding: 40px;
  height: 410px;
}
@media (min-width: 480px) {
  .bonus-banner {
    text-align: center;
  }
}
@media (min-width: 768px) {
  .bonus-banner {
    height: 340px;
    margin-left: 0;
    margin-right: 0;
    border-radius: 10px;
  }
}
.bonus-banner__emoji {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
  height: 115px;
}
.bonus-banner__slogan {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}
.banner-img {
  margin-right: 20px;
}
.banner-img--desk {
  display: none;
}
@media (min-width: 480px) {
  .banner-img--desk {
    display: block;
  }
}
.icon-ban--desk {
  display: none;
}
@media (min-width: 768px) {
  .icon-ban--desk {
    display: block;
  }
}
.icon-ban--mob {
  display: block;
}
@media (min-width: 768px) {
  .icon-ban--mob {
    display: none;
  }
}
.landing-button.button--join-desk {
  max-width: 363px;
  display: none;
}
@media (min-width: 1280px) {
  .landing-button.button--join-desk {
    display: flex;
  }
}
.landing-button.button--join-desk .landing-button--arrow {
  margin-left: auto;
}
.landing-button.button--join-tablet {
  display: none;
}
@media (min-width: 768px) {
  .landing-button.button--join-tablet {
    max-width: 265px;
    display: flex;
  }
}
@media (min-width: 1280px) {
  .landing-button.button--join-tablet {
    display: none;
  }
}
.gk_img {
  border-radius: 50%;
}
.p14 {
  font-size: 14px;
}
.house_supershort {
  min-height: 100px;
}
.codex-text,
.agents-names {
  font-size: 18px;
  color: #334860;
}
.modal-title {
  font-size: 22px;
  font-weight: 700;
  color: #334860;
}
/*Title*/
.head-title {
  max-width: 1000px;
  font-size: 28px;
  font-weight: 700;
}
@media (min-width: 768px) {
  .head-title {
    font-size: 60px;
    line-height: 1.1;
  }
}
.subtitle-block {
  padding-bottom: 25px;
  padding-top: 40px;
}
.subtitle-block .text-box__title-desk {
  display: none;
}
@media (min-width: 768px) {
  .subtitle-block .text-box__title-desk {
    display: block;
  }
}
.text-box__title.text-box__title--small {
  font-size: 16px;
}
@media (min-width: 768px) {
  .text-box__title.text-box__title--small {
    font-size: 20px;
  }
}
.text-box.text-box--mob {
  padding-top: 20px;
  display: block;
  font-size: 14px;
}
@media (min-width: 768px) {
  .text-box.text-box--mob {
    display: none;
  }
}
.codex-link {
  color: #FFF;
  border-bottom: 1px dashed #6ca7df;
  padding-bottom: 1px;
}
.cooperation-right .codex-link {
  border-bottom: 1px dashed #4d4d4d;
}
.codex-link:hover {
  color: #334860;
  border-bottom-color: #334860;
  transition: .5s;
}
/*Кнопки*/
.buttons-reg {
  display: block;
}
@media (min-width: 768px) {
  .buttons-reg {
    display: flex;
  }
}
.buttons-reg--btn {
  width: 100%;
  max-width: 480px;
  height: 60px;
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
  border: 0px;
  font-size: 20px;
  font-weight: 700;
  padding-top: 20px;
  background-color: #3d5a96;
  box-shadow: 0 10px 20px rgba(61, 90, 150, 0.35);
  transition: .5s;
}
@media (min-width: 768px) {
  .buttons-reg--btn {
    font-size: 16px;
  }
}
@media (min-width: 992px) {
  .buttons-reg--btn {
    font-size: 20px;
  }
}
.buttons-reg--btn:hover {
  background-color: #334860;
}
.btn-fb {
  margin-bottom: 20px;
}
@media (min-width: 768px) {
  .btn-fb {
    margin-right: 20px;
    margin-bottom: 0;
  }
}
.btn-desk {
  display: none;
}
@media (min-width: 768px) {
  .btn-desk {
    display: block;
  }
}
.btn-mob {
  display: block;
}
@media (min-width: 768px) {
  .btn-mob {
    display: none;
  }
}
.about {
  padding-top: 80px;
  overflow: hidden;
}
.about-box {
  max-width: 350px;
}
@media (min-width: 992px) {
  .about-box {
    margin-left: auto;
    margin-right: auto;
  }
}
.about-box .landing-button {
  margin-bottom: 60px;
}
@media (min-width: 1200px) {
  .about-box .landing-button {
    margin-bottom: 40px;
  }
}
.about-emoji {
  height: 100px;
  margin-bottom: 16px;
}
.guarantees {
  background-color: #41ae1b;
  border-radius: 5px;
  padding-left: 25px;
  padding-top: 8px;
  margin-left: -30px;
  margin-right: -30px;
}
@media (min-width: 768px) {
  .guarantees {
    margin-right: 0;
    margin-left: 0;
  }
}
.guarantees .percent {
  font-size: 50px;
  font-weight: 700;
}
@media (min-width: 992px) {
  .guarantees .percent {
    font-size: 60px;
  }
}
@media (min-width: 1200px) {
  .guarantees .percent {
    font-size: 80px;
  }
}
.guarant-desk {
  display: none;
}
@media (min-width: 768px) {
  .guarant-desk {
    padding-left: 5px;
    display: inline-block;
  }
}
.landing-button.landing-button--guarantees {
  margin-top: 10px;
  margin-bottom: 40px;
}
@media (min-width: 1200px) {
  .landing-button.landing-button--guarantees {
    margin-bottom: 28px;
    margin-top: 27px;
  }
}
.text-box--fix {
  max-width: 250px;
}
@media (min-width: 992px) {
  .text-box--fix {
    max-width: 312px;
  }
}
#contact-form {
  width: 100%;
}
.form-item {
  position: relative;
}
.input-placeholder {
  position: absolute;
  top: 10px;
  padding: 2px 4px;
  background-color: #FFF;
  left: 16px;
  font-size: 14px;
  color: #4d4d4d;
  transition: .3s;
}
.input-placeholder.active {
  top: -12px;
  color: #3d5a96;
  font-weight: 700;
}
.form-field {
  width: 100%;
  height: 45px;
  padding-left: 16px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  border: 1px solid #4d4d4d;
  margin-bottom: 20px;
}
.form-field.active {
  border: 1px solid #3d5a96;
  -webkit-box-shadow: 0 0 8px rgba(61, 90, 150, 0.35);
  box-shadow: 0 0 8px rgba(61, 90, 150, 0.35);
}
.form-footer {
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.form-footer .buttons-reg--btn {
  height: 40px;
  margin-left: auto;
  color: #FFF;
  font-size: 16px;
  font-weight: 700;
  padding-top: 5px;
  padding-left: 16px;
  padding-right: 16px;
}
.contact-form__success {
  background-color: #41ae1b;
  position: absolute;
  top: 0%;
  left: 0%;
  right: 0;
  display: block;
  color: white;
  padding: 30px 20px;
  font-weight: 700;
  border-radius: 8px;
  padding: 40px;
  padding-bottom: 60px;
  font-size: 20px;
  bottom: 0;
  text-align: center;
  min-width: 300px;
}
#regButton {
  width: 220px;
}
#rezetButton {
  width: 130px;
  margin-left: 0;
}
/* base color */
@media (min-width: 1350px) {
  .container {
    max-width: 1320px;
  }
}
@media (min-width: 1350px) {
  .col-hl {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-hl-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-hl-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-hl-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-hl-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-hl-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-hl-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-hl-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-hl-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-hl-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-hl-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-hl-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-hl-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-hl-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-hl-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }
  .order-hl-last {
    -webkit-box-ordinal-group: 14;
    -ms-flex-order: 13;
    order: 13;
  }
  .order-hl-0 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }
  .order-hl-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
  .order-hl-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }
  .order-hl-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
  }
  .order-hl-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
  }
  .order-hl-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5;
  }
  .order-hl-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6;
  }
  .order-hl-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7;
  }
  .order-hl-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8;
  }
  .order-hl-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9;
  }
  .order-hl-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10;
  }
  .order-hl-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11;
  }
  .order-hl-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-hl-0 {
    margin-left: 0;
  }
  .offset-hl-1 {
    margin-left: 8.333333%;
  }
  .offset-hl-2 {
    margin-left: 16.666667%;
  }
  .offset-hl-3 {
    margin-left: 25%;
  }
  .offset-hl-4 {
    margin-left: 33.333333%;
  }
  .offset-hl-5 {
    margin-left: 41.666667%;
  }
  .offset-hl-6 {
    margin-left: 50%;
  }
  .offset-hl-7 {
    margin-left: 58.333333%;
  }
  .offset-hl-8 {
    margin-left: 66.666667%;
  }
  .offset-hl-9 {
    margin-left: 75%;
  }
  .offset-hl-10 {
    margin-left: 83.333333%;
  }
  .offset-hl-11 {
    margin-left: 91.666667%;
  }
}
.col-hl-1,
.col-hl-2,
.col-hl-3,
.col-hl-4,
.col-hl-5,
.col-hl-6,
.col-hl-7,
.col-hl-8,
.col-hl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl,
.col-hl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 12px;
  padding-left: 12px;
}
.mt-0 {
  margin-top: 0 !important;
}
.mt-5 {
  margin-top: 5px !important;
}
.mt-10 {
  margin-top: 10px !important;
}
.mt-15 {
  margin-top: 15px !important;
}
.mt-20 {
  margin-top: 20px !important;
}
.mt-25 {
  margin-top: 25px !important;
}
.mt-30 {
  margin-top: 30px !important;
}
.mt-35 {
  margin-top: 35px !important;
}
.mt-40 {
  margin-top: 40px !important;
}
.mt-45 {
  margin-top: 45px !important;
}
.mt-50 {
  margin-top: 50px !important;
}
.mt-55 {
  margin-top: 55px !important;
}
.mt-60 {
  margin-top: 60px !important;
}
.mt-65 {
  margin-top: 65px !important;
}
.mt-70 {
  margin-top: 70px !important;
}
.mt-75 {
  margin-top: 75px !important;
}
.mt-80 {
  margin-top: 80px !important;
}
.mt-85 {
  margin-top: 85px !important;
}
.mt-90 {
  margin-top: 90px !important;
}
.mt-95 {
  margin-top: 95px !important;
}
.mt-100 {
  margin-top: 100px !important;
}
.mb-0 {
  margin-bottom: 0 !important;
}
.mb-5 {
  margin-bottom: 5px !important;
}
.mb-10 {
  margin-bottom: 10px !important;
}
.mb-15 {
  margin-bottom: 15px !important;
}
.mb-20 {
  margin-bottom: 20px !important;
}
.mb-25 {
  margin-bottom: 25px !important;
}
.mb-30 {
  margin-bottom: 20px !important;
}
.mb-35 {
  margin-bottom: 35px !important;
}
.mb-40 {
  margin-bottom: 40px !important;
}
.mb-45 {
  margin-bottom: 45px !important;
}
.mb-50 {
  margin-bottom: 50px !important;
}
.mb-55 {
  margin-bottom: 55px !important;
}
.mb-60 {
  margin-bottom: 60px !important;
}
.mb-65 {
  margin-bottom: 65px !important;
}
.mb-70 {
  margin-bottom: 70px !important;
}
.mb-75 {
  margin-bottom: 75px !important;
}
.mb-80 {
  margin-bottom: 80px !important;
}
.mb-85 {
  margin-bottom: 85px !important;
}
.mb-90 {
  margin-bottom: 90px !important;
}
.mb-95 {
  margin-bottom: 95px !important;
}
.mb-100 {
  margin-bottom: 100px !important;
}
.ml-0 {
  margin-left: 0 !important;
}
.ml-5 {
  margin-left: 5px !important;
}
.ml-10 {
  margin-left: 10px !important;
}
.ml-15 {
  margin-left: 15px !important;
}
.ml-20 {
  margin-left: 20px !important;
}
.ml-25 {
  margin-left: 25px !important;
}
.ml-30 {
  margin-left: 20px !important;
}
.ml-35 {
  margin-left: 35px !important;
}
.ml-40 {
  margin-left: 40px !important;
}
.ml-45 {
  margin-left: 45px !important;
}
.ml-50 {
  margin-left: 50px !important;
}
.ml-55 {
  margin-left: 55px !important;
}
.ml-60 {
  margin-left: 60px !important;
}
.ml-65 {
  margin-left: 65px !important;
}
.ml-70 {
  margin-left: 70px !important;
}
.ml-75 {
  margin-left: 75px !important;
}
.ml-80 {
  margin-left: 80px !important;
}
.ml-85 {
  margin-left: 85px !important;
}
.ml-90 {
  margin-left: 90px !important;
}
.ml-95 {
  margin-left: 95px !important;
}
.ml-100 {
  margin-left: 100px !important;
}
.mr-0 {
  margin-right: 0 !important;
}
.mr-5 {
  margin-right: 5px !important;
}
.mr-10 {
  margin-right: 10px !important;
}
.mr-15 {
  margin-right: 15px !important;
}
.mr-20 {
  margin-right: 20px !important;
}
.mr-25 {
  margin-right: 25px !important;
}
.mr-30 {
  margin-right: 20px !important;
}
.mr-35 {
  margin-right: 35px !important;
}
.mr-40 {
  margin-right: 40px !important;
}
.mr-45 {
  margin-right: 45px !important;
}
.mr-50 {
  margin-right: 50px !important;
}
.mr-55 {
  margin-right: 55px !important;
}
.mr-60 {
  margin-right: 60px !important;
}
.mr-65 {
  margin-right: 65px !important;
}
.mr-70 {
  margin-right: 70px !important;
}
.mr-75 {
  margin-right: 75px !important;
}
.mr-80 {
  margin-right: 80px !important;
}
.mr-85 {
  margin-right: 85px !important;
}
.mr-90 {
  margin-right: 90px !important;
}
.mr-95 {
  margin-right: 95px !important;
}
.mr-100 {
  margin-right: 100px !important;
}
@media (max-width: 768px) {
  .sm-mt-0 {
    margin-top: 0 !important;
  }
  .sm-mt-5 {
    margin-top: 5px !important;
  }
  .sm-mt-10 {
    margin-top: 10px !important;
  }
  .sm-mt-15 {
    margin-top: 15px !important;
  }
  .sm-mt-20 {
    margin-top: 20px !important;
  }
  .sm-mt-25 {
    margin-top: 25px !important;
  }
  .sm-mt-30 {
    margin-top: 30px !important;
  }
  .sm-mt-35 {
    margin-top: 35px !important;
  }
  .sm-mt-40 {
    margin-top: 40px !important;
  }
  .sm-mt-45 {
    margin-top: 45px !important;
  }
  .sm-mt-50 {
    margin-top: 50px !important;
  }
  .sm-mt-55 {
    margin-top: 55px !important;
  }
  .sm-mt-60 {
    margin-top: 60px !important;
  }
  .sm-mt-65 {
    margin-top: 65px !important;
  }
  .sm-mt-70 {
    margin-top: 70px !important;
  }
  .sm-mt-75 {
    margin-top: 75px !important;
  }
  .sm-mt-80 {
    margin-top: 80px !important;
  }
  .sm-mt-85 {
    margin-top: 85px !important;
  }
  .sm-mt-90 {
    margin-top: 90px !important;
  }
  .sm-mt-95 {
    margin-top: 95px !important;
  }
  .sm-mt-100 {
    margin-top: 100px !important;
  }
  .sm-mb-0 {
    margin-bottom: 0 !important;
  }
  .sm-mb-5 {
    margin-bottom: 5px !important;
  }
  .sm-mb-10 {
    margin-bottom: 10px !important;
  }
  .sm-mb-15 {
    margin-bottom: 15px !important;
  }
  .sm-mb-20 {
    margin-bottom: 20px !important;
  }
  .sm-mb-25 {
    margin-bottom: 25px !important;
  }
  .sm-mb-30 {
    margin-bottom: 20px !important;
  }
  .sm-mb-35 {
    margin-bottom: 35px !important;
  }
  .sm-mb-40 {
    margin-bottom: 40px !important;
  }
  .sm-mb-45 {
    margin-bottom: 45px !important;
  }
  .sm-mb-50 {
    margin-bottom: 50px !important;
  }
  .sm-mb-55 {
    margin-bottom: 55px !important;
  }
  .sm-mb-60 {
    margin-bottom: 60px !important;
  }
  .sm-mb-65 {
    margin-bottom: 65px !important;
  }
  .sm-mb-70 {
    margin-bottom: 70px !important;
  }
  .sm-mb-75 {
    margin-bottom: 75px !important;
  }
  .sm-mb-80 {
    margin-bottom: 80px !important;
  }
  .sm-mb-85 {
    margin-bottom: 85px !important;
  }
  .sm-mb-90 {
    margin-bottom: 90px !important;
  }
  .sm-mb-95 {
    margin-bottom: 95px !important;
  }
  .sm-mb-100 {
    margin-bottom: 100px !important;
  }
  .sm-ml-0 {
    margin-left: 0 !important;
  }
  .sm-ml-5 {
    margin-left: 5px !important;
  }
  .sm-ml-10 {
    margin-left: 10px !important;
  }
  .sm-ml-15 {
    margin-left: 15px !important;
  }
  .sm-ml-20 {
    margin-left: 20px !important;
  }
  .sm-ml-25 {
    margin-left: 25px !important;
  }
  .sm-ml-30 {
    margin-left: 20px !important;
  }
  .sm-ml-35 {
    margin-left: 35px !important;
  }
  .sm-ml-40 {
    margin-left: 40px !important;
  }
  .sm-ml-45 {
    margin-left: 45px !important;
  }
  .sm-ml-50 {
    margin-left: 50px !important;
  }
  .sm-ml-55 {
    margin-left: 55px !important;
  }
  .sm-ml-60 {
    margin-left: 60px !important;
  }
  .sm-ml-65 {
    margin-left: 65px !important;
  }
  .sm-ml-70 {
    margin-left: 70px !important;
  }
  .sm-ml-75 {
    margin-left: 75px !important;
  }
  .sm-ml-80 {
    margin-left: 80px !important;
  }
  .sm-ml-85 {
    margin-left: 85px !important;
  }
  .sm-ml-90 {
    margin-left: 90px !important;
  }
  .sm-ml-95 {
    margin-left: 95px !important;
  }
  .sm-ml-100 {
    margin-left: 100px !important;
  }
  .sm-mr-0 {
    margin-right: 0 !important;
  }
  .sm-mr-5 {
    margin-right: 5px !important;
  }
  .sm-mr-10 {
    margin-right: 10px !important;
  }
  .sm-mr-15 {
    margin-right: 15px !important;
  }
  .sm-mr-20 {
    margin-right: 20px !important;
  }
  .sm-mr-25 {
    margin-right: 25px !important;
  }
  .sm-mr-30 {
    margin-right: 20px !important;
  }
  .sm-mr-35 {
    margin-right: 35px !important;
  }
  .sm-mr-40 {
    margin-right: 40px !important;
  }
  .sm-mr-45 {
    margin-right: 45px !important;
  }
  .sm-mr-50 {
    margin-right: 50px !important;
  }
  .sm-mr-55 {
    margin-right: 55px !important;
  }
  .sm-mr-60 {
    margin-right: 60px !important;
  }
  .sm-mr-65 {
    margin-right: 65px !important;
  }
  .sm-mr-70 {
    margin-right: 70px !important;
  }
  .sm-mr-75 {
    margin-right: 75px !important;
  }
  .sm-mr-80 {
    margin-right: 80px !important;
  }
  .sm-mr-85 {
    margin-right: 85px !important;
  }
  .sm-mr-90 {
    margin-right: 90px !important;
  }
  .sm-mr-95 {
    margin-right: 95px !important;
  }
  .sm-mr-100 {
    margin-right: 100px !important;
  }
}
.pt-0 {
  padding-top: 0;
}
.pt-5 {
  padding-top: 5px;
}
.pt-10 {
  padding-top: 10px;
}
.pt-15 {
  padding-top: 15px;
}
.pt-20 {
  padding-top: 20px;
}
.pt-25 {
  padding-top: 25px;
}
.pt-30 {
  padding-top: 30px;
}
.pt-35 {
  padding-top: 35px;
}
.pt-40 {
  padding-top: 40px;
}
.pt-45 {
  padding-top: 45px;
}
.pt-50 {
  padding-top: 50px;
}
.pt-55 {
  padding-top: 55px;
}
.pt-60 {
  padding-top: 60px;
}
.pt-65 {
  padding-top: 65px;
}
.pt-70 {
  padding-top: 70px;
}
.pt-75 {
  padding-top: 75px;
}
.pt-80 {
  padding-top: 80px;
}
.pt-85 {
  padding-top: 85px;
}
.pt-90 {
  padding-top: 90px;
}
.pt-95 {
  padding-top: 95px;
}
.pt-100 {
  padding-top: 100px;
}
.pb-0 {
  padding-bottom: 0;
}
.pb-5 {
  padding-bottom: 5px;
}
.pb-10 {
  padding-bottom: 10px;
}
.pb-15 {
  padding-bottom: 15px;
}
.pb-20 {
  padding-bottom: 20px;
}
.pb-25 {
  padding-bottom: 25px;
}
.pb-30 {
  padding-bottom: 30px;
}
.pb-35 {
  padding-bottom: 35px;
}
.pb-40 {
  padding-bottom: 40px;
}
.pb-45 {
  padding-bottom: 45px;
}
.pb-50 {
  padding-bottom: 50px;
}
.pb-55 {
  padding-bottom: 55px;
}
.pb-60 {
  padding-bottom: 60px;
}
.pb-65 {
  padding-bottom: 65px;
}
.pb-70 {
  padding-bottom: 70px;
}
.pb-75 {
  padding-bottom: 75px;
}
.pb-80 {
  padding-bottom: 80px;
}
.pb-85 {
  padding-bottom: 85px;
}
.pb-90 {
  padding-bottom: 90px;
}
.pb-95 {
  padding-bottom: 95px;
}
.pb-100 {
  padding-bottom: 100px;
}
.pl-0 {
  padding-left: 0;
}
.pl-5 {
  padding-left: 5px;
}
.pl-10 {
  padding-left: 10px;
}
.pl-15 {
  padding-left: 15px;
}
.pl-20 {
  padding-left: 20px;
}
.pl-25 {
  padding-left: 25px;
}
.pl-30 {
  padding-left: 20px;
}
.pl-35 {
  padding-left: 35px;
}
.pl-40 {
  padding-left: 40px;
}
.pl-45 {
  padding-left: 45px;
}
.pl-50 {
  padding-left: 50px;
}
.pl-55 {
  padding-left: 55px;
}
.pl-60 {
  padding-left: 60px;
}
.pl-65 {
  padding-left: 65px;
}
.pl-70 {
  padding-left: 70px;
}
.pl-75 {
  padding-left: 75px;
}
.pl-80 {
  padding-left: 80px;
}
.pl-85 {
  padding-left: 85px;
}
.pl-90 {
  padding-left: 90px;
}
.pl-95 {
  padding-left: 95px;
}
.pl-100 {
  padding-left: 100px;
}
.pr-0 {
  padding-right: 0;
}
.pr-5 {
  padding-right: 5px;
}
.pr-10 {
  padding-right: 10px;
}
.pr-15 {
  padding-right: 15px;
}
.pr-20 {
  padding-right: 20px;
}
.pr-25 {
  padding-right: 25px;
}
.pr-30 {
  padding-right: 20px;
}
.pr-35 {
  padding-right: 35px;
}
.pr-40 {
  padding-right: 40px;
}
.pr-45 {
  padding-right: 45px;
}
.pr-50 {
  padding-right: 50px;
}
.pr-55 {
  padding-right: 55px;
}
.pr-60 {
  padding-right: 60px;
}
.pr-65 {
  padding-right: 65px;
}
.pr-70 {
  padding-right: 70px;
}
.pr-75 {
  padding-right: 75px;
}
.pr-80 {
  padding-right: 80px;
}
.pr-85 {
  padding-right: 85px;
}
.pr-90 {
  padding-right: 90px;
}
.pr-95 {
  padding-right: 95px;
}
.pr-100 {
  padding-right: 100px;
}
.no-paddings {
  padding: 0;
}
/* Floats */
.float-right {
  float: right;
}
.float-left {
  float: left;
}
/* Text align */
.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-justify {
  text-align: justify;
}
/* Font weight */
.light {
  font-weight: 300;
}
.normal {
  font-weight: 400;
}
.strong {
  font-weight: 700;
}
/* Font properties */
.uppercase {
  text-transform: uppercase;
}
/* Flex */
.flex-grow {
  -webkit-flex-grow: 1;
  flex-grow: 1;
}
