.agent-info{
	padding-top: 20px;
	padding-bottom: 25px;
	padding-left: 15px;
	padding-right: 15px;
	background-color: @lightBorder;
	height: 100%;
	@media(min-width: 992px){border-radius: 0 10px 10px 0;}
	@media(min-width: 1350px){max-width: 270px;}

}

.agent-card{
	width: 100%;
	@media(min-width: 768px){border-radius: 0 0 10px 10px;}
	@media(min-width: 992px){width: 20%;}

	
	
}
.agent-info-content{
	display: flex;
	justify-content: space-between;
	@media(min-width: 768px){
		flex-direction: column;
		justify-content: flex-start;
	}
}
.posted-time{
	margin-bottom: 14px;
	@media(min-width: 768px){margin-bottom: 19px;}
}
.agent-info-text{
	max-width: 57%;
	@media(min-width: 768px){order:2;}
}
.agent-info-img{
	width: 78px;
	height: 78px;
	border-radius: 5px;
	overflow: hidden;

	img{
		border-radius: 5px;
	}

	@media(min-width: 768px){
		margin-bottom: 16px;
		order:1
	};
}
.agent-info-download{
	display: none;
	padding-top: 100px;
	@media(min-width: 992px){display: block;}
}
.download-num-item{
	position: relative;
	width: 30px;
	height: 30px;
	margin-right: 5px;
	border-radius: 5px;
}
.agent-info-download-num{
	display: none;
	position: relative;
	padding-bottom: 12px;
	@media(min-width: 1200px){
		display: flex;
	}
}
.download-num-item:last-child{padding-right: 0;}
.download-num-item-last{
	content:'';
	position: absolute;
	top: 0;
	bottom: 0;
	width: 30px;
	right: 0;
	background-color: rgba(0,0,0,.4 );
	color: @white;
	display: flex;
	justify-content: center;
	align-items: center;
	font-weight: 700;
}

.agent-card-desk{
	display: none;
	@media(min-width: 992px){
		display: block;
	}
}

.agent-card-mob{
	display: block;
	@media(min-width: 992px){
		display: none;
	}
}