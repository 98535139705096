/*Title*/
.landing-title{
	// background-color: #2c81d1;
}

.head-title{
	max-width: 1000px;
	font-size: 28px;
	font-weight: 700;
	// padding-top: 40px;

	@media(min-width: 768px){
		font-size: 60px;
		line-height: 1.1;
		// padding-top: 50px;
	}
}

.subtitle-block{
	padding-bottom: 25px;
	padding-top: 40px;

	.text-box__title-desk{
		display: none;
		@media(min-width: 768px){
			display: block;
		}
	}
}
.text-box__title.text-box__title--small{
	font-size: 16px;
	@media(min-width: 768px){font-size: 20px;}
}
.text-box.text-box--mob{
	padding-top: 20px;
	display: block;
	font-size: 14px;
	@media(min-width: 768px){
			display: none;
		}
}

.codex-link{
	color: #FFF;
	border-bottom: 1px dashed #6ca7df;
	padding-bottom: 1px;
}
.cooperation-right .codex-link{
	border-bottom: 1px dashed #4d4d4d;

}
.cooperation-right .codex-link{

}
.codex-link:hover{
	color: #334860;
	border-bottom-color: #334860;
	transition: .5s;
}

/*Кнопки*/
.buttons-reg{
	// padding-bottom: 85px;
	display: block;
	@media(min-width: 768px){display: flex;}
}


.buttons-reg--btn{
	width: 100%;
	max-width: 480px;
	height: 60px;
	border-radius: 4px;
	text-align: center;
	cursor: pointer;
	border: 0px;
	font-size: 20px;
	font-weight: 700;
	padding-top: 20px;
		// padding-right: 20px;
	background-color: #3d5a96;
	box-shadow: 0 10px 20px rgba(61, 90, 150, 0.35);
	transition: .5s;
	@media(min-width: 768px){font-size: 16px;}
	@media(min-width: 992px){font-size: 20px;}
}

.buttons-reg--btn:hover{background-color: #334860;}

.btn-fb{
	margin-bottom: 20px;
	@media(min-width: 768px){
		margin-right: 20px;
		margin-bottom: 0;}
}

.btn-desk{
	display: none;
	@media(min-width: 768px){display: block;}
}

.btn-mob{
	display: block;
	@media(min-width: 768px){display: none;}
}

.about{
	padding-top: 80px;
	overflow: hidden;
}

.about-box{
	max-width: 350px;

	@media(min-width: 992px){
		margin-left: auto;
		margin-right: auto;
	}
}
.about-box .landing-button{
	margin-bottom: 60px;
	@media(min-width: 1200px){margin-bottom: 40px;}
}
.about-emoji{
	height: 100px;
	margin-bottom: 16px;
}


.guarantees{
	background-color: #41ae1b;
	border-radius: 5px;
	padding-left: 25px;
	padding-top: 8px;
	margin-left: -30px;
	margin-right: -30px;

	// padding-right: 75px;
	@media(min-width: 768px){
		margin-right: 0;
		margin-left: 0;
		// min-width: 410px;
		// min-height: 254px;
	}

	.percent{
		font-size: 50px;
		font-weight: 700;
		@media(min-width: 992px){font-size: 60px;}
		@media(min-width: 1200px){font-size: 80px;}
	}
}
.guarant-desk{
	display: none;
	@media(min-width: 768px){
		padding-left: 5px;
		display: inline-block;	}
}
.landing-button.landing-button--guarantees{
	margin-top: 10px;
	margin-bottom: 40px;
	@media(min-width: 1200px){
		margin-bottom: 28px;
		margin-top: 27px;}
}

.text-box--fix{
	max-width: 250px;
	@media(min-width: 992px){max-width: 312px;}
}

//-form
#contact-form{width: 100%;}
.form-item{
	position: relative;

}
.input-placeholder{
	// display: none;
	position: absolute;
	top: 10px;
	padding: 2px 4px;
	background-color: #FFF;
	left: 16px;
	font-size: 14px;
	color: #4d4d4d;
	transition:.3s;
}
.input-placeholder.active{
	top: -12px;
	color: #3d5a96;
	font-weight: 700;
}
.form-field{
	width: 100%;
	height: 45px;
	padding-left: 16px;
	-webkit-border-radius: 5px;
	        border-radius: 5px;
	border:1px solid #4d4d4d;
	margin-bottom: 20px;
}
.form-field.active{
	border:1px solid #3d5a96;
	-webkit-box-shadow: 0 0 8px rgba(61, 90, 150, 0.35);;
	        box-shadow: 0 0 8px rgba(61, 90, 150, 0.35);;
}
.form-footer{
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-justify-content: center;
	        justify-content: center;
}
.form-footer .buttons-reg--btn{
	height: 40px;
	// min-width: 200px;
	margin-left: auto;
	color: #FFF;
	font-size: 16px;
	font-weight: 700;
	padding-top: 5px;
	padding-left: 16px;
	padding-right: 16px;
}
.contact-form__success {

  background-color: #41ae1b;
  position: absolute;
  top: 0%;
  left: 0%;
  right: 0;
  display: block;

  color: white;
  padding: 30px 20px;
  font-weight: 700;
  border-radius: 8px;
  padding: 40px;
  padding-bottom: 60px;
  font-size: 20px;
  bottom: 0;
  text-align: center;
  min-width: 300px;
}
#regButton{width: 220px;

}
#rezetButton{
	width: 130px;
	margin-left: 0;
}