.footer{
	// background-color:#2c81d1;
	padding-top: 50px;
	// height: 207px;

	position: relative;
	// padding-left: 17px;
	@media(min-width: 768px){
	padding-left: 0px;
	}
	@media(min-width: 1200px){
		padding-top: 60px;
		 overflow:hidden;
	}
	@media(min-width: 1350px){
		flex-direction: row;
		align-items: center;
		// height: 220px;
	}


	.footer-top{
		background: transparent;
		position: relative;
		z-index: 2;
		padding-bottom: 2.2rem;
		flex-direction: column;
		align-items: flex-start;
		justify-content: space-between;
		@media(min-width: 768px){
			padding-bottom: 1rem;
			flex-wrap: wrap;
			flex-direction: row;
			align-items: center;}
		@media(min-width: 1350px){
			padding-bottom: 30px;
			justify-content: flex-start;}
	}
	.simple-link{
		width: fit-content;
		padding-top: 5px;
		@media(min-width: 768px){padding-top: 16px;}
		@media(min-width: 992px){padding-top: 0px;}



	}
	.links-block{
		width: 300px;
		flex-wrap: wrap;
		margin-bottom: 30px;
		@media(min-width: 480px){width:  auto;}
		@media(min-width: 768px){
			margin-bottom: 0;
			display: flex;
			margin-right: auto;
			justify-content: space-between;
		//	order:3;
		}
		@media(min-width: 800px){width: 550px;}
		@media(min-width: 992px){
			align-items: center;
			width: auto;
	//		order:2;
			margin-right: 0;
			margin-bottom: 0;}
		@media(min-width: 1200px){
			flex-wrap: nowrap;
		}
	}
	.button-block{
		position: relative;
	//	@media(min-width: 768px){order:2;}
	//	@media(min-width: 992px){order:2;}
		// @media(min-width: 1350px){margin-left: 60px;}
	}
	.logoblock{
		align-items: flex-start;
		flex-direction: column;
		padding-bottom: 20px;
		@media(min-width: 768px){padding-bottom: 0px;}
		@media(min-width: 992px){
			margin-right: 0;
			padding-bottom: 20px;}
		@media(min-width: 1350px){

			align-items: center;
			flex-direction: row;
			margin-right: auto;
			padding-bottom: 0;
		}
		.logo-text{
			width: 270px;
			font-size: 14px;
			display: block;
			color: #fff;

			@media(min-width: 1350px){width: 210px;}
		}
		.logo-icon{
			margin-bottom: 6px;
			@media(min-width: 1350px){
			margin-bottom: 0;
			}
		}
	}

}
.footer-bottom{
	overflow: hidden;
	flex-direction: column;
	background: transparent;
	position: relative;
	z-index: 2;
	// border-top: 2px solid #ced9f2;
	padding-top: 0.5rem;
	padding-bottom: 1.0rem;
		@media(min-width: 992px){
			padding-top: 1rem;
			padding-bottom: 2.2rem;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
		}
		@media(min-width: 1440px){
			padding-bottom: 40px;
			padding-top: 15px;}

	.simple-link{
		display: inline-block;
		color: #fff;
		order:1;
		margin-right: 0;
		margin-bottom: 22px;
		@media(min-width: 768px){margin-bottom: 10px;}
		@media(min-width: 992px){
			margin-bottom: 22px;
			order:2;
			margin-bottom: 0;
		}
	}

}
.footer-bottom__copyright{
	color: #fff;
	order:2;
	font-size: 14px;
	@media(min-width: 992px){
		order:1;
	}

}
.footer-bottom__politic{
	order:1;
	@media(min-width: 992px){
		order:2;
	}
}
.realty-cat-img{
	display: none;
	position: absolute;
	bottom: -73px;
	left: 50%;
	transform: translateX(-50%) translateX(30px);
	svg{
		width: 230px;
	}
	@media(min-width: 768px){
		bottom: -81px;
		display: block;
		transform: translate(-32%,-10%);
	}
	@media(min-width: 992px){bottom: -91px;}
	@media(min-width: 1350px){
		bottom: -76px;
		transform: translateX(-50%) translateX(27px) translateY(-5px);
		}

}
.simple-link{
	border-bottom: 1px solid #569ada;
	font-size: 14px;
	color: #fff;
	transition: .5s;
	&:hover{color: #569ada;}
}
.btn-add{
	min-width:270px;

	width: 100%;
	max-width: 300px;
	padding-top: 18px;
	padding-bottom: 19px;
	border-radius: 5px;
	border:2px solid #fff;
	display: block;
	text-align: center;
	color: #fff;
	font-size: 16px;
	font-weight: 700;
	@media(min-width: 768px){
	border-width:1px;
	font-size: 14px;
	font-weight: 400;
	min-width: 200px;
	padding-top: 5px;
	padding-bottom: 5px;

	}
}
.item-plus{
	font-size: 38px;
	position: relative;
	top: 8px;
	// font-weight: 700;
	line-height: 0;
	@media(min-width: 768px){
		top: 2px;
		font-size: 20px;}


}
.simple-link.simple-link--block{
	display: block;
	margin-bottom: 6px;
	@media(min-width: 768px){
		margin-bottom: 0px;
		display: inline-block;	}
}
.simple-link.simple-link--ib{
	padding-top: 6px;
	display: inline-block;
	// @media(min-width: 768px){padding-top: 16px;}
}
.simple-link--ib:nth-child(2){margin-right: 20px;}
.footer-tooltip{
	display: none;
	padding: 10px;
	border-radius: 5px;
	background-color: #000;
	position: absolute;
	top: -20px;
	left: 50%;
	font-size: 14px;
	text-align: center;
	max-width: 250px;
	min-width: 250px;

	transform: translate(-50%, -100%);
}
.footer-tooltip:after{
	content: "";
	width: 0px;
	height: 0px;
	position: absolute;
	border:10px solid #000;
	border-bottom: 0px;
	border-left-color: transparent;
	border-right-color: transparent;
	bottom:-8px;
	left: 50%;

}
.button-block:hover .footer-tooltip{display: block;}
