#header{
	position: sticky;
	top: 0;
	z-index: 100;
	background-color: white;
	border-bottom: 1px solid rgba(44, 129, 209, .35);
	color: #334860;
	min-height: 135px;
	padding-top: 25px;
	overflow: hidden;
	@media(min-width: 768px){
		padding-top: 22px;
		min-height: 80px;}

}

.logo-icon {
	margin-right: 20px;
	svg{
		height: 40px;
	width: 200px;
	}

}

.logoblock{
	display: block;
	align-items: center;
	margin-bottom: 1.8rem;
	margin-right: 2.5rem;
	.logo-icon{
	margin-right: 1rem;
	@media(min-width: 1350px){
		margin-right: 1.1rem;
		}
	}

	.logo-text{
		// display: none;
		color:black;
		width: 228px;
		line-height: 1.2;
		font-size: 14px;
		@media(min-width: 480px){width: 250px;}
	}

	@media(min-width: 768px){
		display: flex;
		margin-bottom: 0px;
		justify-content: flex-start;}
	@media(min-width: 992px){width: auto;}
}


.header-content{
	display: flex;
	align-items: center;
	.logoblock{margin-right: auto;}

}
.header-info-block{
	display: none;
	margin-right: 45px;
	@media(min-width: 1200px){display: flex;}
}
.header-info{
	display: flex;
	align-items: center;
}
.header-info__number{
	font-size: 40px;
}
.header-info__text{
	font-size: 14px;
	padding-left: 12px;
	max-width: 170px;
}

.header-btn{
	border:0px;
	border-radius: 5px;
	background-color: #2c81d1;
	color: #fff;
	font-weight: 700;
	border-radius: 5px;
	min-width: 90px;
	height: 30px;
	text-align: center;
	padding-top: 2px;
	transition: .5s;
	cursor: pointer;
}
.header-btn:hover{
	box-shadow: 0 0 12px 4px rgba(44, 129, 209, .45);
}