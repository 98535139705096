// Контейнер для блока!
.title-block-wrapper{
	justify-content: space-between;
	align-items: center;
	padding-top: 32px;
	padding-bottom: 24px;

	.text-info{
		flex-direction: column;
		@media(min-width: 768px){
			padding-top: 14px;
		}
		@media(min-width: 1200px){padding-top: 0px;}
	}
	.metro-station:last-child{
		.last-text--comma{display: none;
		}
	}
}
.title-block--left{
	width: 100%;
	@media(min-width: 768px){

		width:  75%;}
	@media(min-width: 1320px){width:  70%;}

}
.main-title-block{
	position: relative;
	flex-direction: column;
	padding-bottom: 1.2rem;
	@media(min-width: 1200px){
		flex-direction: row;
		align-items: baseline;
	}
	.span-title{
		padding-right: 16px;
		color: @title-1;
		@media(min-width: 1200px){	padding-left: 20px;}

	}
	.span-details{
		color: #999;
	}
	.title-1{
		padding-bottom:  12px;

		@media(min-width: 1200px){
			padding-bottom: 0px;
		}
	}
	.subtitle{
		font-size: 1rem;
	}
	.price-badge{
		display: block;
		border-radius: 0 5px 5px 0;
		background-color: @white;
		padding-left: 24px;
		padding-top: 11px;
		padding-bottom: 12px;
		width: 295px;
		position: relative;
		left: -22px;
		margin-top: 18px;

		@media(min-width: 480px){left:auto;}
		@media(min-width: 768px){

			display: none;
		}
	}

		@media(min-width: 1350px){

		}
	}

	.main-badge-block{

		flex-direction: row;
		align-items: center;
		flex-wrap: wrap;
		.link-block{
			padding-right: 0;

		}
		.badge-group{
			display: none;
			align-items: center;
			@media(min-width: 768px){
				display: flex;
			}
			.badge{margin-right: 12px;}
		}

		.link-group{

			font-size: 16px;
			line-height: 1.7;
			@media(min-width: 1200px){
				font-size: 14px;
				padding-right: 24px;
				line-height: 1.7;
			}
				@media(min-width: 1350px){
				font-size: 14px;
				padding-right: 32px;
				line-height: 1.7;
			}
			.link-hidden{
				display: inline-block;
				padding-right: 7px;
				@media(min-width: 768px){
				display: none;
			}
			}

		}
		.metro-station{
				margin-top: 12px;
				@media(min-width: 768px){margin-top: 0;}
			}
	}



	.title-block--right{
		width: 25%;
		text-align: right;
		@media(min-width: 768px){}
		@media(min-width: 992px){width: 30%;}
		@media(min-width: 1350px){

			width: 25%;
		}
		.update-box{
			display: none;
			padding-bottom: 16px;
			color:#999;
			@media(min-width: 768px){
				display: block;
			}
		}
	}

	.price-box{
		display: none;
		@media(min-width: 768px){
			font-size: 18px;
			display: block;
		}
		@media(min-width: 992px){
			font-size: 20px;
		}
	}