.broker_cabinet {
  background: @yellowBg;
  border: 1px solid @yellowBRD;
  border-radius: 10px;
  padding: 20px 5px;
  margin-top: 50px;

  .filter-toggle {
    font-weight: bold;

    .filter-toggle-item {
      font-size: 16px;
      height: 26px;
      margin-right: 26px
    }
  }

  .card-description-chat {
    background: transparent;

    .message-block {
      background-color: #fff;
    }

    .comment {
      background-color: #fff;
    }

    .message-block:before {
      border: 8px solid #fff;
      border-bottom: 0;
      border-right-color: transparent;
      border-left-color: transparent;
    }
  }

}
.fadecab{height: 3em;
  position: relative;
  top: -3em;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0, #fff8eb 100%);}
.commission {
  color: @greenBg;
  font-size: 30px;
  font-weight: bold;
  margin-top: 45px;
  line-height: 1;
}

.externallink {
  position: relative;
  top: -3px;
  left: 3px;
}

.refreshed {
  color: @yellowMuted;
  //position: absolute;
  //bottom: 10px;
}
.text-yellow-muted{color: @yellowMuted;}
.yellowhr {
  border-top: 1px solid @yellowBRD;
}
.list-links{line-height: 250%;}
.infoblock{min-height: 350px;}
.transparent-panel{border: 1px solid @lightBorder;    border-radius: 5px;  padding: 14px;}
.maillink{font-size: 12px;}
.white-section {
  background: @white;
  //border: 1px solid @grey;
  border-radius: 10px;
  padding: 20px 5px;
  margin-top: 50px;
}
.cabvisible{
 // max-height: 180px;
  overflow: hidden;
}
.inlineblock{display: inline;}