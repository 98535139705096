.hc-photo-wrapper {
  display: none;
  margin-bottom: 40px;
  @media (min-width: 768px) {
    display: block;
  }
}

.hc-photo-mobile {
  display: block;
  @media (min-width: 768px) {
    display: none ;
  }

  .carousel-item img {
    border-radius: 5px;
  }
}

.switch-block__main-photo {
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  position: relative;
  margin-bottom: 8px;

  img {
    //margin: auto;
    max-height: 100%;
    max-width: 100%;
    width: 100%;
    display: block;
    //position:absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  @media (min-width: 768px) {
    //width: 850px;
    height: 100%;
    max-height: 570px;
    margin-bottom: 10px;
  }
  @media (min-width: 1200px) {
    //width: 560px;

  }
  @media (min-width: 1350px) {
    //width: 630px;
  }
}


.switch-block__thumbnails {
  display: none;
  //justify-content: space-between;
  cursor: pointer;
  @media (min-width: 768px) {
    display: flex;
    //width: 630px;
    max-width: 850px;
    margin-left: auto;
    margin-right: auto;
  }
  @media (min-width: 1200px) {
    //width: 560px;

  }
  @media (min-width: 1350px) {
    //width: 630px;
  }
}


.thumbnails__image {

  width: 80px;
  height: 70px;
  margin-right: 10px;
  overflow: hidden;
  position: relative;
  border-radius: 8px;

  &:last-child {
    margin-right: 0;

    &:after {
      display: flex;
      position: absolute;
      align-items: center;
      padding: 25px;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 2;
      // background-color: rgba(0, 0, 0, .5);
      // content: '+9';
      font-size: 20px;
      line-height: 1.25;
      font-weight: 700;
      color: @white;
    }

    @media (min-width: 768px) {
      margin-right: 0;
    }

  }

  @media (min-width: 1200px) {
    width: 76px;
    height: 66px;
  }
  @media (min-width: 1350px) {
    width: 80px;
    height: 70px;
  }

  img {
    max-width: 100%;
    display: block;
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
  }
}

.wimg {
  display: inline-block;
  //background: #fff;
  padding: 10px;
  opacity: 0.5;
  max-width: 350px;
  max-height: 250px;
}

.watermark {
  position: absolute;
  z-index: 5;
  width: 100%;
  top: 20px;
  opacity: 0.7;
}