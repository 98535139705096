.color-block{
	display: flex;
	margin-bottom: 40px;
	align-items: 	center;

}
.color{
	width: 30px;
	height: 30px;
	margin-right: 40px;
	border: 2px solid #f3f3f3;
}
.color-name{
	font-size: 18px;
}
.color-num{
	margin-left: 50px;
	font-size: 18px;
}