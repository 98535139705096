.transition {
	-webkit-transition: all 0.2s ease-in;
	   -moz-transition: all 0.2s ease-in;
	    -ms-transition: all 0.2s ease-in;
	     -o-transition: all 0.2s ease-in;
	        transition: all 0.2s ease-in;
}

.transition-shadow {
	-webkit-transition: box-shadow 0.2s ease-in;
	   -moz-transition: box-shadow 0.2s ease-in;
	    -ms-transition: box-shadow 0.2s ease-in;
	     -o-transition: box-shadow 0.2s ease-in;
	        transition: box-shadow 0.2s ease-in;
}


// Иконка треугольник
.icon-mixin(@color, @right, @top) {
	position:relative;

	&::after {
		content:'';
		border: 5px solid transparent;
		border-top:7px solid @color;
		position:absolute;
		right:@right;
		top:@top;
	}
}