@radius: 6px;
@shadow: 0px 10px 40px 0px rgba(99, 117, 138, 0.3);
@background: #fff;

.card {
	border: none;
	border-radius: @radius;
	background-color: @background;
	box-shadow: @shadow;
	padding: 20px;
	display: flex;
	flex-direction: column;
}

.card > .title-1, .card > .title-2, .card > .title-3, .card > .title-4, .card > .title-5, .card > .title-6 {
	margin-top: 0;
}
.card__img {
	width: 100%;
	margin-bottom: 15px;
	overflow:hidden;
}

.card__title {
	font-size: 22px;
	line-height: 28px;
	font-weight: 600;
	// overflow:hidden;
	// text-overflow:ellipsis;
}
.card--post {
	width: 100%;
	height: 308px;
}
.card--portfolio {
	padding: 0;
	padding-bottom: 20px;
	width: 100%;
	height: 315px;
}

.card__desc {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-start;
	flex:1;
}

.card-wrapper-fixed-size {
	width: 360px;
}
.card{
	padding-top: 60px;
	.title-2{
		margin-bottom: 20px;
	}
	a.button{font-size: 16px;}
}
.title-1.mb-55{
		margin-top: 55px;
	}
