.offer-page-wrapper{
	.logoblock {

		@media(min-width: 1200px){margin-right: 1.5em;}
		@media(min-width: 1350px){margin-right: 2.5em;}

		.logo-text{
			@media(min-width: 992px){display: none;	}
			@media(min-width: 1350px){display: block;	}
		}
	}
	.header__icon-group__item{
		margin-right: 20px;
		@media(min-width: 992px){margin-right: 32px;}

	}
}

.section-top--new-offer{
	display: block;
	margin-top: 60px;
	margin-bottom: 20px;

	@media(min-width: 768px){
		display: flex;
		justify-content: space-between;
		margin-top: 0;
		margin-bottom: 0;
	}

	.badge--green{
		margin-bottom: 20px;
		@media(min-width: 768px){
			
			margin-bottom: 0;
		}
	}
}

.section-cards{
	margin-bottom: 30px;
	@media(min-width: 768px){
		margin-bottom: 50px;
	}
}

.new-offer-footer{
	display: flex;
    justify-content: space-between;
    margin-bottom: 80px;

    .btn-text{
    	display: none;
    	@media(min-width: 768px){
    		display: block;
    	}
    }

    .btn-text--mobile{
    	display: block;
    	@media(min-width: 768px){
    		display: none;
    	}
    }
}

.section-top--hot-offer{
	display: block;
	padding-top: 0;
	padding-bottom: 0;
	

	.title-1--desk{
		display: none;
		@media(min-width: 992px){
			display: block;
			padding-right: 28px;
		}
	}

	.title-1--mob{
		display: block;
		padding-bottom: 14px;
		@media(min-width: 768px){
			padding-bottom: 0;
			padding-right: 28px;
		}
		@media(min-width: 992px){
			display: none;
		}
	}

	.badge{
		margin-bottom: 20px;
		@media(min-width: 768px){
			margin-bottom: 0;
			margin-right: 28px;

		}
	}

	@media(min-width: 768px){display: flex;}
}

.hot-offer-footer{
	.button--basic{@media(min-width: 768px){width: 100%;}}
	.btn-text{
    	display: none;
    	@media(min-width: 768px){
    		display: block;
    	}
    }

    .btn-text--mobile{
    	display: block;
    	@media(min-width: 768px){
    		display: none;
    	}
    }
}

.button-download{
	height: 30px;
	text-align: center;
	background-color: @btnBg;
	color: @white;
	padding-left: 10px;
	padding-right: 10px;
	padding-top: 5px;
	border-radius: 3px;
	font-weight: 700;
	&:hover, &:active, &:focus {
		color: @white;
		text-decoration: none;
		opacity: .85;
	}

	.button-download--desk{
		display: none;
		@media(min-width:768px){
			display: block;
		}
	}
	.button-download--mob{
		display: block;
		@media(min-width:768px){
			display: none;
		}
	}
}



.offer-row--tools{
	.icon-heart--tablet{
		.simple-link{@media(min-width:1200px){
			display: block;
			padding-left: 8px;
			}
		}
	}
}

.offer-row--info{
	position: relative;

	.appartment-card--mobile-repaire-icon{
		top: 114px;

		@media(min-width:768px){display: none;}
	}

	
}