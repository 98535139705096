* {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

html,
body {
	width: 100%;
	height: 100%;
	font-family: 'Montserrat', sans-serif;
	font-size: 14px;
	padding: 0;
	margin: 0;
	min-height: 100%;
	position: relative;
	min-width: 360px;
	background-color:@bodyBg;
}
.wrapper-page{
	display: table;
	height: 100%;
	width: 100%;
}
.main-content{
	display: table-row;
	height: 100%;
}

// .offer-row--swipe li{
// 	display: inline-block;
// }
/* fix for top margin collapsing and vertical scroll prevent for body tag, when body height = 100% */

body::before {
	content: "";
	display: block;
	width: 1px;
	height: 0px;
	background-color: transparent;
}

input,
textarea {
	font-family: 'Montserrat', sans-serif;
	font-size: 16px;
	line-height: 1.5;
	resize: vertical;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0;
	font-weight: 400;
	line-height: 1.3;
}

img {
	display: block;
}

a {

	color: rgb(44,129,209);
	&:hover{
		text-decoration: none;
		color:rgb(209,86,86);

	}
}

code,
pre {
	background-color: #f5f5f5;
	border-radius: 6px;
	-moz-osx-font-smoothing: auto;
	-webkit-font-smoothing: auto;
	font-family: monospace;
	font-family: Consolas, Monaco, Lucida Console, monospace;
	font-size: 1rem;
}

pre {
	color: #666;
	line-height: 1.5;
	margin: 15px 0;
	overflow: auto;
	padding: 10px;
}

code {
	color: #666;
	font-weight: 400;
	padding: .3em .8em;
}

pre code {
	padding: 0;
}

blockquote {
	background: #2d82d1;
	border-radius: 5px;
	padding: 30px 80px;
	position: relative;
	width: 100%;
	margin: 2em auto;
	overflow: hidden;
	font-size: 18px;
	line-height: 1.5;
}

blockquote:before {
	content: '“';
	font-weight: 900;
	color: #c4c4c4;
	opacity: 0.2;
	font-size: 4em;
	line-height: 1em;
	position: absolute;
	top: 30px;
	left: 30px;
	z-index: 0;
}

blockquote p {
	margin-bottom: 0;
	font-size: 18px;
	line-height: 1.5;
	color: #2a2a2a;
	font-weight: 700;
	position: relative;
	z-index: 1;
}
label{
	margin: 0;
	padding: 0;
}

.pt-16{padding-top: 16px;}
.clear {
	clear: both;
	display: table;
}

.body--dashboard {
	padding-left: 330px;
	padding-right: 15px;

	&.ui-nav--hidden {
		padding-left: 0;
		padding-right: 0;
	}
}

.body-bg {
	background-color: #f3f3f3;
}

.container--left {
	margin-left: 0 !important;
	margin-right: 0 !important;
}

.section-ui {
	padding: 15px 0px;
}

.ui-title {
	font-size: 18px;
	font-weight: 600;
	margin-bottom: 10px;

}

.section-ui pre {
	margin-top: 0;
}


.hr-line {
	// border-bottom: 1px solid #e9eff3;
	border-top: 1px solid #C4C4C4;

}


.col--no-padding {
	padding-left: 0 !important;
	padding-right: 0 !important;
}
.photo-slider{
	display: flex;
	height: 250px;
	overflow-y: hidden;
	white-space: nowrap;
	&::-webkit-scrollbar {
	display: none;
	}
	.photo-slider-item{
		border-radius: 8px;
		width: 300px;
		margin-right: 20px;
		@media(min-width: 480px){
			width: 360px;
		}
		img{
			border-radius: 10px;
			height: 100%;
		}
	}
}
.card-slider{
	.slick-slide{
		margin-right: 10px;
		width: 248px;
		// @media(min-width: 768px){width: 230px;}
	}
}

.appartment-card--mobile{
	.photo-slider{
		height: auto;
	// 	@media(min-width: 480px){height: 190px;}
	}
}
.card--photo-slider{
	.photo-slider-item{
		min-height: 150px;
		width: 250px;
		@media(min-width: 480px){
			width: 300px;
		}
	}
}
.fadeOut:after{
	display: none;
	content:"";
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	margin: 0;
	height: 3.0em;
	background-color: rgba(255,255,255,0);
	background-image: linear-gradient(to bottom, rgba(255,255,255,0) 0, #f5f6fa 100%);
	@media(min-width: 768px){display: block;}
}

.box-visible-desk{
	display: none !important;
	@media(min-width: 768px){
		display: inline-flex !important;
	}
}



.box-visible-mob{
	display: inline-flex !important;
	@media(min-width: 768px){
		display: none !important;
	}
}

// .section-wrapper{
// 	width: 100%;
// 	padding-left: 30px;
// 	padding-right: 30px;

// 	@media(min-width: 992px){
// 		padding-left: 60px;
// 		padding-right: 60px;
// 	}
// 	@media(min-width: 1200px){
// 		// padding-left: 0;
// 		// padding-right: 0;
// 		// max-width: 1140px;
// 		// margin-left: auto;
// 		// margin-right: auto;
// 		padding-left: 80px;
// 		padding-right: 80px;

// 	}
// 	@media(min-width: 1350px){
// 		max-width: 1290px;
// 		min-width: 1290px;
// 		padding-left: 0;
// 		padding-right: 0;
// 		margin-left: auto;
// 		margin-right: auto;

// 	}
// }
